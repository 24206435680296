import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';

const AppelAPIMoto = ({voyage,typeVehicule, typeCarburant, valeurInput, frequence, consommation,unit,typeKilo,setLancer, Lancer, setApiResponse}) => {
  const { token, fetchToken } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    //console.log(token)
    if (token && Lancer) { 
       fetchData();
       setLancer(false);
     }


  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

   const fetchData = async () => {
     setLoading(true);
    const apiUrlConnu = 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Pmoto_Global';
    const apiUrlInconnu= 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Pmoto_Detail';
    
    let apiUrl = ''; 

    // Déterminer l'URL de l'API en fonction de la valeur de `kmConnu`
    if (typeKilo === 'oui') {
        apiUrl = apiUrlConnu;
      } else {
        apiUrl = apiUrlInconnu;
      }

    const data = (typeKilo==='oui')
      ? {
      IdBC: "b2c25702-f76c-ee11-8178-000d3a951a7d",
      IdPage: "Pmoto",
      TypeVehicule: typeVehicule,
      Typecarburant: typeCarburant,
      Consommation: consommation,
      ConsoConnue: consommation !== '',
      UnitConsommation: unit,
      KilometrageConnu: "True",
      Kilometrage: valeurInput,
      FrequenceKilometrage: frequence
    }:
    {
        IdBC: "b2c25702-f76c-ee11-8178-000d3a951a7d",
        IdPage: "Pmoto",
        typeVoyage: voyage.typeVoyage,
        depart: voyage.depart.name,
        latitudeDepart: voyage.depart.latitude,
        longitudeDepart: voyage.depart.longitude,
        arrivee: voyage.arrive.name,
        latitudeArrivee: voyage.arrive.latitude,
        longitudeArrivee: voyage.arrive.longitude,
        frequence: voyage.ajout.frequence,
        unit: voyage.ajout.unit,
        nbPersonnes: voyage.ajoutPerso
      };

      console.log(data);
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    //Faire le if sur la condition km connu =true
    try {
      const result = await axios.post(apiUrl, data, options);
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(result.data);
       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default AppelAPIMoto;
