import React from 'react';
import './Graphique.css';
import CardPetiteDroite from '../CardPetiteDroite';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Ligne2 = ({text, lien, image}) => {
  // Données pour les éléments du graphique
  
  return (
      <div className='ligne2 row'>
          <div className='col-5'><CardPetiteDroite color={{ backgroundColor: "#FBAE50"}} backgroundImage={image}/></div>
          <div className='col-1 logo'><div className='traiLigne'></div><AddCircleOutlineIcon/></div>
          <div className='col-6 text'>
            <div className='titre'>{text}</div>
            <div className='savoir titre' src={lien} >En savoir plus</div>
          </div>
          
      </div>
  );
};

export default Ligne2;
