import React from 'react';
import './Band.css';
import Card from '../Card';
import ContenueCardBilan from './ContenuCardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import SousTitre from '../../components/SousTitre';
import InputField from '../Form/Input';
import Bouton from '../Form/Bouton';
import InputFieldEmail from '../Form/Input/indexMail';
import  {useState } from 'react';


const Band2 = ({}) => {
  // Données pour les éléments du graphique

  const [email, setEmail] = useState('');

  // Fonction pour gérer le changement de l'adresse e-mail
  const handleEmailChange = (newValue) => {
    setEmail(newValue);
  };

  
  return (
    <div className='row col-12 Band2 align-items-center justify-item-center' style={{padding : "0px"}}>
      <div className='bandA col-6'>
        <p className='p2'>Je veux recevoir des conseils personnalisés sur mes éco-gestes et recevoir des informations de Good Planet</p>
      </div>    
      <div className='bandD col-6 justify-item-center'>
        <div className='row'>
        <div className='col-8'><InputFieldEmail titre="Mon email" text="Entrez votre adresse mail" value={email} onChange={handleEmailChange}/></div>
        <div className='col-4'><Bouton titre="Recevoir" /></div>
        </div>
      </div>
    </div>
  );
  
};

export default Band2;
