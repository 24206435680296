import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import InputFieldNb from '../InputNombre';
import InputFieldNbUnique from '../InputNombre/indexUnit';
import Compteur from '../../Compteur/Compteur';
import CheckboxChoixUnique from '../checkBox/checkbox';


function FormDeplacementParticipant({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false); // nouvel état pour suivre la soumission du formulaire
  const [DepIntraRegio, setDepIntraRegio] = useState(); // nouvel état pour suivre la soumission du formulaire
  const [DepInterRegio, setDepInterRegio] = useState(); // nouvel état pour suivre la soumission du formulaire
  const [Europe, setEurope] = useState(); // nouvel état pour suivre la soumission du formulaire
  const [Afrique, setAfrique] = useState(); // nouvel état pour suivre la soumission du formulaire
  const [Amerique, setAmerique] = useState(); // nouvel état pour suivre la soumission du formulaire
  const [Asie, setAsie] = useState(); // nouvel état pour suivre la soumission du formulaire
  const [DistanceMoyenne, setDistanceMoyenne] = useState();
  const [DistanceConnue, setDistanceConnue] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true); // indique que le formulaire a été soumis
    console.log(formSubmitted);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate("/Voiture-11");
  };

  const [domicile, setDomicile] = useState({
    routier: {
      voiture: '',
      "2 roues": '',
      bus: ''
    },
    aerien: {
      sup: '',
      entre: '',
      inf: ''
    },
    ferroviaire: {
      metro: '',
      trainFrance: '',
      trainHorsFrance: ''
    }
  });

  const [hebergement, setHebergement] = useState({
    routier: {
      voiture: '',
      "2 roues": '',
      bus: ''
    },
    aerien: {
      sup: '',
      entre: '',
      inf: ''
    },
    ferroviaire: {
      metro: '',
      trainFrance: '',
      trainHorsFrance: ''
    }
  });

  const handleChange = (category, type, value, isHebergement = false) => {
    if (isHebergement) {
      setHebergement((prevHebergement) => ({
        ...prevHebergement,
        [category]: {
          ...prevHebergement[category],
          [type]: value
        }
      }));
    } else {
      setDomicile((prevDomicile) => ({
        ...prevDomicile,
        [category]: {
          ...prevDomicile[category],
          [type]: value
        }
      }));
    }
  };


  return (
    <form onSubmit={handleSubmit}>


      <div className="row">
          <div className="col-12">
          <CheckboxChoixUnique
              onChange={(value) => setDistanceConnue(value)}
              titre="Connaissez-vous la distance parcourue par tout les participants ?"
              rep1="Oui"
              rep2="Non"
          />
          </div>
      </div>

      {DistanceConnue ? (
        <div style={{marginBottom : "5vh"}}>
          <div className='h61 vert'>Trajet Domicile - Evénement</div>
          <div className='row col-12'>
            <div className='h61 vert'>Déplacements routiers</div>
          </div>

          <div className='row col-12'>
            <div className='row col-12'>
              <Compteur
                titre="Déplacements intrarégionaux"
                unit='personnes'
                onValeurChange={(value) => setDepIntraRegio(value)}
                /> 
            </div>
            <div className='row col-12'>
              <Compteur
                titre="Déplacements inter-régionaux"
                unit='personnes'
                onValeurChange={(value) => setDepInterRegio(value)}
              />
            </div>
          </div>

          <div className='row col-12'>
            <div className='h61 vert'>Hors France</div>
          </div>

          <div className='row col-12'>
            <div className='row col-12'>
              <Compteur
                titre="Europe"
                unit='personnes'
                onValeurChange={(value) => setEurope(value)}
              />
            </div>
            <div className='row col-12'>
              <Compteur
                titre="Afrique (Nord/Centre/Ouest) / Asie (Moyen-Orient /Centre/Nord)"
                unit='personnes'
                onValeurChange={(value) => setAfrique(value)}
              />
            </div>
            <div className='row col-12'>
              <Compteur
                titre="Amérique/ Afrique (Est/Australe) / Asie (Est/Sud)"
                unit='personnes'
                onValeurChange={(value) => setAmerique(value)}
              />
            </div>            
            <div className='row col-12'>
              <Compteur
                titre="Asie (Sud-Est) / Océanie"
                unit='personnes'
                onValeurChange={(value) => setAsie(value)}
              />
            </div>
          </div>

        </div>
      ) : (

      <div>
      <div className='row col-12'>
        <div className='h61 vert'>Déplacements routiers</div>
      </div>

      <div className='row col-12'>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Voiture"
            unit="pers.km"
            value={domicile.routier.voiture}
            onChange={(value) => handleChange('routier', 'voiture', value)}
          />
        </div>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="2 roues"
            unit="pers.km"
            value={domicile.routier["2 roues"]}
            onChange={(value) => handleChange('routier', '2 roues', value)}
          />
        </div>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Bus"
            unit="pers.km"
            value={domicile.routier.bus}
            onChange={(value) => handleChange('routier', 'bus', value)}
          />
        </div>
      </div>

      <div className='row col-12'>
        <div className='h61 vert'>Déplacements aériens</div>
      </div>

      <div className='row col-12'>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Distance supérieure à 4000km"
            unit="pers.km"
            value={domicile.aerien.sup}
            onChange={(value) => handleChange('aerien', 'sup', value)}
          />
        </div>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Entre 3000 et 4000km"
            unit="pers.km"
            value={domicile.aerien.entre}
            onChange={(value) => handleChange('aerien', 'entre', value)}
          />
        </div>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Distance inférieure à 3000km"
            unit="pers.km"
            value={domicile.aerien.inf}
            onChange={(value) => handleChange('aerien', 'inf', value)}
          />
        </div>
      </div>

      <div className='row col-12'>
        <div className="SousTitre">
          <div className='h61 vert'>Déplacements ferroviaires</div>
        </div>
      </div>

      <div className='row col-12'>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Métro/RER/Tramway"
            unit="pers.km"
            value={domicile.ferroviaire.metro}
            onChange={(value) => handleChange('ferroviaire', 'metro', value)}
          />
        </div>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Train en France"
            unit="pers.km"
            value={domicile.ferroviaire.trainFrance}
            onChange={(value) => handleChange('ferroviaire', 'trainFrance', value)}
          />
        </div>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Train hors France"
            unit="pers.km"
            value={domicile.ferroviaire.trainHorsFrance}
            onChange={(value) => handleChange('ferroviaire', 'trainHorsFrance', value)}
          />
        </div>
      </div>

      </div>
      )}

        <div className="SousTitre">
          <div className='row col-12 h61 vert'>Trajet Hébergement sur place - Evénement</div>
          <p className='row col-12'>Indiquer le pourcentage de chaque mode de déplacement pour avoir un total de 100%</p>
        </div>

      <div className='row col-12'>
      <div className="SousTitre">
          <div className='h61 vert'>Déplacements routiers</div>
        </div>
      </div>


      <div className='row col-12'>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Voiture"
            unit="%"
            value={hebergement.routier.voiture}
            onChange={(value) => handleChange('routier', 'voiture', value, true)}
          />
        </div>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="2 roues"
            unit="%"
            value={hebergement.routier["2 roues"]}
            onChange={(value) => handleChange('routier', '2 roues', value, true)}
          />
        </div>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Bus"
            unit="%"
            value={hebergement.routier.bus}
            onChange={(value) => handleChange('routier', 'bus', value, true)}
          />
        </div>
      </div>

      <div className='row col-12'>
      <div className="SousTitre">
          <div className='h61 vert'>Déplacements ferroviaire</div>
        </div>
      </div>

      <div className='row col-12'>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Métro/RER/Tramway"
            unit="%"
            value={hebergement.aerien.sup}
            onChange={(value) => handleChange('aerien', 'sup', value, true)}
          />
        </div>
        <div className='col-4'>
        </div>
        <div className='col-4'>
        </div>
      </div>

      <div className='row col-12'>
      <div className="SousTitre">
          <div className='h61 vert'>Autres moyens de transport </div>
        </div>
      </div>

      <div className='row col-12'>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Transport doux : marche/vélo"
            unit="%"
            value={hebergement.ferroviaire.metro}
            onChange={(value) => handleChange('ferroviaire', 'metro', value, true)}
          />
        </div>
        <div className='col-4'>
          <InputFieldNbUnique
            titre="Mode inconnu"
            unit="% (calcul automatique)"
            value={hebergement.ferroviaire.trainFrance}
            onChange={(value) => handleChange('ferroviaire', 'trainFrance', value, true)}
          />
        </div>
        <div className='col-4'>
        </div>
      </div>

      <Compteur
          titre="Distance moyenne entre les sites d’hébergement et le lieu de l’événement"
          value={DistanceMoyenne}
          onValeurChange={(value) => setDistanceMoyenne(value)}
          />
      
  
      <div className="row">
        <div className="col-12">
          <Bouton titre="CALCULER" />
        </div>
      </div>
  
      {formSubmitted && ( 
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInter numeroEtape={2} voyage="non" />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick}/>
            </div>
          </div>
        </div>
      )}

    </form>
  );
}  

export default FormDeplacementParticipant;
