import React from 'react';
import './Band.css';
import Card from '../Card';
import ContenueCardBilan from './ContenuCardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import SousTitre from '../../components/SousTitre';

const Band1 = ({}) => {
  // Données pour les éléments du graphique
  
  return (
    <div className='row col-12 Band1 justify-item-center'>
    <div className='bandG col-5 row justify-item-center'>
      <div className='col-3'><InstagramIcon sx={{ fontSize: 40, color: 'black' }}/></div>
      <div className='col-3'><FacebookIcon sx={{ fontSize: 40, color: 'black' }}/></div>
      <div className='col-3'><WhatsAppIcon sx={{ fontSize: 40, color: 'black' }}/></div>
      <div className='col-3'><MapsUgcIcon sx={{ fontSize: 40, color: 'black' }}/></div>
    </div>
    <div className='col-7'>
      <SousTitre SousTitre={"Partager votre bilan et vos objectifs"}/>
      <p className='p1'>Invitez vos proches à faire le leur</p>
    </div>
</div>
  );
};

export default Band1;
