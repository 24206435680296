import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';

const AppelAPIAlimentaire = ({compteurAlim,setLancer, Lancer, setApiResponse}) => {
  const { token, fetchToken } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    console.log(token)
    if (token && Lancer) { 
       fetchData();
     }


  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

   const fetchData = async () => {
     setLoading(true);
    const apiUrl = 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Palimentaire_Global';
    
    const dataAlim = {
      IdBC: "b2c25702-f76c-ee11-8178-000d3a951a7d",
      IdPage: "PAlimentaire",
      cerealier: compteurAlim.cerealier,
      continental: compteurAlim.continental,
      sale: compteurAlim.sale,
      vegetarien: compteurAlim.vegetarien,
      vegetalien: compteurAlim.vegetalien,
      produitsMarins: compteurAlim.produitsMarins,
      viandeBovine: compteurAlim.viandeBovine,
      viandeVolaille: compteurAlim.viandeVolaille,
      viandeRougePlus: compteurAlim.viandeRougePlus,
      viandeRougeEntre: compteurAlim.viandeRougeEntre,
      viandeRougeMoins: compteurAlim.viandeRougeMoins,
      viandeBlanchePlus: compteurAlim.viandeBlanchePlus,
      viandeBlancheEntre: compteurAlim.viandeBlancheEntre,
      viandeBlancheMoins:compteurAlim.viandeBlancheMoins,
      cafe:compteurAlim.cafe,
      the:compteurAlim.the,
      jusFruits:compteurAlim.jusFruits,
      soda:compteurAlim.soda,
      alcool:compteurAlim.alcool
    };

    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const resultAlim = await axios.post(apiUrl, dataAlim, options);
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(resultAlim.data);
       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default AppelAPIAlimentaire;
