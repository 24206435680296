import React from 'react';
import "./Bouton.css"

function Bouton(props) {
  const { titre } = props;
  const { onClick } = props;


  return (
    <button type="submit" className="btn-black btn" onClick={onClick}>
      {titre}
    </button>
  );
}

export default Bouton;
