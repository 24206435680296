import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';

const AppelAPIDechet = ({compteurDechet, setLancer, Lancer, setApiResponse}) => {
  const { token, fetchToken } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    console.log(token)
    if (token && Lancer) { 
       fetchData();
     }


  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

   const fetchData = async () => {
     setLoading(true);
     //console.log(token);
    const apiUrl = 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Pdechet_Global';
    
    const dataDechet = {
      IdBC: "b2c25702-f76c-ee11-8178-000d3a951a7d", 
      IdPage: "Pdechet",
      Mobilier: compteurDechet.Mobilier,
      MobilierScM: compteurDechet.MobilierScM,
      Electronique: compteurDechet.Electronique,
      ElectroniqueScM: compteurDechet.ElectroniqueScM,
      Vetement: compteurDechet.Vetement,
      VetementScM: compteurDechet.VetementScM,
      TriPlastique: compteurDechet.TriPlastique,
      TriPapier: compteurDechet.TriPapier,
      TriDechet: compteurDechet.TriDechet,
      TriCarton: compteurDechet.TriCarton,
      TriVerre: compteurDechet.TriVerre,
      Ampoule: compteurDechet.Ampoule,
      Medicament: compteurDechet.Medicament,
      Elect:compteurDechet.Elect,
      Bricolage:compteurDechet.Bricolage,
      Pile:compteurDechet.Pile
    };
    console.log(compteurDechet);
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      console.log('dans le try');
      const resultDechet = await axios.post(apiUrl, dataDechet, options);
      console.log(resultDechet);
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(resultDechet.data);
       setLancer(false);
      //setResponse(result.data);
      console.log('fin du try');
    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };
  
  
  return (null);
};

export default AppelAPIDechet;
