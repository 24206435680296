import React, { useRef, useEffect, useState } from 'react';
import "./Input.css";

function InputFieldEmail(props) {
  const { titre, text, erreur } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true); // State pour suivre la validité de l'e-mail

  const handleInputBlur = () => {
    setIsFocused(false);
    validateEmail(currentValue);
  };

  const handleInputChange = (e) => {
    setCurrentValue(e.target.value);
    setIsValidEmail(true); // Réinitialiser l'état de validation à true à chaque changement
  };

  const validateEmail = (email) => {
    // Expression régulière pour vérifier l'adresse e-mail
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(regex.test(email)); // Met à jour l'état de validation en fonction du résultat de la regex
  };

  return (
    <div className="row">
      <div className={`col-25 ${isFocused || currentValue ? 'label-up' : ''} ${erreur ? 'label-error' : ''}`}>
        <label className='h61'>{titre}</label>
      </div>
      <div className="col-75">
        <div className={`normal ${erreur || !isValidEmail ? 'erreur' : ''}`}>
          <input
            type="email"
            className={`form-control ${erreur ? 'input-error' : ''}`}
            placeholder={text}
            value={currentValue}
            onChange={handleInputChange}
            onFocus={() => setIsFocused(true)}
            onBlur={handleInputBlur}
          />
          {!isValidEmail && <div className="error-message">Veuillez entrer une adresse e-mail valide.</div>}
        </div>
      </div>
    </div>
  );
}

export default InputFieldEmail;