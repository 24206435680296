import React from 'react';
import './Graphique.css';
import Bouton from '../Form/Bouton';
import CarreBilan from './CarreBilan';

const ContenueCardBilan = ({logo, titre, titregras}) => {
  // Données pour les éléments du graphique
  
  return (
      <div className='ContenueCardBilan row'>
          <div className='col-8'>
              <p className='p1'  style={{marginBottom : "3vh", marginTop : "3vh"}}>
              La contribution à la neutralité carbone est un mécanisme qui permet d’avoir un impact positif sur l’environnement, les communautés les plus vulnérables et la neutralité carbone, en participant au développement de projets favorisant la réduction ou la séquestration des émissions de gaz à effet de serre.
              <br/>
              <br/>
              Les fonds de la compensation carbone permettent le financement des projets de valorisation d’ordures ménagères au Cameroun et à Madagascar.
              </p>
              <button type="button" className="custom-button">
                  EN savoir plus sur les projets et la contribution
              </button>
          </div>
          <div className='col-4'>
              <CarreBilan/>
              <Bouton titre = "JE CONTRIBUE pour 48€"/>
          </div>
          
      </div>
  );
};

export default ContenueCardBilan;
