import React from 'react';
import './StepperLine-test.css';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TrainIcon from '@mui/icons-material/Train';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HouseIcon from '@mui/icons-material/House';
import RecyclingIcon from '@mui/icons-material/Recycling';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import { useNavigate } from 'react-router-dom'; // import the useNavigate hook
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BusinessIcon from '@mui/icons-material/Business';
import ComputerIcon from '@mui/icons-material/Computer';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


const StepperLineEntreprise = () => {
  const navigate = useNavigate(); // instantiate the navigate function

  return (
    <div className="containerT">

      <div className="circle" onClick={() => navigate('/entreprise')}>
        <ElectricBoltIcon sx={{ color: 'green' }}/>
        {/* <div className="triangle"></div> */}
        {/* <div className="line-down"></div> */}
        <div className="textT H69" style={{color: "green"}}>Energie</div>
      </div>

      <div className="lineT line-between"></div>
      <div className="circle" onClick={() => navigate('/Entreprise/Deplacement')}>
        <DirectionsCarIcon sx={{ color: '#68C4A5' }}/>
        {/* <div className="triangleT"></div>
        <div className="line-down"></div> */}
        <div className="textT h21">Déplacement terrestre</div>
      </div>

      <div className="lineT line-between"></div>
      <div className="circle" onClick={() => navigate('/Entreprise/Voyage')}>
        <FlightIcon sx={{ color: '#FBAB3B' }} />
        {/* <div className="triangle"></div> */}
        <div className="line-down"></div>
        <div className="textT" style={{color: "#FBAB3B"}}>Déplacement aérien</div>
      </div>

      <div className="lineT line-between"></div>
      <div className="circle" onClick={() => navigate('/Entreprise/Fret')}>
        <LocalShippingIcon/>
        {/* <div className="triangle"></div> */}
        <div className="line-down"></div>
        <div className="textT">Fret</div>
      </div>

      <div className="lineT line-between"></div>
      <div className="circle" onClick={() => navigate('/Entreprise/Immobilisation')}>
        <BusinessIcon/>
        {/* <div className="triangle"></div> */}
        <div className="line-down"></div>
        <div className="textT">Immobilisations & consommables</div>
      </div>

      <div className="lineT line-between"></div>
      <div className="circle" onClick={() => navigate('/Entreprise/Bien')}>
        <LocalPrintshopIcon/>
        {/* <div className="triangle"></div> */}
        <div className="line-down"></div>
        <div className="textT">Biens et services</div>
      </div>

      <div className="lineT line-between"></div>
      <div className="circle" onClick={() => navigate('/Entreprise/Numerique')}>
        <ComputerIcon/>
        {/* <div className="triangle"></div> */}
        <div className="line-down"></div>
        <div className="textT">Numérique</div>
      </div>

      {/* <div className="lineT line-between"></div>
      <div className="circle" onClick={() => navigate('/Dechet')}>
        <RecyclingIcon/>
        <div className="line-down"></div>
        <div className="textT">Consommation et déchets</div>
      </div> */}

    </div>
  );
}

export default StepperLineEntreprise;
