import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const Popup = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255,255,255,0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex : '999',
    }}>
      <div style={{
        width: '70%',
        height: '70%',
        backgroundColor: 'white',
        position: 'relative',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border : '1px solid black',
      }}>
        <CloseIcon style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          cursor: 'pointer',
        }} onClick={onClose} />
        {children}
      </div>
    </div>
  );
};

export default Popup;
