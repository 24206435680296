import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AppelAPITaxi from '../../Api/AppelAPITaxi';


function FormVoyage({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false); // nouvel état pour suivre la soumission du formulaire
  const [displayBilan, setDisplayBilan] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setDisplayBilan(true);
     // indique que le formulaire a été soumis SetDisplay Bilan (true) quand reponse api je repasse à false. le display de bilan inter se fera sur displaybilan
    console.log(formSubmitted);
  }


  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API
   

  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponse = (response) => {
    setApiResponse(response);
  };


  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);
    console.log(lancer);
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
    //setApiResponse(null); // Réinitialiser les données avant l'appel API
  };

  

  const [voyages, setVoyages] = useState([
    {
      typeVoyage: '',
      depart: {
        name: '',
        latitude: null,
        longitude: null
      },
      arrive: {
        name: '',
        latitude: null,
        longitude: null
      },
      ajout: { frequence: '', unit: '' },
    },
  ]);

  useEffect(() => {
    localStorage.setItem('voyages', JSON.stringify(voyages));
  }, [voyages]);

  const addVoyage = useCallback(() => {
    setVoyages((prevVoyages) => [
      ...prevVoyages,
      {
        typeVoyage: '',
        depart: {
          name: '',
          latitude: null,
          longitude: null
        },
        arrive: {
          name: '',
          latitude: null,
          longitude: null
        },
        ajout: { frequence: '', unit: '' },
      },
    ]);
  }, []);

  const handleChangeVoyage = useCallback((index, field, value) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        voyage[field] = value;
      }
      return updatedVoyages;
    });
  }, []);

  const handleChangeAjout = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        // Récupérer les valeurs de fréquence et unité
        const { frequence, unit } = values;
        
        // Transformer unit selon les conditions
        let unitValue;
        switch (unit) {
          case 'an':
            unitValue = 279640000;
            break;
          case 'mois':
            unitValue = 279640001;
            break;
          case 'semaine':
            unitValue = 279640002;
            break;
          default:
            unitValue = 279640000; // Défaut à an si unit n'est pas reconnu
        }
        
        // Mettre à jour les valeurs
        voyage.ajout = { frequence, unit: unitValue };
      }
      return updatedVoyages;
    });
  }, [setVoyages]);



  const handleDeleteVoyage = (index) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages.splice(index, 1);
      return updatedVoyages;
    });
  };
  
  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate("/Train"); 
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row col-12">
          <p className='p4'>
            Si votre voyage comporte une ou plusieurs escales, décomposez-le en plusieurs voyages.
          </p>
      </div>
  
      {voyages.map((voyage, index) => (
        <div className="duplique" key={index}>
          <div className="row col-12">
            <div className="SousTitre">
              <DeleteOutlinedIcon sx={{ color : "#024C4A"}} className="Icon" onClick={() => handleDeleteVoyage(index)} />
              <div className="h61">Voyage {index + 1}</div>
            </div>
          </div>
  
          <div className="row">
            <div className="col-lg-4 col-12">
              <ListDeroule
                titre="Type de voyage"
                value={voyage.typeVoyage === 279640001 ? 'Aller simple':'Aller - Retour'}
                onChange={(value) => handleChangeVoyage(index, 'typeVoyage', value==='Aller simple'? 279640001:279640000)}
                text="Aller - retour"
                mots={['Aller simple']}
              />
            </div>
            <div className="col-lg-4 col-12">
            <InputField
              titre="Départ"
              value={voyage.depart.name} // Use the name from the depart object
              onChange={(value) => handleChangeVoyage(index, 'depart', value)}
            />
            </div>
            <div className="col-lg-4 col-12">
            <InputField
              titre="Arrivée"
              value={voyage.arrive.name} // Use the name from the arrive object
              onChange={(value) => handleChangeVoyage(index, 'arrive', value)}
            />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Ajout values={voyage.ajout} setValues={(values) => handleChangeAjout(index, values)} />
            </div>
          </div>
        </div>
      ))}
  
      <div className="row">
        <div className="col-12">
          <button type="button" className="custom-button" onClick={addVoyage}>
            Ajouter un voyage
          </button>
        </div>
      </div>
  
      <div className="row">
        <div className="col-12">

          <Bouton titre="CALCULER" onClick={handleButtonClickAPI}/>
          
          {voyages.map((voyage, index) => (
              <AppelAPITaxi voyage={voyage} setLancer={setLancer} Lancer={lancer} setApiResponse={updateApiResponse}/>
              ))}
        </div>
      </div>
  
      {displayBilan && ( 
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInter numeroEtape={2} voyage="non" />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick}/>
              
            </div>
          </div>
        </div>
      )}
  
      
      <div className="row">
        <div className="col-12">
          <h3>Valeurs des voyages :</h3>
          {voyages.map((voyage, index) => (
            <div key={index}>
              <p>Voyage {index + 1}</p>
              <p>Type de voyage : {voyage.typeVoyage}</p>
              <p>Départ : {voyage.depart.name}</p>
              <p>Arrivée : {voyage.arrive.name}</p>
              <p>Fréquence : {voyage.ajout.frequence}</p>
              <p>unit : {voyage.ajout.unit}</p>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </form>
  );
}  

export default FormVoyage;
