import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CarreLogo.css';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Padding } from '@mui/icons-material';

const CarreLogoInput2 = ({ logo, titre, titregras, nombre = 2, unit = 'kWh', unit1 = '€', secondeMain, verification = false , onChange}) => {
  const [valeur1, setValeur1] = useState('');
  const [valeur2, setValeur2] = useState('');
  const [inputVisible, setInputVisible] = useState(true);

  const handleChangeValeur1 = (event) => {
    const newValue = event.target.value.replace(/\D/g, ''); // Supprimer les caractères non numériques
    if (verification && newValue !== '' && parseInt(newValue) > 100) {
       setValeur1('100');
     } else {
       setValeur1(newValue);
       onChange(newValue);

     }
    setInputVisible(newValue === '');
    //setValeur1(newValue);
    //onChange(newValue);
  };

  const handleChangeValeur2 = (event) => {
    const newValue = event.target.value.replace(/\D/g, ''); // Supprimer les caractères non numériques
    if (verification && newValue !== '' && parseInt(newValue) > 100) {
       setValeur2('100');
     } else {
       setValeur2(newValue);
       onChange(newValue);

     }
     setInputVisible(newValue === '');
  };

  const renderInputCL = () => {
    if (nombre === 1) {
      return (
        <div className='col-9 inputCL'>
          <input
            type='text'
            className="ajout-input"
            value={valeur1}
            onChange={handleChangeValeur1}
            pattern="[0-9]*"
          />
          <div className='KM'>{unit}</div>
        </div>
      );
      
    } else if (nombre === 2) {

      return (
        <>
          {inputVisible ? (
            <>
              <div className='col-4 inputCL2'>
                <input
                  type='text'
                  className="ajout-input"
                  value={valeur1}
                  onChange={handleChangeValeur1}
                  pattern="[0-9]*"
                />
                <div className='KM'>{unit}</div>
              </div>
              <div className='col-1 KM' style={{ fontSize: "10px", padding: "0px", paddingLeft: "5px" }}>ou</div>
              <div className='col-4 inputCL2 offset-1'>
                <input
                  type='text'
                  className="ajout-input"
                  value={valeur2}
                  onChange={handleChangeValeur2}
                  pattern="[0-9]*"
                />
                <div className='KM'>{unit1}</div>
              </div>
            </>
          ) : (
            <div className='col-9 inputCL'>
              <input
                type='text'
                className="ajout-input"
                value={valeur1 || valeur2}
                onChange={valeur1 ? handleChangeValeur1 : handleChangeValeur2}
                pattern="[0-9]*"
              />
              <div className='KM'>{valeur1 ? unit : unit1}</div>
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className='CarreLogoInput2'>
      <div className='GrasCarre None'>{titregras}</div>
      <div className='LogoCarre'>
        {secondeMain ? (<div><img src={logo} alt='Image' style={{opacity : 0.5}} /></div>) : (<img src={logo} alt='Image' />)}
      </div>
      <div className='titreCarre' style={{ fontSize: "10px" }}>{titre}</div>
      <div className='titreCarre row'>{renderInputCL()}</div>
    </div>
  );
};

export default CarreLogoInput2;





