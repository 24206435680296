import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import CheckboxChoixUnique from '../checkBox/checkbox';
import InputFieldNb from '../InputNombre/index';
import CheckboxAjout from '../checkBoxAjout/CheckboxAjout';
import AjoutPerso from '../Ajout/index-personne';
import { Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CheckboxChoixUnique3 from '../checkBox/checkbox-3';
import { useFormProgress } from '../../FormProgressContext';
import AppelAPITrain from '../../Api/AppelAPITrain';


function FormTrain({ onSubmit }) {
  const navigate = useNavigate();
  
  const [valeurInput, setValeurInput] = useState('0');
  const {formStatus, setFormStatus} = useFormProgress();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [displayBilan, setDisplayBilan] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState(false);
  const [typeCarburant, setTypeCarburant] = useState('');
  const [kmConnu, setkmConnu] = useState('');
  const [zoneGeoOui, setzoneGeoOui] = useState('');
  const [kilometrageConnu, setKilometrageConnu] = useState(false);
  const [unite, setUnite] = useState('');//Pour la valeur 'mois','semaine' etc quand on sélectionne oui
  const [selectedUnite, setSelectedUnite] = useState(279640000);
  // Fonction de gestion du changement de la valeur kmConnu
  const handleKmConnuChange = (value) => {
    // Mettre à jour la valeur kmConnu
    setkmConnu(value);
    
    // Si la valeur est "oui", mettre à jour la valeur kilometrageConnu à true
    if (value === "oui") {
      setKilometrageConnu(true);
    } else {
      setKilometrageConnu(false);
    }
  };

  const [voyages, setVoyages] = useState([
    {
      typeVoyage: '',
      zoneGeo:'',
      depart: {
        name: '',
        latitude: null,
        longitude: null
      },
      arrive: {
        name: '',
        latitude: null,
        longitude: null
      },
      ajout: { frequence: '', unit: 'an' },
      ajoutPerso: '' ,
    },
  ]);

  

  const isFormValid = voyages.every((voyage) => {
    if (kmConnu === "non") {
        // Le cas ou il y a un voyage à remplir
        return voyage.typeVoyage && voyage.depart.name && voyage.arrive.name && voyage.ajout.frequence;
    } else {
        return zoneGeoOui && valeurInput && selectedUnite;
    }
});

  

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate("/TC"); // Ajout de la navigation après la soumission du formulaire
  };

  const handleDeleteVoyage = (index) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages.splice(index, 1);
      return updatedVoyages;
    });
  };
  

  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API
   

  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponse = (response) => {
    setApiResponse(response);
  };


  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    //ssetFormSubmitted(false);
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
    //setApiResponse(null); // Réinitialiser les données avant l'appel API
  };

  // État pour stocker l'unité sélectionnée

  // Fonction pour récupérer et mapper l'unité sélectionnée
 
  const handleUniteChange = (newUnite) => {
    console.log('je suis dans handleUniteChange');
    let unitValue;
    switch (newUnite) {
      case 'an':
        unitValue = 279640000;
        break;
      case 'mois':
        unitValue = 279640001;
        break;
      case 'semaine':
        unitValue = 279640002;
        break;
      default:
        unitValue = 279640000; // Défaut à an si newUnite n'est pas reconnu
    }
    setSelectedUnite(unitValue); // Met à jour l'unité sélectionnée dans l'état
    console.log(newUnite);
  };



  const addVoyage = useCallback(() => {
    setVoyages((prevVoyages) => [
      ...prevVoyages,
      {
        typeVoyage: '',
        depart: {
          name: '',
          latitude: null,
          longitude: null
        },
        arrive: {
          name: '',
          latitude: null,
          longitude: null
        },
        ajout: { frequence: '', unit: 'an' },
        ajoutPerso : '',
      },
    ]);
  }, []);

    // Soumission du formulaire
    const handleSubmit = (event) => {
      event.preventDefault();
      if (isFormValid) {
        setFormSubmitted(true);
        setDisplayBilan(true);
        setFormStatus({
          ...formStatus,
          train: 'fini',
        });
      } else {
        alert('Veuillez remplir tous les champs requis avant de soumettre.');
      }
    };

    useEffect(() => {
      setFormStatus({
          ...formStatus,
          train: 'en cours',
      });
     }, [formStatus,setFormStatus]);
 
 
   useEffect(() => {
     if (isFormValid) {
       setFormStatus({
         ...formStatus,
         train: 'fini',
       });
     } else if (formSubmitted) {
       setFormStatus({
         ...formStatus,
         train: 'en cours',
       });
     }
   }, [voyages, formSubmitted, formStatus, isFormValid, setFormStatus]);
 

  const handleConsommationConnueChange = (value) => {
    setConsommationConnue(value);
  };

  const handleChangeVoyage = useCallback((index, field, value) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        if (field === 'depart' || field === 'arrive') {
          voyage[field] = { ...voyage[field], ...value };
        } else {
          voyage[field] = value;
        }
      }
      return updatedVoyages;
    });
  }, []);
  

  const handleChangeAjout = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      const i = 0;
      console.log("hello")
      if (voyage) {
        // Récupérer les valeurs de fréquence et unité
        const { frequence, unit } = values;
        
        // Transformer unit selon les conditions
        let unitValue;
        switch (unit) {
          case 'an':
            unitValue = 279640000;
            break;
          case 'mois':
            unitValue = 279640001;
            break;
          case 'semaine':
            unitValue = 279640002;
            break;
          default:
            unitValue = 279640000; // Défaut à an si unit n'est pas reconnu
        }
        
        // Mettre à jour les valeurs
        voyage.ajout = { frequence, unit: unitValue };
      }
      console.log(i+1);
      return updatedVoyages;
    });
  }, [setVoyages]);

  const handleChangeAjoutPerso = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        voyage.ajoutPerso = values;
      }
      return updatedVoyages;
    });
  }, []);
  

  return (
    <form onSubmit={handleSubmit}>

      <div className="row">
        <div className="col-12">
          <CheckboxAjout
            titre="Connaissez-vous le nombre de km parcourus en train ?"
            values={kmConnu}
            setValues={handleKmConnuChange}
            valeurInput={valeurInput}
            setValeurInput ={(valeurInput) => setValeurInput(valeurInput)}
            transport ="non"
            unit = "km"
            //unite={selectedUnite}
            setHandleUnite={handleUniteChange}
          />
        </div>
      </div>


      {kmConnu === "" ? ("") :kmConnu === "oui" ? (
        <CheckboxChoixUnique3 
        titre = "Dans quelle zone géographique avez-vous circulé en majorité ?" 
        rep1 = "En France" 
        rep2 = "France <-> Pays transfrontaliers" 
        rep3 = "En Europe (hors France)" 
        setReturn= {setzoneGeoOui}       
        />
        
      ) 
      :(
        <div>
        <div className="row">
          <div className="col-12">
            {voyages.map((voyage, index) => (
              <div className="duplique" key={index}>
                <div className="row col-12">
                  <div className="SousTitre">
                  <DeleteOutlinedIcon sx={{ color : "#024C4A"}} className="Icon" onClick={() => handleDeleteVoyage(index)} />
                    <div className="h61">Voyage {index + 1}</div>
                    </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-4">
                    <ListDeroule
                      titre="Voyage en"
                      text="France"
                      storageKey={`uniqueKey-ZoneGeo-${index}`}
                      value={voyage.zoneGeo}
                      onChange={(value) => {
                        let convertedValue;
                        switch (value) {
                          case "France":
                            convertedValue = 279640000;
                            break;
                          case "France <-> Pays transfrontaliers":
                            convertedValue = 279640001;
                            break;
                          case "en Europe (hors France)":
                            convertedValue = 279640002;
                            break;
                          default:
                            convertedValue = value; 
                          }
                        handleChangeVoyage(index, 'zoneGeo', convertedValue);}}
                      mots={["France <-> Pays transfrontaliers", "en Europe (hors France)"]} 
                      />
                  </div>
                  <div className="col-12 col-md-4">
                    <ListDeroule
                      titre="Type de voyage"
                      text="Aller - retour"
                      storageKey={`uniqueKey-typeVoyage-${index}`}
                      value={voyage.typeVoyage === 279640001 ? 'Aller simple':'Aller - Retour'}
                      onChange={(value) => handleChangeVoyage(index, 'typeVoyage', value==='Aller simple'? 279640001:279640000)}
                      mots={['Aller simple']}
                      />
                  </div>

                </div>
                  
                <div className="row" style={{marginTop : "3vh"}}>
                  <div className="col-12 col-md-4">
                  <InputField
                    titre="Départ"
                    value={voyage.depart.name} // Use the name from the depart object
                    onChange={(value) => handleChangeVoyage(index, 'depart', value)}
                  />
                  </div>
                  <div className="col-12 col-md-4">
                    <InputField
                      titre="Arrivée"
                      value={voyage.arrive.name} // Use the name from the arrive object
                      onChange={(value) => handleChangeVoyage(index, 'arrive', value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Ajout
                      values={voyage.ajout}
                      setValues={(values) => 
                        handleChangeAjout(index, values)
                      }
                    />
                  </div>


                </div>
              </div>
            ))}
          </div>
        </div>
     

      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className="custom-button"
            onClick={addVoyage}
          >
            Ajouter un voyage
          </button>
        </div>
      </div>
      </div>
      )}

                

      <div className="row">
        <div className="col-12">

        <Bouton titre="CALCULER"  onClick={handleButtonClickAPI}/>
        {voyages.map((voyage, index) => (
              <div className="duplique" key={index}>

              <AppelAPITrain voyage={voyage} kmConnu={kmConnu} km={valeurInput} zoneGeoOui={zoneGeoOui} selectedUnite={selectedUnite} setLancer={setLancer} Lancer={lancer} setApiResponse={updateApiResponse}/> 
              
              {/* Affichage des valeurs spécifiques pour ce voyage pour test*/}
                  {/* <div className="row">
                    <div className="col-12">
                      <div className='h61'>Valeurs spécifiques pour Voyage {index + 1} :</div>
                      <div>IdPage : PTrain</div>
                      <div>kmConnu ? : {kmConnu}</div>
                      <div>ZoneGeo oui? : {zoneGeoOui}</div>
                      <div>km : {valeurInput}</div>
                      <div>Unité de voyage : {selectedUnite}</div>
                      <div>ZoneGeo : {voyage.zoneGeo}</div>
                      <div>typeVoyage : {voyage.typeVoyage}</div>
                      <div>depart : {voyage.depart.name}</div>
                      <div>latitudeDepart : {voyage.depart.latitude}</div>
                      <div>longitudeDepart : {voyage.depart.longitude}</div>
                      <div>arrive : {voyage.arrive.name}</div>
                      <div>latitudeArrive : {voyage.arrive.latitude}</div>
                      <div>longitudeArrive : {voyage.arrive.longitude}</div>
                      <div>frequence : {voyage.ajout.frequence}</div>
                      <div>unit : {voyage.ajout.unit}</div>
                      <div>message : {voyage.emptyFieldsMessage}</div>
                    </div>
                  </div> */}
                </div>
              ))}

        </div>
      </div>

      {displayBilan && (
        <><div className="row">
          <div className="col-12">
            <BilanInter voyages={voyages} numeroEtape={3} handleDeleteVoyage={handleDeleteVoyage} responseAPITrain={apiResponse} />
            {console.log(apiResponse)}
            {apiResponse && (
            <div>
              <h2>Réponse de l'API :</h2>
              <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
            </div>
          )}
          </div>
        </div><div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />

            </div>
          </div></>
      )}
    </form>
  );
}

export default FormTrain;
