import React, { useState } from 'react';
import ListDeroule from '../ListDeroule';
import InputField from '../Input';
import Bouton from '../Bouton';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import '../Form Particulier/form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import "./Connection.css"

function FormCreeCompte() {
  const [structure, setStructure] = useState('');
  const [secteur, setSecteur] = useState('');
  const [employes, setEmployes] = useState('');
  const [adresse, setAdresse] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [ville, setVille] = useState('');
  const [pays, setPays] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [Alerte, setAlerte] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Vérification des champs remplis
    if (
      structure &&
      secteur &&
      employes &&
      adresse &&
      codePostal &&
      ville &&
      pays
    ) {
      setAlerte(true);
      setFormSubmitted(true);
    } else {
      setAlerte(false);
      setFormSubmitted(false);
      console.log(Alerte);
    }
  };

  return (
    <form className='Connection'>
      <div className="row col-12 justify-content-center">
        <div className="SousTitre">
          <Titre
            Titre="Votre entreprise ou association"
            place="center"
            color="#024C4A"
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <ListDeroule
            titre="Structure"
            value={structure}
            onChange={setStructure}
            options={['Option 1', 'Option 2', 'Option 3']} // Remplacez par les options réelles
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Nom de la Structure"
            value={structure}
            onChange={setStructure}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <ListDeroule
            titre="Secteur"
            value={secteur}
            onChange={setSecteur}
            options={['Option 1', 'Option 2', 'Option 3']} // Remplacez par les options réelles
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Nombre d'employés"
            value={employes}
            onChange={setEmployes}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Adresse"
            value={adresse}
            onChange={setAdresse}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Code postal"
            value={codePostal}
            onChange={setCodePostal}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField titre="Ville" value={ville} onChange={setVille} />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField titre="Pays" value={pays} onChange={setPays} />
        </div>
      </div>

      {formSubmitted && (
        <div className="row">
          <div className="col-12">
            <p>Structure: {structure}</p>
            <p>Secteur: {secteur}</p>
            <p>Nombre d'employés: {employes}</p>
            <p>Adresse: {adresse}</p>
            <p>Code postal: {codePostal}</p>
            <p>Ville: {ville}</p>
            <p>Pays: {pays}</p>
          </div>
        </div>
      )}

      {!formSubmitted && !Alerte && (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                display:
                  structure &&
                  secteur &&
                  employes &&
                  adresse &&
                  codePostal &&
                  ville &&
                  pays
                    ? 'none'
                    : 'block'
              }}
            >
              Veuillez remplir tous les champs !
            </div>
          </div>
        </div>
      )}


      {!formSubmitted && (
        <div className="row">
          <div className="col-12">
            <Bouton
              type="submit"
              titre="Suivant"
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}

    </form>
  );
}

export default FormCreeCompte;
