import React, { useState, useEffect } from 'react';

const SelectElement = ({ values, setValues, onChange }) => {
  

  

  const handleChangeUnite = (event) => {
    setValues(event.target.value);
    onChange(event.target.value);
    console.log(event.target.value);
    
  };

  return (
    <select className="ajout-select" value={values} onChange={handleChangeUnite}>
      <option value="an">par an</option>
      <option value="mois">par mois</option>
      <option value="semaine">par semaine</option>
    </select>
  );
}

export default SelectElement;