import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import CheckboxChoixUnique from '../checkBox/checkbox';
import InputFieldNb from '../InputNombre/index';
import CheckboxAjout from '../checkBoxAjout/CheckboxAjout';
import { Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import axios from 'axios';
import Voyages from './cityForm';
import { useFormProgress } from '../../FormProgressContext';
import BilanInterRequete from '../../BilanInter/indexRequete';
import AppelAPIVoiture from '../../Api/AppelAPIVoiture';
import AjoutPerso from '../Ajout/index-personne';

const VEHICULE_VALUES = {
  'Berline': 279640000,
  'Entrée de gamme': 279640001,
  'Coeur de gamme': 279640002,
  'Haut de gamme': 279640003
};

const CARBURANT_VALUES = {
  'Essence': 279640000,
  'Electrique': 279640001,
  'Hybride': 279640002,
  'GPL': 279640003
};

function FormVoiture({ onSubmit }) {
  const navigate = useNavigate();
  const [valeurInput, setValeurInput] = useState('7');
  const [consommationConnue, setConsommationConnue] = useState("");
  const [typeCarburant, setTypeCarburant] = useState(279640000); // Initialiser à la valeur de 'Essence'
  const [typeVehicule, setTypeVehicule] = useState(279640000); // Initialiser à la valeur de 'Berline'
  const [typeKilo, setTypeKilo] = useState('oui');
  const [consommation, setConsommation] = useState('');
  const [unit, setUnit] = useState(279640000);
  const [kilometrageConnu, setKilometrageConnu] = useState("oui");
  const formulaireId = 'PVoiture'; // Remplacez par un identifiant unique pour chaque formulaire
  const { formStatus, setFormStatus } = useFormProgress();

  const [displayBilan, setDisplayBilan] = useState(false);

  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API

  const handleUnitChange = (value) => {
    setUnit(value === 'L/100 km' ? 279640000 : 279640001);
  };

  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponse = (response) => {
    setApiResponse(response);
  };


  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
    //setApiResponse(null); // Réinitialiser les données avant l'appel API
  };

  const [formSubmitted, setFormSubmitted] = useState(() => {
    // Récupérer la valeur de formSubmitted depuis le localStorage
    const savedFormSubmitted = localStorage.getItem('formSubmitted');
    return savedFormSubmitted === 'true'; // Convertir en booléen
  });  
  
  const [resultatsRequetes, setResultatsRequetes] = useState([]); // État pour les résultats de requête
  const [voyages, setVoyages] = useState([
    {
      typeVoyage: '',
      depart: {
        name: '',
        latitude: null,
        longitude: null
      },
      arrive: {
        name: '',
        latitude: null,
        longitude: null
      },
      ajout: { frequence: '1', unit: 'an' },
      ajoutPerso: '',
    },
  ]);
  
  const [selectedUnite, setSelectedUnite] = useState(279640000); // État pour stocker l'unité sélectionnée

  // Fonction pour récupérer et mapper l'unité sélectionnée
 
  const handleUniteChange = (newUnite) => {
    console.log('je suis dans handleUniteChange');
    let unitValue;
    switch (newUnite) {
      case 'an':
        unitValue = 279640000;
        break;
      case 'mois':
        unitValue = 279640001;
        break;
      case 'semaine':
        unitValue = 279640002;
        break;
      default:
        unitValue = 279640000; // Défaut à an si newUnite n'est pas reconnu
    }
    setSelectedUnite(unitValue); // Met à jour l'unité sélectionnée dans l'état
    console.log(newUnite);
  };

  // Fonction pour gérer le clic sur le bouton de navigation
  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Train'); // Ajout de la navigation après la soumission du formulaire
  };

    // Cette fonction sera appelée depuis le composant Voyages pour mettre à jour les résultats de requête et les voyages
    const handleRequeteTerminee = (resultat, voyageIndex, updatedVoyages) => {
      setResultatsRequetes((prevResultats) => {
        const updatedResultats = [...prevResultats];
        updatedResultats[voyageIndex] = resultat;
        return updatedResultats;
      });
  
      setVoyages(updatedVoyages);
    };

  // Fonction pour gérer le changement de la valeur "consommationConnue"
  const handlekilometrageConnu = (value) => {
    setKilometrageConnu(value);
    localStorage.setItem('kilometrageConnu', value); // Save in localStorage
  };

  const handleTypeVehiculeChange = (value) => {
    setTypeVehicule(VEHICULE_VALUES[value]);
  };

  const handleTypeCarburantChange = (value) => {
    setTypeCarburant(CARBURANT_VALUES[value]);
  };


  // Fonction pour gérer le changement de la valeur "consommationConnue"
  const handleConsommationConnueChange = (value) => {
    setConsommationConnue(value);
    localStorage.setItem('consommationConnue', value); // Save in localStorage
  };

  useEffect(() => {
    // Retrieve consommationConnue from localStorage
    const savedConsommationConnue = localStorage.getItem('consommationConnue');
    if (savedConsommationConnue) {
      setConsommationConnue(savedConsommationConnue);
    }
    const savedkilometrageConnu= localStorage.getItem('kilometrageConnu');
    if (savedkilometrageConnu) {
      setKilometrageConnu(savedkilometrageConnu);
    }
  }, []);
  
  useEffect(() => {
    setFormStatus({
        ...formStatus,
        train: 'en cours',
    });
   }, []);


  useEffect(() => {
    if (displayBilan) {
      setFormStatus({
        ...formStatus,
        voiture: 'fini',
      });
    } else if (formSubmitted) {
      setFormStatus({
        ...formStatus,
        voiture: 'en cours',
      });
    }
  }, [voyages, formSubmitted]);
  



// Fonction pour gérer la soumission du formulaire
const handleSubmit = (event) => {
  event.preventDefault(); // Prevent default form submission
  setFormSubmitted(true);
  setDisplayBilan(true);
  setFormStatus({
    ...formStatus,
    voiture: 'fini',
  });
  // Enregistrer formSubmitted dans le localStorage
  localStorage.setItem('formSubmitted', 'true');
};

  // Fonction pour gérer le changement des valeurs d'un voyage de la liste
  const handleChangeVoyage = useCallback((index, field, value) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        if (field === 'depart' || field === 'arrive') {
          voyage[field] = { ...voyage[field], ...value };
        } else {
          voyage[field] = value;
        }
      }
      return updatedVoyages;
    });
  }, []);

  // Fonction pour gérer le changement des valeurs d'ajout d'un voyage de la liste
  const handleChangeAjout = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        // Récupérer les valeurs de fréquence et unité
        const { frequence, unit } = values;
        
        // Transformer unit selon les conditions
        let unitValue;
        switch (unit) {
          case 'an':
            unitValue = 279640000;
            break;
          case 'mois':
            unitValue = 279640001;
            break;
          case 'semaine':
            unitValue = 279640002;
            break;
          default:
            unitValue = 279640000; // Défaut à an si unit n'est pas reconnu
        }
        
        // Mettre à jour les valeurs
        voyage.ajout = { frequence, unit: unitValue };
      }
      return updatedVoyages;
      
    });
  }, [setVoyages]);

  const handleDeleteVoyage = (index) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages.splice(index, 1);
      return updatedVoyages;
    });
  };


  // Fonction pour gérer le changement des valeurs d'ajout personnel d'un voyage de la liste
  const handleChangeAjoutPerso = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        voyage.ajoutPerso = values;
      }
      return updatedVoyages;
    });
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      {/* Checkbox pour choisir si la consommation est connue */}
      <div className="row col-12">
        <CheckboxChoixUnique
          titre="Connaissez-vous votre consommation ?"
          rep1="Oui"
          rep2="Non, mais aidez-moi à la calculer"
          onChange={(value) => handleConsommationConnueChange(value)}
        />
      </div>

      {/* Affichage conditionnel en fonction de la valeur de "consommationConnue" */}
      {consommationConnue === "" ? null : consommationConnue ? (
        <div className="row">
          {/* Liste déroulante pour le type d'énergie */}
          <div className="col-12 col-md-4">
            <ListDeroule
              titre="Type d'énergie"
              text="Essence"
              storageKey="type-carburant"
              value={Object.keys(CARBURANT_VALUES)[typeCarburant]}
              onChange={handleTypeCarburantChange}
              mots={['Electrique', 'Hybride', 'GPL']}
            />
          </div>
          {/* Champ d'entrée pour la consommation */}
          <div className="col-12 col-md-4">
            <InputFieldNb
              titre="Consommation"
              value={consommation}
              onChange={(value) => setConsommation(value)}
              valueSelect={unit === 279640000 ? 'L/100 km' : 'kW/h'}
              onChangeSelect={handleUnitChange}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          {/* Liste déroulante pour le type d'énergie */}
          <div className="col-12 col-md-4">
            <ListDeroule
              titre="Type d'énergie"
              text="Essence"
              storageKey="type-carburant"
              value={Object.keys(CARBURANT_VALUES)[typeCarburant]}
              onChange={handleTypeCarburantChange}
              mots={['Electrique', 'Hybride', 'GPL']}
            />
          </div>
          {/* Liste déroulante pour le type de véhicule */}
          <div className="col-12 col-md-4">
            <ListDeroule
              titre="Type de véhicule"
              text="Berline"
              storageKey="type-vehicule"
              value={Object.keys(VEHICULE_VALUES)[typeVehicule]}
              onChange={handleTypeVehiculeChange}
              mots={['Entrée de gamme', 'Coeur de gamme', 'Haut de gamme']}
            />
          </div>
        </div>
      )}


      {consommationConnue === "" ? null : (
      <div className="row">
        <div className="col-12">
          {/* Composant CheckboxAjout */}
          <CheckboxAjout
            titre="Connaissez-vous votre kilométrage annuel ?"
            values={kilometrageConnu}
            setValues={(values) => handlekilometrageConnu(values)}
            valeurInput={valeurInput}
            transport="non"
            setValeurInput={(valeurInput) => setValeurInput(valeurInput)}
            unit='km'
            setHandleUnite={handleUniteChange}
          />
        </div>
      </div>)}

      {/* Condition de rendu basée sur la valeur de "kilometrageConnu" */}
      {kilometrageConnu === 'oui' ? null : (
        <div className="row">
           

<div className="col-12">
                {/* Affichage dynamique des voyages */}
                {voyages.map((voyage, index) => (
                  <div className="duplique" key={index}>
                    <div className="row col-12">
                      <div className="SousTitre">
                        {/* Icône de suppression du voyage */}
                        <DeleteOutlinedIcon
                          sx={{ color: '#024C4A' }}
                          className="Icon"
                          onClick={() => handleDeleteVoyage(index)}
                        />
                        <div className="h61">Voyage {index + 1}</div>
                      </div>
                    </div>

                    <div className="row">
                      {/* Liste déroulante pour le type de voyage */}
                      <div className="col-12 col-lg-4">
                        <ListDeroule
                          titre="Type de voyage"
                          text="Aller - retour"
                          storageKey={`uniqueKey-${index}`}
                          value={voyage.typeVoyage === 279640001 ? 'Aller simple':'Aller - Retour'}
                          onChange={(value) => 
                            handleChangeVoyage(index, 'typeVoyage', value==='Aller simple'? 279640001:279640000)}
                          mots={['Aller simple']}
                          />
                      </div>
                      {/* Champ d'entrée pour le départ */}
                      <div className="col-lg-4 col-md-6 col-12">
                      <InputField
                        titre="Départ"
                        value={voyage.depart.name} // Use the name from the depart object
                        onChange={(value) => handleChangeVoyage(index, 'depart', value)}
                      />
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                      <InputField
                        titre="Arrivée"
                        value={voyage.arrive.name} // Use the name from the arrive object
                        onChange={(value) => handleChangeVoyage(index, 'arrive', value)}
                      />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-lg-6">
                        {/* Composant Ajout pour la fréquence */}
                        <Ajout
                          values={voyage.ajout}
                          setValues={(values) =>
                            handleChangeAjout(index, values)
                          }
                        />
                      </div>

                      <div className="col-12 col-lg-6">
                        {/* Composant AjoutPerso pour la fréquence personnalisée */}
                        <AjoutPerso
                          values={voyage.ajoutPerso}
                          setValues={(values) =>
                            handleChangeAjoutPerso(index, values)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
          </div>
          )}

          {voyages.map((voyage, index) => (
              <div className="duplique" key={index}>
              {/* <AppelAPIVoiture voyage={voyage} typeVehicule={typeVehicule} typeCarburant={typeCarburant} valeurInput={valeurInput} consommation={consommation} kilometrageConnu={kilometrageConnu} unit={unit} setLancer={setLancer} Lancer={true} setApiResponse={updateApiResponse}/>  */}
              
              {/* Affichage des valeurs spécifiques pour ce voyage */}
                  {/* <div className="row">
                    <div className="col-12">
                      <div className='h61'>Valeurs spécifiques pour Voyage {index + 1} :</div>
                      <div>IdPage : PVoiture</div>
                      <div>kmConnu : {kilometrageConnu}</div>
                      <div>km : {valeurInput}</div>
                      <div>Type Carburant : {typeCarburant}</div>
                      <div>Type Véhicule : {typeVehicule}</div>
                      <div>kilométrage connu fréquence : {selectedUnite}</div>
                      <div>Consommation : {consommation}</div>
                      <div>Unité Consommation : {unit}</div>
                      <div>typeVoyage : {voyage.typeVoyage}</div>
                      <div>depart : {voyage.depart.name}</div>
                      <div>latitudeDepart : {voyage.depart.latitude}</div>
                      <div>longitudeDepart : {voyage.depart.longitude}</div>
                      <div>arrive : {voyage.arrive.name}</div>
                      <div>latitudeArrive : {voyage.arrive.latitude}</div>
                      <div>longitudeArrive : {voyage.arrive.longitude}</div>
                      <div>frequence : {voyage.ajout.frequence}</div>
                      <div>nbPersonnes : {voyage.ajoutPerso.valeur}</div> 
                      <div>unit : {voyage.ajout.unit}</div>
                      <div>message : {voyage.emptyFieldsMessage}</div>
                    </div>
                  </div> */}
                </div>
              ))}

            <div className="row">
              <div className="col-12">
                
                {/* Bouton de soumission du formulaire */}
                <Bouton titre="CALCULER" onClick={handleButtonClickAPI} />
                {voyages.map((voyage, index) => (
                  <div className="duplique" key={index}>
                    <AppelAPIVoiture voyage={voyage} typeVehicule={typeVehicule} typeCarburant={typeCarburant} 
                    valeurInput={valeurInput} selectedUnite={selectedUnite} consommation={consommation} kilometrageConnu={kilometrageConnu} 
                    unit={unit} setLancer={setLancer} Lancer={lancer} setApiResponse={updateApiResponse}/> 
              
                  </div>
                  
              ))}
              


                
              </div>
            </div>

      
      {displayBilan && (
        <div>
          <div className="row">
            <div className="col-12">

              
              {/* Composant BilanInter pour afficher les résultats */}
              <BilanInter
                voyages={kilometrageConnu === 'oui' ? [] : voyages}
                numeroEtape={2}
                handleDeleteVoyage={handleDeleteVoyage}
                responseAPIVoiture ={apiResponse}
                
              /> 
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              {/* Bouton pour passer à la catégorie suivante */}
              <Bouton
                titre="Catégorie suivante"
                onClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default FormVoiture;
