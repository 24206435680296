import React, { useState, useEffect } from 'react';
import './Ajout.css';
import Compteur from '../../Compteur/Compteur';

const AjoutNume = ({ values, setValues }) => {
  const [valeurCompteur, setValeurCompteur] = useState(values.valeur || '7');
  const [valeurAjout, setValeurAjout] = useState(values.valeur || '7');
  const [unite, setUnite] = useState(values.unite || 'an');

  const handleChangeCompteurValeur = (newValue) => {
    setValeurCompteur(newValue);
  };

  const handleChangeAjoutValeur = (newValue) => {
    setValeurAjout(newValue);
  };

  const handleChangeUnite = (event) => {
    setUnite(event.target.value);
  };

  useEffect(() => {
    setValues({ valeur: valeurAjout, unite });
  }, [valeurAjout, unite, setValues]);

  return (
    <div>
      <div className="ajout-container">
      <div className="col-12 row">
        <span className="ajout-label col-lg-4 col-12">Nombre de mails envoyés en moyenne</span>

        <div className="col-lg-6 col-12">
          <button className="ajout-button" onClick={() => handleChangeAjoutValeur(parseInt(valeurAjout) - 1)}>-</button>
          <input
            type='text'
            className="ajout-input"
            value={valeurAjout}
            onChange={(event) => handleChangeAjoutValeur(event.target.value.replace(/\D/g, ''))}
            pattern="[0-9]*"
          />
          <button className="ajout-button" onClick={() => handleChangeAjoutValeur(parseInt(valeurAjout) + 1)}>+</button>
        </div>
        <select className="ajout-select" value={unite} onChange={handleChangeUnite}>
          <option value="an">par jour/salarié</option>
          <option value="mois">par mois/salarié</option>
          <option value="semaine">par semaine/salarié</option>
        </select>
      </div>
      </div>

      <div className="row col-5">
        <Compteur
          unit='% avec pièce jointe'
          onValeurChange={handleChangeCompteurValeur}
          val={valeurCompteur}
          block={false}
          italique={true}
        />
      </div>
      
    
    </div>
  );
};

export default AjoutNume;
