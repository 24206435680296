import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Import du hook personnalisé pour l'authentification
import { useAuth } from '../../../Authentification/Auth';

// Composant pour envoyer une requête de test
function EnvoyerRequeteTest({ voyage, IdPage, Url, onRequeteTerminee, voyageIndex }) {
  // // Utilisation du hook `useAuth` pour obtenir le token et la fonction `fetchToken`
  // const { token, fetchToken } = useAuth();

  // // Utilisation de l'état local pour gérer la réponse et l'état de chargement
  // const [response, setResponse] = useState(null);
  // const [loading, setLoading] = useState(false);

  // // Utilisation de `useEffect` pour effectuer la requête lorsque le token change
  // useEffect(() => {
  //   // Vérification si le token est disponible avant d'effectuer la requête
  //   if (token) {
  //     console.log(voyage);
  //     fetchData();
  //   }
  // }, [token]); // Se ré-exécute lorsque `token` change

  // // Fonction pour envoyer la requête de test
  // const fetchData = async () => {
  //   setLoading(true);

  //   // Préparation des données à envoyer dans la requête
  //   /* const data = {
  //     IdBC: "b2c25702-f76c-ee11-8178-000d3a951a7d",
  //     IdPage: "PAvion",
  //     typeVoyage: 279640000,
  //     depart: "Paris, France",
  //     latitudeDepart: 48.8566,
  //     longitudeDepart: 2.3522,
  //     arrivee: "Marseille, France",
  //     latitudeArrivee: 43.2964,
  //     longitudeArrivee: 5.3697,
  //     frequence: 1,
  //     unit: 279640000
  //   }; */
  //   const data = {
  //     IdBC: "b2c25702-f76c-ee11-8178-000d3a951a7d",
  //     IdPage: "PAvion",
  //     typeVoyage: 279640000 ,
  //     depart: voyage.depart.name,
  //     latitudeDepart: voyage.depart.latitude,
  //     longitudeDepart: voyage.depart.longitude ,
  //     arrivee: voyage.arrive.name,
  //     latitudeArrivee: voyage.arrive.latitude,
  //     longitudeArrivee: voyage.arrive.longitude,
  //     frequence: voyage.ajout.frequence,
  //     unit: 279640000
  //   };
  //   // Options de la requête avec l'en-tête d'autorisation
  //   const options = {
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //       'Content-Type': 'application/json'
  //     }
  //   };
    
  //   try {
  //     // Envoi de la requête POST avec les données et les options
  //     const result = await axios.post(Url, data, options);

  //     // Mise à jour de la réponse et appel de la fonction `onRequeteTerminee`
  //     setResponse(result.data);
  //     console.log(response);
  //     //onRequeteTerminee(result.data, voyageIndex);
  //   } catch (error) {
  //     // Gestion des erreurs en cas d'échec de la requête
  //     console.error("Erreur lors de l'envoi de la requête :", error);
  //     setResponse('Erreur lors de la récupération des données.');
  //     onRequeteTerminee(null, voyageIndex);
  //   } finally {
  //     // Fin de la requête, quel que soit le résultat
  //     setLoading(false);
  //   }    
  // };

  // Rendu du composant (dans cet exemple, il ne rend rien, il exécute seulement des actions)
  return null;
}

export default EnvoyerRequeteTest;
