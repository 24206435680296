import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import CheckboxChoixUnique from '../checkBox/checkbox';
import Compteur from '../../Compteur/Compteur';
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import LogoPapier from "../../img/papier1.png";
import LogoBureautique from "../../img/Bureautique.png";
import LogoFourniture from "../../img/Fourniture.png";
import BilanInterEnt from '../../BilanInter/indexEntreprise';
import InputFieldNbUnique from '../InputNombre/indexUnit';


function FormBatiment({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(formSubmitted);
  }

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Entreprise/Numerique'); // Ajout de la navigation après la soumission du formulaire
  };

  const handleConsommationConnueChange = (value) => {
    setConsommationConnue(value);
  };

  const [batiments, setBatiments] = useState([
    {
      Surface: '',
      Type: '',
      Structure: ''
    },
  ]);

  useEffect(() => {
    localStorage.setItem('batiments', JSON.stringify(batiments));
  }, [batiments]);

  const addBatiment = useCallback(() => {
    setBatiments((prevBatiments) => [
      ...prevBatiments,
      {
        Surface: '',
        Type: '',
        Structure: ''
      },
    ]);
  }, []);

  const handleChangeBatiment = useCallback((index, field, value) => {
    setBatiments((prevBatiments) => {
      const updatedBatiments = [...prevBatiments];
      const batiment = updatedBatiments[index];
      if (batiment) {
        batiment[field] = value;
      }
      return updatedBatiments;
    });
  }, []);

  const handleDeleteBatiment = (index) => {
    setBatiments((prevBatiments) => {
      const updatedBatiments = [...prevBatiments];
      updatedBatiments.splice(index, 1);
      return updatedBatiments;
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row col-12">
      </div>
  
      {batiments.map((batiment, index) => (
        <div className="duplique" key={index}>
          <div className="row col-12">
            <div className="Locaux">
              <DeleteOutlinedIcon sx={{ color: "#024C4A" }} className="Icon" onClick={() => handleDeleteBatiment(index)} />
              <div className="h61">Bâtiment {index + 1}</div>
            </div>
          </div>
  
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12" style={{ marginTop: "1vh" }}>
              <InputFieldNbUnique
                titre="Surface construite"
                value={batiment.Surface}
                onChange={(value) => handleChangeBatiment(index, 'Surface', value)}
                unit="m²"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <ListDeroule
                titre="Type d'activité"
                text="Commerce"
                storageKey={`uniqueKey-${index}`}
                value={batiment.Type}
                onChange={(value) => handleChangeBatiment(index, 'Type', value)}
                mots={["Bureau", "Enseignement", "Santé", "Autres", "Bâtiment agricole", "Bâtiment industriel"]}
              />
            </div>
  
            <div className="col-lg-4 col-md-6 col-12">
              <ListDeroule
                titre="Type de structure"
                text="Béton"
                storageKey={`uniqueKey-${index}`}
                value={batiment.Structure}
                onChange={(value) => handleChangeBatiment(index, 'Structure', value)}
                mots={["Métal"]}
              />
            </div>
          </div>
        </div>
      ))}
  
      <div className="row">
        <div className="col-12">
          <button type="button" className="custom-button" onClick={addBatiment}>
            Ajouter un bâtiment
          </button>
        </div>
      </div>
  
      <div className="row col-12" style={{marginTop : "2vh"}}>
        <Titre Titre="Véhicule de société/fonction de moins de 10 ans" />
      </div>
      <div className="row col-12">
        <CheckboxChoixUnique
          titre="Connaissez-vous le poids total des véhicules en tonnes ?"
          rep1="Oui"
          rep2="Non"
          onChange={(value) => setConsommationConnue(value)}
        />
      </div>
  
      {consommationConnue === "" ? ("") : consommationConnue ? 
      (
        <div>
        <div><Compteur titre="Poid total" unit="tonnes" /></div>
        
        <div className="row col-12" style={{marginTop : "2vh"}}>
        <Titre Titre="Equipement informatique de moins de 3 ans" />
        </div>
        <div className="row">
          <div className="row">
            <div className="col-md-6 col-12"><Compteur titre="Nombre de postes fixes :" unit="ordinateur(s)" /></div>
            <div className="col-md-6 col-12"><Compteur titre="Nombre d’ordinateurs portables :" unit="ordinateur(s)" /></div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12"><Compteur titre="Nombre d’imprimantes individuelles :" unit="imprimante(s)" /></div>
            <div className="col-md-6 col-12"><Compteur titre="Nombre d’imprimantes multifonctions :" unit="imprimante(s)" /></div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12"><Compteur titre="Nombres d’écrans plats :" unit="écran(s)" /></div>
            <div className="col-md-6 col-12"><Compteur titre="Nombre de serveurs :" unit="serveur(s)" /></div>
          </div>
        </div>
  
      </div>
      ) :
      <div>
        <div className="row">
          <div className="col-9">
            <div className="row"><Compteur titre="Nombre de véhicules légers (voiture) :" unit="véhicule(s)" /></div>
            <div className="row"><Compteur titre="Nombre de véhicules utilitaires (PTAC <= 3,5 t) :" unit="véhicule(s)" /></div>
            <div className="row"><Compteur titre="Nombre de véhicules lourds (PTAC > 3,5 t) :" unit="véhicule(s)" /></div>
          </div>
          <div className="col-12 row" style={{marginTop : "2vh"}}>
            <p className='p1'>PTAC : Poids Total Autorisé en Charge</p>
          </div>
        </div>
      
  
      <div className="row col-12" style={{marginTop : "2vh"}}>
        <Titre Titre="Equipement informatique de moins de 3 ans" />
      </div>
      <div className="row">
        <div className="row">
          <div className="col-md-6 col-12"><Compteur titre="Nombre de postes fixes :" unit="ordinateur(s)" /></div>
          <div className="col-md-6 col-12"><Compteur titre="Nombre d’ordinateurs portables :" unit="ordinateur(s)" /></div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12"><Compteur titre="Nombre d’imprimantes individuelles :" unit="imprimante(s)" /></div>
          <div className="col-md-6 col-12"><Compteur titre="Nombre d’imprimantes multifonctions :" unit="imprimante(s)" /></div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12"><Compteur titre="Nombres d’écrans plats :" unit="écran(s)" /></div>
          <div className="col-md-6 col-12"><Compteur titre="Nombre de serveurs :" unit="serveur(s)" /></div>
        </div>
      </div>
  
      </div>
      }
      <div className="row">
        <div className="col-12">
          <Bouton titre="Calculer" />
        </div>
      </div>
  
      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInterEnt voyages={[]} numeroEtape={4} />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton
                titre="Catégorie suivante"
                onClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
  
}

export default FormBatiment;

