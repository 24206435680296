import React, { useState } from 'react';
import axios from 'axios';

function BoutonToken() {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchToken = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await axios.post("http://localhost:4000/gettoken");
      setToken(response.data.access_token);
    } catch (err) {
      setError("An error occurred while fetching the token");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div style={{ padding: "20px" }}>
      <h1>OAuth2 Token Request</h1>
      <button onClick={fetchToken} disabled={loading}>
        {loading ? "Loading..." : "Get Token"}
      </button>
      {token && (
        <div>
          <h2>Access Token:</h2>
          <pre style={{ overflowX: "auto" }}>{token}</pre>
        </div>
      )}
      {error && (
        <div style={{ color: 'red' }}>
          {error}
        </div>
      )}
    </div>
  );
}

export default BoutonToken;
