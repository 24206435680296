import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import CheckboxChoixUnique from '../checkBox/checkbox';
import InputFieldNbUnique from '../InputNombre/indexUnit';
import AjoutPerso from '../Ajout/index-personne';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CheckboxLabels from '../CheckBoxMulti';
import BilanInterEnt from '../../BilanInter/indexEntreprise';


function FormFret({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState("");
  const [voyages, setVoyages] = useState([
    {
      typeVoyage: '',
      prenom: '',
      nom: '',
      ajout: { valeur: '30', unite: 'an' },
      ajoutPerso: '',
    },
  ]);

  const [conso, setConso] = useState([
    {
      titre: 'Consommation d’essence',
      unite: 'L',
      valeur: ''
    },
    {
      titre: 'Consommation de Diesel',
      unite: 'L',
      valeur: ''
    },
    {
      titre: 'Consommation de GPL',
      unite: 'L',
      valeur: ''
    }
  ]);

  const [categories, setCategories] = useState([
    {
      titre: 'Fret aérien',
      input1: { titre: 'Distance supérieur à 3000km', unite: 'tonnes.km', valeur: '' },
      input2: { titre: 'Distance inferieur à 3000km', unite: 'tonnes.km', valeur: '' }
    },
    {
      titre: 'Fret ferroviaire',
      input1: { titre: 'En France', unite: 'tonnes.km', valeur: '' },
      input2: { titre: 'Hors France', unite: 'tonnes.km', valeur: '' }
    },
    {
      titre: 'Fret maritime',
      input1: { titre: 'En porte-conteneurs', unite: 'tonnes.km', valeur: '' },
      input2: { titre: 'En porte-vraquiers', unite: 'tonnes.km', valeur: '' }
    },
    {
      titre: 'Fret fluvial',
      input1: { titre: 'En pousseurs', unite: 'tonnes.km', valeur: '' },
      input2: { titre: 'En automoteurs', unite: 'tonnes.km', valeur: '' }
    }
  ]);

  const handleInputChange = useCallback((index, inputIndex, field, value) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const category = updatedCategories[index];
      if (category) {
        category[`input${inputIndex + 1}`][field] = value;
      }
      return updatedCategories;
    });
  }, []);  

  const handleInputChangeConso = (index, value) => {
    setConso((prevConso) => {
      const updatedConso = [...prevConso];
      updatedConso[index] = {
        ...updatedConso[index],
        valeur: value
      };
      return updatedConso;
    });
  };
  


  const handleConsommationConnueChange = (value) => {
    setConsommationConnue(value);
  };

  const handleDeleteVoyage = (index) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages.splice(index, 1);
      return updatedVoyages;
    });
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Entreprise/Immobilisation'); // Ajout de la navigation après la soumission du formulaire
  };

  const addVoyage = useCallback(() => {
    setVoyages((prevVoyages) => [
      ...prevVoyages,
      {
        typeVoyage: '',
        prenom: '',
        nom: '',
        ajout: { valeur: '10000', unite: 'an' },
        ajoutPerso: '',
      },
    ]);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(formSubmitted);
  };

  const handleChangeVoyage = useCallback((index, field, value) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        voyage[field] = value;
      }
      return updatedVoyages;
    });
  }, []);

  const handleChangeAjout = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        voyage.ajout = values;
      }
      return updatedVoyages;
    });
  }, []);

  const handleChangeAjoutPerso = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        voyage.ajoutPerso = values;
      }
      return updatedVoyages;
    });
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row col-12">
        <CheckboxChoixUnique
          titre="Connaissez-vous votre consommation ?"
          rep1="Oui"
          rep2="Non"
          onChange={(value) => setConsommationConnue(value)}
        />
      </div>
  
      {consommationConnue === "" ? (
        ""
      ) : consommationConnue ? (
        <div>
          <div className="row col-12">
            {conso.map((consoItem, index) => (
              <div key={index} classNamemd-="col-12 col-lg-4 col-md-6 col-12">
                <InputFieldNbUnique
                  titre={consoItem.titre}
                  value={consoItem.valeur}
                  onChange={(value) =>
                    handleInputChangeConso(index, value)
                  }
                  unit={consoItem.unite}
                />
              </div>
            ))}
          </div>
          {categories.map((category, categoryIndex) => (
            <div key={categoryIndex} className="row col-12">
              <div className="SousTitre">
                <div
                  className="h61 vert"
                  style={{ marginTop: "5vh", color: "#024C4A", marginBottom: "3vh" }}
                >
                  {category.titre}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12 col-lg-4">
                  <InputFieldNbUnique
                    titre={category.input1.titre}
                    value={category.input1.valeur}
                    onChange={(value) =>
                      handleInputChange(categoryIndex, 0, 'valeur', value)
                    }
                    unit={category.input1.unite}
                  />
                </div>
                <div className="col-md-6 col-12 col-lg-4">
                  <InputFieldNbUnique
                    titre={category.input2.titre}
                    value={category.input2.valeur}
                    onChange={(value) =>
                      handleInputChange(categoryIndex, 1, 'valeur', value)
                    }
                    unit={category.input2.unite}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            {voyages.map((voyage, index) => (
              <div className="duplique" key={index}>
                <div className="row col-12">
                  <div className="SousTitre">
                    <DeleteOutlinedIcon
                      sx={{ color: "#024C4A" }}
                      className="Icon"
                      onClick={() => handleDeleteVoyage(index)}
                    />
                    <div className="h61">Voyage {index + 1}</div>
                  </div>
                </div>
  
                <div className="row">
                  <div className="col-md-6 col-12">
                    <ListDeroule
                      titre="Mode de fret"
                      text="Aérien"
                      storageKey={`uniqueKey-${index}`}
                      value={voyage.typeVoyage}
                      onChange={(value) =>
                        handleChangeVoyage(index, 'typeVoyage', value)
                      }
                      mots={["Routier", "Ferroviaire", "Maritime" , "Fluvial"]}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <ListDeroule
                      titre="Type de voyage"
                      text="Aller - retour"
                      storageKey={`uniqueKey-${index}`}
                      value={voyage.typeVoyage}
                      onChange={(value) =>
                        handleChangeVoyage(index, 'typeVoyage', value)
                      }
                      mots={["Retour"]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <InputField
                      titre="Départ"
                      value={voyage.prenom}
                      onChange={(value) =>
                        handleChangeVoyage(index, 'prenom', value)
                      }
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      titre="Arrivée"
                      value={voyage.nom}
                      onChange={(value) =>
                        handleChangeVoyage(index, 'nom', value)
                      }
                    />
                  </div>
                </div>
  
                <div className="row">
                  <div className="col-12">
                    <Ajout
                      values={voyage.ajout}
                      setValues={(values) =>
                        handleChangeAjout(index, values)
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
  
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="custom-button"
                onClick={addVoyage}
              >
                Ajouter un vol
              </button>
            </div>
          </div>
        </div>
      )}
  
      <div className="row">
        <div className="col-12">
          <CheckboxLabels
            title="Possédez-vous une flotte de véhicules de transport pour le fret (choix multiples possibles) ?"
            labels={[
              "Flotte de véhicules terrestres",
              "Flotte de véhicules aériens",
              "Flotte de véhicules fluviaux",
              "Flotte de véhicules maritimes",
              "Aucune"
            ]}
          />
        </div>
      </div>
  
      <div className="row">
        <div className="col-12">
          <Bouton titre="Soumettre" />
        </div>
      </div>
  
      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInterEnt
                voyages={voyages}
                numeroEtape={4}
                handleDeleteVoyage={handleDeleteVoyage}
              />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton
                titre="Catégorie suivante"
                onClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
  
}

export default FormFret;
