import React from 'react';
import ImageAvion from "../img/LogoAvion.png";
import './CardDroite.css';
import { colors } from '@mui/material';
import SousTitre from '../SousTitre';

const ChiffreSousChiffre = ({ Titre, Chiffre, SousChiffre, style }) => {
  return (
    <div className="ChiffreSousChiffre" style={style}>
        <SousTitre SousTitre = {Titre} place = {"center"}/>
        <div className='Chiffre'>{Chiffre}</div>
        <div className='SousChiffre'>{SousChiffre}</div>
    </div>
  );
}

export default ChiffreSousChiffre;