import React from 'react';
import './Graphique.css';
import ImageBurger from "../img/burger.png";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import "./burger.css"
const Burger = ({titre}) => {
  // Données pour les éléments du graphique
  
  return (
      <div>
          <div className='ImageBurger row'>
            <img src={ImageBurger} alt="Image"/>
          </div>
          <div className='row'>
            <div className='traiBurger'></div>
            <div className='logoplus'><AddCircleOutlineIcon/></div>
            <div className='titreBurger'>{titre}</div>
          </div>
      </div>
  );
};

export default Burger;