import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import ImageVoiture from "../../../components/img/Al.png";
import StepperLine from '../../../components/StepperLine/index-test'
import LogoNon from "../../../components/img/imageLogoNon.png";
import LogoTravail from "../../../components/img/travail.png";
import Logowkend from "../../../components/img/wkend.png";
import Logottjour from "../../../components/img/ttjour.png";
import Compteur from '../../../components/Compteur/Compteur';
import ContenueBoutonAl from '../../../components/ContenuCard/ContenueBoutonAl';

const FormAl = () => {
  const navigate = useNavigate();
  const [nombrePetitDej, setNombrePetitDej] = useState(0);
  const [cardContenu, setCardContenu] = useState(<ContenueLogo Logo={ImageVoiture} Titre="Alimentation"/>);

  const handleCardClick = () => {
    setCardContenu(<ContenueBoutonAl petiteCards={petiteCards} setPetiteCards={setPetiteCards} TitreP="Alimentation" SousTitreP="Combien de petits déjeuners prenez-vous par semaine ?" lien="/Domicile"/>);
  };

  const handleCardClickPetitDej = (nombre) => {
    console.log(nombre);
    setNombrePetitDej(nombre);
  };

  const [petiteCards, setPetiteCards] = useState([
    { label: "Pas de petit déjeuner", imageName: LogoNon, lien: `/Alimentaire-2?nombrePetitDej=${nombrePetitDej}`, nombre:0 },
    { label: "Seulement en semaine", imageName: LogoTravail, lien: `/Alimentaire-2?nombrePetitDej=${nombrePetitDej}`, nombre:5 },
    { label: "Seulement le week-end", imageName: Logowkend, lien: `/Alimentaire-2?nombrePetitDej=${nombrePetitDej}`, nombre:2 },
    { label: "Tous les jours", imageName: Logottjour, lien: `/Alimentaire-2?nombrePetitDej=${nombrePetitDej}`, nombre:7 },
    { label: "Autre", imageName: "non" }
  ]);

  const ajouterPetiteCard = (label, imageName) => {
    const nouvellePetiteCard = { label, imageName };
    setPetiteCards([...petiteCards, nouvellePetiteCard]);
  };

  const navigateToFormAlimentaire2 = () => {
    navigate(`/Alimentaire-2?nombrePetitDej=${nombrePetitDej}`);
  };

  return (
    <div className='page'>  
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>
      <StepperLine/>

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>

          <div className="row">
            <div className="col-10 offset-1">
              <TitreH2 Titre="Calculez vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10">
              <Card contenu={cardContenu} onClick={handleCardClick} color="#024C4A"/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-8">
              <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
          </div>
      </div>
    </div> 
  );
};

export default FormAl;

