import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';

const AppelAPITrain = ({voyage,kmConnu,km,zoneGeoOui,selectedUnite,setLancer, Lancer, setApiResponse}) => {
  const { token, fetchToken } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    console.log(token)
    if (token && Lancer) { 
       fetchData();
       setLancer(false);
       
     }


  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

   const fetchData = async () => {
     setLoading(true);
    const apiUrlConnu = 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Ptrain_Global';
    const apiUrlInconnu= 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Ptrain_Detail';
    
    let apiUrl = ''; 

    // Déterminer l'URL de l'API en fonction de la valeur de `kmConnu`
    if (kmConnu === 'oui') {
        apiUrl = apiUrlConnu;
      } else if (kmConnu === 'non') {
        apiUrl = apiUrlInconnu;
      }

    const data = (kmConnu === 'oui')
      ? {
      IdBC: "b2c25702-f76c-ee11-8178-000d3a951a7d",
      IdPage: "Ptrain",
      kmConnu: "True",
      km: km,
      ZoneGeo: zoneGeoOui,
      unit: selectedUnite,
    }:  (kmConnu === 'non') ?
    {
        IdBC: "b2c25702-f76c-ee11-8178-000d3a951a7d",
        IdPage: "Ptrain",
        ZoneGeo: 279640000, //A voir avec GoodPlnaet et Apsia le parametre ne sert à rien constante pour l'instant mais à surveiller si ca commence a donner des results différents
        typeVoyage: voyage.typeVoyage,
        depart: voyage.depart.name,
        latitudeDepart: voyage.depart.latitude,
        longitudeDepart: voyage.depart.longitude,
        arrivee: voyage.arrive.name,
        latitudeArrivee: voyage.arrive.latitude,
        longitudeArrivee: voyage.arrive.longitude,
        frequence: voyage.ajout.frequence,
        unit: voyage.ajout.unit
      }:null;
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const resultTrain = await axios.post(apiUrl, data, options);
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(resultTrain.data);
       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    
    } finally {
      setLoading(false);
      setLancer(false);
    }
  };
  return (null);
};

export default AppelAPITrain;
