import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Bouton from '../Bouton';
import SousTitre from '../../SousTitre';
import velo from "../../img/velo.png";
import veloelect from "../../img/veloel.png";
import pied from "../../img/pied.png";
import Trottinette from "../../img/Trottinette.png";

import "./form.css"
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import BilanInter from '../../BilanInter';
import AppelAPITC2 from '../../Api/AppelAPITC2';
import { useFormProgress } from '../../FormProgressContext';


const CardInput = ({}) => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false); // nouvel état pour suivre la soumission du formulaire
  const [displayBilan, setDisplayBilan] = useState(false);
  const { formStatus, setFormStatus } = useFormProgress();

  
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setDisplayBilan(true);
     // indique que le formulaire a été soumis SetDisplay Bilan (true) quand reponse api je repasse à false. le display de bilan inter se fera sur displaybilan
    console.log(formSubmitted);
  }
  

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate("/alimentaire"); // Ajout de la navigation après la soumission du formulaire
  };
  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API
   

  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponse = (response) => {
    setApiResponse(response);
  };


  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);
    setFormStatus({ ...formStatus, tc: 'fini' });
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
    //setApiResponse(null); // Réinitialiser les données avant l'appel API
  };

  const [compteur, setCompteur] = useState({
    velo: '0',
    veloElectrique: '0',
    trotinetteElectrique: '0',
    pied:'0'
  });

  console.log(apiResponse);
  const handleValeurChange = (nom, valeur) => {
    setCompteur({ ...compteur, [nom]: valeur });
  };

  useEffect(() => {
    setFormStatus({
        ...formStatus,
        tc: 'en cours',
    });
   }, []);


 useEffect(() => {
   if (displayBilan) {
     setFormStatus({
       ...formStatus,
       tc: 'fini',
     });
   } else if (formSubmitted) {
     setFormStatus({
       ...formStatus,
       tc: 'en cours',
     });
   }
 }, [formSubmitted]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="Cadre">
        <div className="row">
          <SousTitre SousTitre="Autres modes de transport - mobilité douce" place="center" />
        </div>

        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <CarreLogoInput2 titregras="Vélo" logo={velo} nombre={1} unit="km/an" onChange={(valeur) =>
                    handleValeurChange('velo', valeur)} />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <CarreLogoInput2 titregras="Vélo électrique" logo={veloelect} nombre={1} unit="km/an" onChange={(valeur) =>
                    handleValeurChange('veloElectrique', valeur)}/>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <CarreLogoInput2 titregras="A pied" logo={pied} nombre={1} unit="km/an" onChange={(valeur) =>
                    handleValeurChange('pied', valeur)} />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <CarreLogoInput2 titregras="Trottinette electrique" logo={Trottinette} nombre={1} unit="km/an" onChange={(valeur) =>
                    handleValeurChange('trotinetteElectrique', valeur)}/>
          </div>
        </div>
      </div>

      <div className="row" style={{ marginTop: "7vh" }}>
        <div className="col-12">
          <Bouton titre="CALCULER" onClick={handleButtonClickAPI}/>
        
          <AppelAPITC2 compteur={compteur} setLancer={setLancer} Lancer={lancer} setApiResponse={updateApiResponse}/>
        </div>
      </div>

      {displayBilan && (
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInter numeroEtape={4} voyage="non" responseAPITC={apiResponse} />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default CardInput;
