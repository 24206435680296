import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios'; // Importez la bibliothèque axios
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import ListDeroule from '../ListDeroule';
import './form.css';
import Ajout from '../Ajout';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useFormProgress } from '../../FormProgressContext';
import Alert from '@mui/material/Alert';
import EnvoyerRequeteTest from './Requetes/RequeteVoyageTest';
import BilanInterRequete from '../../BilanInter/indexRequete';
import AjoutPerso from '../Ajout/index-personne';



function Voyages({ formulaireId, formSubmitted, onRequeteTerminee, NbPerso = false }) {

  const [resultatsRequetes, setResultatsRequetes] = useState([]);

  const handleRequeteTerminee = (resultat, voyageIndex) => {
    const updatedVoyages = [...voyages]; // Copie des voyages
    updatedVoyages[voyageIndex].resultatConso = resultat;

    // Appel de la fonction du composant parent pour mettre à jour les résultats de requête et voyages
    onRequeteTerminee(resultat, voyageIndex, updatedVoyages);

    setResultatsRequetes((prevResultats) => {
      const updatedResultats = [...prevResultats];
      updatedResultats[voyageIndex] = resultat;
      return updatedResultats;
    });

    setVoyages(updatedVoyages);
  };

  
  
  const [voyages, setVoyages] = useState(() => {
    // Récupérer les voyages spécifiques à ce formulaire depuis le localStorage
    const storedVoyages = JSON.parse(localStorage.getItem('mesVoyages') || '{}');
    return storedVoyages[formulaireId] || [
      {
        typeVoyage: '',
        depart: {
          name: '',
          latitude: null,
          longitude: null
        },
        arrive: {
          name: '',
          latitude: null,
          longitude: null
        },
        ajout: { frequence: '7', unit: 'an' },
        ajoutPerso: '3',
        emptyFieldsMessage: null,
        resultatConso : '',
      },
    ];
  });


  // ---------- Ajout d'un nouveau voyage ----------
  const addVoyage = useCallback(() => {
    if (voyages.length < 10) {
      setVoyages((prevVoyages) => [
        ...prevVoyages,
        {
          typeVoyage: '',
          depart: {
            name: '',
            latitude: null,
            longitude: null
          },
          arrive: {
            name: '',
            latitude: null,
            longitude: null
          },
          ajout: { frequence: '7', unit: 'an' },
          ajoutPerso: '3',
          emptyFieldsMessage: null,
          resultatConso : '',
        },
      ]);
  
      // Mise à jour du localStorage avec les nouveaux voyages
       const storedVoyages = JSON.parse(localStorage.getItem('mesVoyages') || '{}');
       storedVoyages[formulaireId] = [
         ...storedVoyages[formulaireId] || [],
         {
           typeVoyage: '',
           depart: {
             name: '',
             latitude: null,
             longitude: null
           },
           arrive: {
             name: '',
             latitude: null,
             longitude: null
           },
           ajout: { frequence: '7', unit: 'an' },
           ajoutPerso: '3',
           emptyFieldsMessage: null,
           resultatConso : '',
         },
       ];
       localStorage.setItem('mesVoyages', JSON.stringify(storedVoyages));
    }
  }, [formulaireId, voyages]);
  

  // ---------- Gestion du changement des informations d'un voyage ----------

  const handleChangeVoyage = useCallback((index, field, value) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        if (field === 'depart' || field === 'arrive') {
          voyage[field] = { ...voyage[field], ...value };
        } else {
          voyage[field] = value;
        }
      }

      // Mise à jour du localStorage avec les voyages mis à jour
      const storedVoyages = JSON.parse(localStorage.getItem('mesVoyages') || '{}');
      storedVoyages[formulaireId] = updatedVoyages;
      localStorage.setItem('mesVoyages', JSON.stringify(storedVoyages));

      return updatedVoyages;
    });

    // Reset the result when a field changes
    setResultatsRequetes((prevResultats) => {
      const updatedResultats = [...prevResultats];
      updatedResultats[index] = ''; // Reset the result
      return updatedResultats;
    });
  }, [formulaireId]);
  


  // ---------- Gestion du changement des informations d'ajout d'un voyage ----------

  const handleChangeAjout = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        voyage.ajout = values;
      }

      // Mise à jour du localStorage avec les voyages mis à jour
      const storedVoyages = JSON.parse(localStorage.getItem('mesVoyages') || '{}');
      storedVoyages[formulaireId] = updatedVoyages;
      localStorage.setItem('mesVoyages', JSON.stringify(storedVoyages));

      return updatedVoyages;
    });
  }, [formulaireId]);

  // ---------- Gestion de la suppression d'un voyage ----------
  const handleDeleteVoyage = (index) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages.splice(index, 1);

      // Mise à jour du localStorage avec les voyages mis à jour
      const storedVoyages = JSON.parse(localStorage.getItem('mesVoyages') || '{}');
      storedVoyages[formulaireId] = updatedVoyages;
      localStorage.setItem('mesVoyages', JSON.stringify(storedVoyages));

      return updatedVoyages;
    });
  };

    // Fonction pour gérer le changement des valeurs d'ajout personnel d'un voyage de la liste
    const handleChangeAjoutPerso = useCallback((index, values) => {
      setVoyages((prevVoyages) => {
        const updatedVoyages = [...prevVoyages];
        const voyage = updatedVoyages[index];
        if (voyage) {
          voyage.ajoutPerso = values;
        }

        // Mise à jour du localStorage avec les voyages mis à jour
        const storedVoyages = JSON.parse(localStorage.getItem('mesVoyages') || '{}');
        storedVoyages[formulaireId] = updatedVoyages;
        localStorage.setItem('mesVoyages', JSON.stringify(storedVoyages));
        return updatedVoyages;
      });
    }, [formulaireId]);
 

  // ---------- Rendu du composant ----------
  return (
    <div style={{marginTop : "5vh"}}>
      {voyages.map((voyage, index) => (
        <div className="duplique" key={index}>
          <div className="row col-12">
            <div className="SousTitre">
              <DeleteOutlinedIcon
                sx={{ color: "#024C4A" }}
                className="Icon"
                onClick={() => handleDeleteVoyage(index)}
              />
              <div className="h61">Voyage {index + 1}</div>
            </div>
          </div>
            
          {voyage.arrive.name === '' ? '' : voyage.arrive.name === voyage.depart.name ? (<div className='row col-12' style={{marginTop : "2vh", marginBottom : "3vh"}}><Alert severity="error">Les noms de la ville de départ et d'arrivée ne peuvent pas être identiques.</Alert></div>) : ("")}
       

          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <ListDeroule
                titre="Type de voyage"
                value={voyage.typeVoyage}
                onChange={(value) => handleChangeVoyage(index, 'typeVoyage', value)}
                text="Aller - retour"
                mots={['Aller simple']}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <InputField
              titre="Départ"
              erreur = {voyage.depart.name === "erreur" ? true : ""}
              value={voyage.depart.name === "erreur" ? "" : voyage.depart.name} // Use the name from the depart object
              onChange={(value) => handleChangeVoyage(index, 'depart', value)}
            />
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <InputField
              titre="Arrivée"
              erreur = {voyage.arrive.name === "erreur" ? true : ""}
              value={voyage.arrive.name === "erreur" ? "" : voyage.arrive.name} // Use the name from the depart object
              onChange={(value) => handleChangeVoyage(index, 'arrive', value)}
            />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Ajout
                values={voyage.ajout}
                setValues={(values) => handleChangeAjout(index, values)}
              />
            </div>
          </div>

          { NbPerso ? (
          <div className="col-12" style={{marginTop : "-5vh"}}>
              <AjoutPerso
                values={voyage.ajoutPerso}
                setValues={(values) =>
                  handleChangeAjoutPerso(index, values)
                }
              />
          </div>
          ) : ("")
          }

        </div>
      ))}

      <div className="row">
        <div className="col-12">
          {voyages.length < 10 && (
            <button type="button" className="custom-button" onClick={addVoyage}>
              Ajouter un voyage
            </button>
          )}
        </div>
      </div>

      {formSubmitted && voyages.map((voyage, index) => (
        <div className="duplique" key={index}>
          <EnvoyerRequeteTest
            voyage={voyage}
            IdPage={formulaireId}
            //Url={'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_TestRequeteSite'}
            Url ={'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Pavion_Detail'}
            onRequeteTerminee={handleRequeteTerminee}
            voyageIndex={index} // Pass the voyage index here
          />
        </div>
      ))}


    </div>
  );
}

export default Voyages;
