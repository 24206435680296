import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import CheckboxChoixUnique from '../checkBox/checkbox';
import Compteur from '../../Compteur/Compteur';
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import LogoPapier from "../../img/papier1.png";
import LogoBureautique from "../../img/Bureautique.png";
import LogoFourniture from "../../img/Fourniture.png";
import LogoHberge from "../../img/heberge.png";
import LogoVisioconf from "../../img/visioconf.png";
import LogoReseau from "../../img/reseau.png";
import LogoVisite from "../../img/Visite.png";
import AjoutNume from '../Ajout/indexNumerique';
import BilanInterEnt from '../../BilanInter/indexEntreprise';




function FormBien({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState(false);
  const [mail, setMail] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(formSubmitted);
  }

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Entreprise/Bilan'); // Ajout de la navigation après la soumission du formulaire
  };

  const handleConsommationConnueChange = (value) => {
    setConsommationConnue(value);
  };

  const [batiments, setBatiments] = useState([
    {
      Surface: '',
      Type: '',
      Structure: ''
    },
  ]);

  useEffect(() => {
    localStorage.setItem('batiments', JSON.stringify(batiments));
  }, [batiments]);

  const addBatiment = useCallback(() => {
    setBatiments((prevBatiments) => [
      ...prevBatiments,
      {
        Surface: '',
        Type: '',
        Structure: ''
      },
    ]);
  }, []);

  const handleChangeBatiment = useCallback((index, field, value) => {
    setBatiments((prevBatiments) => {
      const updatedBatiments = [...prevBatiments];
      const batiment = updatedBatiments[index];
      if (batiment) {
        batiment[field] = value;
      }
      return updatedBatiments;
    });
  }, []);

  const handleDeleteBatiment = (index) => {
    setBatiments((prevBatiments) => {
      const updatedBatiments = [...prevBatiments];
      updatedBatiments.splice(index, 1);
      return updatedBatiments;
    });
  };

  const handleChangeMail = useCallback((values) => {
    setMail(values)
  });

  return (
    <form onSubmit={handleSubmit}>
  
      <div className='h61 vert'>Dépenses liées à des consommables</div>
      <div className="row col-12">
        <Compteur titre={"Total des dépenses liés aux services ( assurances, banques, téléphonie, ménage, honoraires, ...) "} unit='€'/>
      </div>
      <div className='p1' style={{marginTop : "5vh"}}>Indiquer vos consommations annuelles en euro ou en unités d’achat pour vos consommables</div>
      
      <div className="row col-12">
        <div className="col-lg-3 col-6 col-12"><CarreLogoInput2 logo={LogoPapier} titregras="Papiers" titre="Tous les formats de papiers : Papier ( brochures, livres, carton, etc.)" nombre={2} unit="€" unit1="Ramettes A4" /></div>
        <div className="col-lg-3 col-6 col-12"><CarreLogoInput2 logo={LogoFourniture} titregras="Petites fournitures" titre="Post-its, scotchs, cartouches, stylos, surligneurs, ciseaux, etc ..." nombre={1} unit="€" /></div>
        <div className="col-lg-3 col-6 col-12"><CarreLogoInput2 logo={LogoBureautique} titregras="Informatique et bureautique" titre="Cartouches d’encre, câbles, souris, claviers, casques, clés USB, etc..." nombre={1} unit="€" /></div>
      </div>
  
      <div className="row">
        <div className="col-12" style={{ marginTop: "3vh" }}>
          <Bouton titre="Calculer" onClick={handleButtonClick} />
        </div>
      </div>
  
      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInterEnt voyages={[]} numeroEtape={6} />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton
                titre="Catégorie suivante"
                onClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
  
}

export default FormBien;

