import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import CarreLogoCompteur from '../../../components/CarreLogo/CarreLogoCompteur';
import LogoCereale from "../../../components/img/cereale.png";
import LogoCont from "../../../components/img/continental.png";
import LogoSale from "../../../components/img/sale.png";
import LogoVege from "../../../components/img/vege.png";
import LogoVegetalien from "../../../components/img/vegetalien.png";
import LogoPoisson from "../../../components/img/poisson.png";
import LogoViande from "../../../components/img/viande.png";
import LogoVolail from "../../../components/img/volaille.png";
import LogoThe from "../../../components/img/the.png";
import LogoCafe from "../../../components/img/cafe.png";
import LogoBiere from "../../../components/img/biere.png";
import LogoJus from "../../../components/img/jus.png";
import LogoSodas from "../../../components/img/soda.png";


import SousTitre from '../../../components/SousTitre';
import TitreH2 from '../../../components/TitreH2';
import Compteur from '../../../components/Compteur/Compteur';
import FormAlimentaire2 from '../../../components/Form/Form Particulier/FormAlimentaire';
import AppelAPIAlimentaire from '../../../components/Api/AppelAPIAlimentaire';


const FormAl2 = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nombrePetitDej = searchParams.get('nombrePetitDej');
  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API
   

  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponse = (response) => {
    setApiResponse(response);
  };


  const handleButtonClick = () => {
    setLancer(true);
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
    // setResponseData(null); // Réinitialiser les données avant l'appel API
  };

  return (
    <>
    <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>
      <Stepperline/>

        <div className="container">
            <div className="row">
            <div className="row col-10 offset-1">
                <TitreH2 Titre="Alimentation" place="start" />
              </div>
            </div>
            

            <div className="row col-10 offset-1">
            <FormAlimentaire2 nombrePetitDej={nombrePetitDej}/>
            </div>
            
          

            <div className="row">
              <div className="col-12">
              <Ensavoir contenu={<div>
            <div className='h41'>Émissions liées à l'alimentation</div>
              <p>Les émissions liées à notre alimentation dépendent du type de repas consommé. En effet, l’élevage est un secteur particulièrement émetteur de gaz à effet de serre et le bœuf et l’agneau sont les viandes les plus émettrices. Afin de pouvoir évaluer les émissions liées à l’alimentation, l’ADEME a calculé les facteurs d’émissions de différents repas à partir de menus types, respectant les apports nutritionnels nécessaires pour un adulte (entrée, plat, dessert). À savoir : végétarien, classique (poulet), classique (bœuf), à dominante végétale (poulet), à dominante végétale (bœuf). Vous trouverez les menus types ainsi que plus d’informations pour les hypothèses choisies sur la <a href="https://bilans-ges.ademe.fr/fr/accueil/documentation-gene/index/page/Repas" target="_blank" rel="noopener noreferrer">documentation de l’ADEME</a>.</p>
    
              <p>Les repas type poisson et végétalien ne figurent pas dans la base carbone. Nous avons calculé le facteur d’émission d’un repas à base de poisson à partir d’un menu classique en remplaçant le plat de viande par un plat de poisson (à partir du facteur d’émission « Plats à base de poissons : moyenne ‘pavé de saumon grillé’, ‘brandade de morue’ »). De même, nous avons calculé le facteur d’émission d’un repas végétalien à partir du menu végétarien en remplaçant les œufs par un substitut à base de soja ou d’autres végétaux (à partir du facteur d’émission « Substituts de produits animaux à base de soja et autres végétaux »).</p>
    
             <p>Le facteur d’émission du petit déjeuner est calculé à partir des différents types de petit déjeuner proposés. Il prend en compte les aliments présents dans le menu ainsi qu’un grammage moyen pour un adulte.</p>

             <div className='h41'>Évaluer vos repas :</div>
           <ul>
              <li>Repas végétalien : un repas sans viande ni produits de la mer ni produits d’origine animale (œufs, beurre, crème, yaourt …)</li>
              <li>Repas végétarien : sans viande ni produits de la mer</li>
              <li>Repas à dominante végétale avec viande blanche : moins d’1/3 du plat constitué de viande blanche (poulet, porc …)</li>
              <li>Repas à dominante végétale avec viande rouge : moins d’1/3 du plat constitué de viande rouge (bœuf, veau, agneau …)</li>
              <li>Repas classique viande blanche : plus de la moitié du plat constitué de viande blanche (poulet, porc …)</li>
              <li>Repas classique avec viande rouge : plus de la moitié du plat constitué de viande rouge (bœuf, veau, agneau …)</li>
              <li>Repas classique avec produits de la mer : plus de la moitié du plat est constitué de produits de la mer (poissons, coquillages, crustacés …)</li>
            </ul>
            </div>} />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAl2;
