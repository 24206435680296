import React, { useState } from 'react';
import "./checkbox.css"
import SousTitre from '../../SousTitre';
import InputFieldNb from '../InputNombre/index';
import ListDeroule from '../ListDeroule/index';

const CheckboxChoixUnique3 = ({ titre, rep1, rep2, rep3, setReturn}) => {
  const [choix, setChoix] = useState(null);

  const handleCheckboxChange = (event) => {
    const value =event.target.value
    setChoix(value);
    let convertedValue;
          switch (value) {
            case "France":
              convertedValue = 279640000; // Valeurs venant de l'Excel contrat interface d'apsia
              break;
            case "FP":
              convertedValue = 279640001; // France <-> Pays transfrontaliers
              break;
            case "FT":
              convertedValue = 279640002; // En Europe (hors France)
              break;
            default:
              convertedValue = 279640000;
          }
          setReturn(convertedValue);

  };

  return (
    <div className="checkbox">
      <SousTitre SousTitre={titre} place="start" />

      <label>
        <input
          type="checkbox"
          name="choix"
          value="France"
          checked={choix === "France"}
          onChange={handleCheckboxChange}
        />
        <span className="checkmark"></span>
        {rep1}
      </label>

      <label>
        <input
          type="checkbox"
          name="choix"
          value="FP"
          checked={choix === "FP"}
          onChange={handleCheckboxChange}
        />
        <span className="checkmark"></span>
        {rep2}
      </label>

      <label>
        <input
          type="checkbox"
          name="choix"
          value="FT"
          checked={choix === "FT"}
          onChange={handleCheckboxChange}
        />
        <span className="checkmark"></span>
        {rep3}
      </label>



      


    </div>
  );
}

export default CheckboxChoixUnique3;
