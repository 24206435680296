import React from 'react';
import './font-family.css';
import './font-google-api.css';

const Police = () => {
  return (
    <div className="container">
      <h1 className="h2 back">h2 back</h1>
      <h2 className="h11">h11 Montserrat - Regular</h2>
      <h2 className="h21">h21 Montserrat - Semi-bold</h2>
      <h2 className="h31">h31 Montserrat - Bold</h2>
      <h2 className="h3 jeremy">h3 jeremy Montserrat - Light</h2>
      <h2 className="h41">h41 Montserrat - Semi-bold</h2>
      <h2 className="h61">h61 Montserrat - Bold</h2>
      <p className="p1">p1 Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      <div className="button1">Bouton : Click Me</div>
      <p className="caption1">caption1 This is a caption</p>
    </div>
  );
};

export default Police;


