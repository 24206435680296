import React, { useEffect, useState } from 'react';
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/Index";
import Graphique from "../../components/Bilan/index"
import CardPetiteDroite from '../../components/CardPetiteDroite';
import SousTitre from '../../components/SousTitre';
import ChiffreSousChiffre from '../../components/CardPetiteDroite/Chiffre';
import ListDeroule from '../../components/Form/ListDeroule';
import TitreH2 from '../../components/TitreH2/index';
import TitreBG from '../../components/TitreBG/index';
import image1 from "../../components/img/planet.png";
import image2 from "../../components/img/Trainbilan.png"
import image3 from "../../components/img/Livre.png"
import Ligne1 from '../../components/Bilan Entreprise/Ligne1';
import Ligne2 from '../../components/Bilan/Ligne2';
import Bouton from '../../components/Form/Bouton';
import CardBilan from '../../components/Bilan Entreprise/CardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import Band1 from '../../components/Bilan Entreprise/Band1';
import Band2 from '../../components/Bilan/Band2';
import { useNavigate } from 'react-router-dom';
import Popup from '../../components/Popup';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TrainIcon from '@mui/icons-material/Train';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HouseIcon from '@mui/icons-material/House';
import RecyclingIcon from '@mui/icons-material/Recycling';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import ChartEntreprise from '../../components/Bilan Entreprise';
import { Linking } from 'react';
import CreeImage from '../../components/CreeImage';


const BilanEntreprise = () => {
  const navigate = useNavigate();
  
  const handleListDerouleChange = (selectedValue) => {
    // Logique de gestion du changement de valeur du ListDeroule
    console.log("ListDeroule value:", selectedValue);
  };

  const imageURL = "https://www.referenseo.com/wp-content/uploads/2019/03/image-attractive.jpg";  // Remplacer avec l'URL de l'image
  const shareURL = `https://www.instagram.com/accounts/login/?next=%2Fp%2F${imageURL}%2F&source=share-link`;

  const shareToInstagram = () => {
    window.open(shareURL, '_blank');
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/bilan-2');
  };

  const [isPopupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    setPopupOpen(true);
  }, []);

  const handlePopupClose = () => {
    setPopupOpen(false);
  };


  return (
    <div>
      <div style={{zIndex : "999"}}>
      <Popup isOpen={isPopupOpen} onClose={handlePopupClose}>
        {/* <div className="col-10">
            <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
        </div>
        <div className="row col-6" style={{marginTop : "3vh"}}>
                <CardPetiteDroite
                  color={{ backgroundColor: "black", height : "180px" }}
                  taille={{ height : "180px"}}                  
                  contenu={
                    <div>
                    <ChiffreSousChiffre
                      Titre={"Vos émissions"}
                      Chiffre={"6,57"}
                      SousChiffre={"Tonnes de C02"}
                      style={{
                        height: "65%",   
                        marginTop: "5vh",
                      }}
                    />
                    {/* <div className='row col-6 align-center offset-3' style={{marginTop : "2vh"}}>
                    <div className='col-3'><FlightIcon sx={{ color: '#EBE085' }}/></div>
                    <div className='col-3'><DirectionsCarIcon sx={{ color: '#68C4A5' }}/></div>
                    <div className='col-3'><TrainIcon sx={{ color: '#FBAB3B' }}/></div>
                    <div className='col-3'><PedalBikeIcon sx={{ color: '#024C4A' }}/></div>
                    </div>
                    <div className='row justify-content-center col-6 offset-3' style={{marginTop : "1vh", marginBottom : "2vh"}}>
                    <div className='col-3'><RestaurantIcon sx={{ color: '#313179' }}/></div>
                    <div className='col-3'><HouseIcon sx={{ color: '#C14118' }}/></div>
                    <div className='col-3'><RecyclingIcon sx={{ color: '#8C8C8C' }}/></div>
                    </div> */}
                  {/*  </div>
                  
                 />
              </div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '100%',
        }}>  */}

          <div className="row">

          <div className="col-12">
              <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
          </div>

          </div>

          <div className="row">

          <div className="col-8">
              <h1 className = "h31">Répartition de vos émissions</h1>
          <div style={{width : "50%"}}>
            <ChartEntreprise />
          </div>
          </div>



          <div className="col-3">
            <div className="row col-8 offset-1" style={{marginTop : "3vh"}}>
              <CardPetiteDroite
                color={{ backgroundColor: "black", height : "150px" }}
                taille={{ height : "150px"}}                  contenu={
                  <ChiffreSousChiffre
                    Titre={"Vos émissions"}
                    Chiffre={"6,57"}
                    SousChiffre={"Tonnes de C02"}
                    style={{
                      height: "65%",   
                      marginTop: "0px"
                    }}
                  />
                }
              />
            </div>


          </div>
          
          <div className='container row col-12 align-center' style={{marginTop : "5vh"}}>
          <div className='col-6 justify-content-start'>
            <Bouton titre="Créez un compte et comparez vos futurs calculs d'empreinte"/>
          </div>
          <div className='col-5'>
            <Bouton titre="Recevoir le détail en pdf"/>
          </div>
          </div>

        </div>
      </Popup>
      </div>
      <TitreBG Titre="Bilan de vos émissions annuelles" className="titre"/>
      <div className="Header">
        <Header />
      </div>

      <div className="conten">
        <div className="container">
        <div className="row">

            <div className="col-10">
                <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            
        </div>

        <div className="row">
            
            <div className="col-9">
                <h1 className = "h31">Répartition de vos émissions</h1>

                <SousTitre SousTitre="Mes émissions" />

                <p className = "p1">A vos émissions annuelles, 1,1 tCO2 s’ajoute liées au fonctionnement de nos services publics. </p>
            <div>
              <ChartEntreprise />
            </div>
            </div>



            <div className="col-3">
              <div className="row col-8 offset-1" style={{marginTop : "3vh"}}>
                <CardPetiteDroite
                  color={{ backgroundColor: "black", height : "150px" }}
                  taille={{ height : "150px"}}                  contenu={
                    <ChiffreSousChiffre
                      Titre={"Vos émissions"}
                      Chiffre={"6,57"}
                      SousChiffre={"Tonnes de C02"}
                      style={{
                        height: "65%",   
                        marginTop: "30px"
                      }}
                    />
                  }
                />
              </div>
              <div className="row offset-1" style={{marginTop : "5vh"}}>
                <ListDeroule
                  titre="Se comparer à"
                  value="National"
                  onChange={handleListDerouleChange}
                  text="France"
                  storageKey="listDerouleValue"
                  mots = {["Rapport 1", "Mon rapport", "rapport-20062023"]}
                />
              </div>
              <div className="row col-6 offset-1"  style={{marginTop : "5vh"}}>
              <CardPetiteDroite
                  color={{ backgroundColor: "black", height : "120px" }}
                  taille={{ height : "120px"}}
                  contenu={
                    <ChiffreSousChiffre
                      Chiffre={"9,9"}
                      SousChiffre={"Tonnes de C02"}                  
                      style={{
                        height: "80%",   
                        marginTop: "10%"
                      }}
                    />
                  }
                />
              </div>
              <div 
              className="row offset-1"
              style={{
                marginTop : "3vh",
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "800",
                fontSize: "10px",
                lineHeight: "12px",
                fontVariant: "all-small-caps"
                }}  
              >

              <Bouton titre = "MON BILAN FINAL"  onClick={handleButtonClick}/>
                    Source: UUU
              </div>
            </div>

          </div>

          <div className="row" style={{marginTop : "15vh", backgroundColor: "#F5F5F5"}}>
              <div className="col-4">
              <Ligne1 titre = {"Emissions totales des gaz à effet de serre : "} titregras={"7 tonnes"}/>
              </div>
              <div className="col-4">
              <Ligne1 logo = {image2} titre = {"Emissions liées aux scopes 1 & 2 : "} titregras={"40 %"}/>
              </div>
              <div className="col-4">
              <Ligne1 logo = {image3} titre = {"Emissions liées au scope 3 : "} titregras={"60 %"}/>
              </div>
          </div>

          <div className="row" style={{marginTop : "10vh"}}>
              <div className="col-4">
              <Ligne2 text = {"L’empreinte de l’avion  est très important. Cherchez des solutions alternatives."}/>
              </div>
              <div className="col-4">
              <Ligne2 text = {"Le tri des déchets influe également sur notre émission carbone. Quelles sont les bonnes pratiques ?"}/>
              </div>
              <div className="col-4">
              <Ligne2 text = {"La production d’1 kg de viande a une émission carbone 5 à 10 fois supérieure à celle d’1 kg de céréale."}/>
              </div>
          </div>
          <div className="row" style={{marginTop : "5vh"}}>
              <div className="col-4">
              <Ligne2 text = {"L’empreinte de l’avion  est très important. Cherchez des solutions alternatives."}/>
              </div>
              <div className="col-4">
              <Ligne2 text = {"Le tri des déchets influe également sur notre émission carbone. Quelles sont les bonnes pratiques ?"}/>
              </div>
              <div className="col-4">
              <Ligne2 text = {"La production d’1 kg de viande a une émission carbone 5 à 10 fois supérieure à celle d’1 kg de céréale."}/>
              </div>
          </div>

          <div className="row justify-content-center" style={{marginTop : "5vh"}}>
              <div className="col-4">
              <Bouton titre="Réduire mes émissions" onClick={handleButtonClick}/>
              </div>
              <div className="col-4">
              <Bouton titre = {"VOIR LE Bilan détaillé"} onClick={shareToInstagram}/>
              </div>
          </div>

          <CreeImage/>

          <div className="row justify-content-center" style={{marginTop : "5vh"}}>
              <div className="col-12">
              <h1 className = "h31">L’objectif fixé dans le cadre de l’accord de Paris est limiter le réchauffement climatique en deçà de 2°C </h1>
              </div>
              <div className="col-12">
                <p className='p1'>
                L'accord de Paris est un traité international juridiquement contraignant sur les changements climatiques. Il a été adopté par 196 Parties lors de la COP 21 à Paris, le 12 décembre 2015 et est entré en vigueur le 4 novembre 2016.
                Son objectif est de limiter le réchauffement climatique à un niveau bien inférieur à 2, de préférence à 1,5 degré Celsius, par rapport au niveau préindustriel.
                Cet objectif peut être atteint en travaillant main dans la main avec l’Etat, les individus et les entreprises pour compenser les emissions faites et réduire les emissions. On parle ainsi d’un objectif de 2 tonnes de CO2e par français et par an pour atteindre cet objectif. 
                </p>
              </div>
          </div>

          <div className='row col-12'>
              <CardBilan/>
          </div>

          <div className='row col-12' style={{marginTop : "5vh"}}>
              <Band1/>
          </div>

          <div className='row col-12' style={{marginTop : "5vh"}}>
              <Band2/>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BilanEntreprise;

