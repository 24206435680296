import React, { useState } from 'react';
import Header from "../../../components/Header/index";
import Footer from '../../../components/Footer/Index';
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import FormCreeCompte from '../../../components/Form/Form Connection/CreeCompte';
import FormCreeCompte2 from '../../../components/Form/Form Connection/CreeCompte2';

const CreeCompte2 = () => {

  return (
    <>   
    <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container'>

        <div className='TitreBG'>
        <TitreBG Titre="Se créer un compte" className="titre"/>
        </div>
        <div className="row">
            <div className="col-10 offset-1">
                <TitreH2 Titre="Se créer un compte" className="titre" couleur="#000000"/>
            </div>
          </div>

          <FormCreeCompte2/>

          <div className="row justify-content-center">
            <div className="col-8">
              <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreeCompte2;

