import React, { useState }  from 'react';
import { useNavigate } from 'react-router-dom';
import './PetiteCard.css';
import Compteur from '../Compteur/Compteur';
import Bouton from '../Form/Bouton';

const PetiteCardAl = ({ label, imageName, lien, nombre }) => {
  const navigate = useNavigate();
  const [nombreCompteur, setNombreCompteur] = useState(1);

  const handleButtonClick = (event) => {
    event.preventDefault();
    if (imageName === 'non') {
      navigate(`/Alimentaire-2?nombrePetitDej=${nombreCompteur}`);
    } else {
      navigate(`/Alimentaire-2?nombrePetitDej=${nombre}`);
    }
  };
  

  const handleValeurChange = (value) => {
    setNombreCompteur(value);
  };

  return (
    <button className="small-card" onClick={imageName === 'non' ? null : handleButtonClick}>
      <div className="rectangle">
        <div className="label">{label}</div>
        <div className="imageDiv">
          <div className="image">
            {imageName === 'non' ? (
              <div>
                <Compteur                 
                  titre=""
                  unit=""
                  onValeurChange={handleValeurChange}
                  val={nombreCompteur}
                /> 
                <button type="button" className="custom-button" onClick={handleButtonClick} style={{marginBottom : "10px" , marginTop : "12px"}}>
                  Valider
                </button>
              </div>
            ) : (
              <img src={imageName} alt="Image" />
            )}
          </div>
        </div>
      </div>
    </button>
  );
}  

export default PetiteCardAl;




