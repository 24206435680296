import React from "react";
import "./TitreBG.css";

function TitreBG(props) {
  const { Titre, Style} = props;

  return (
    <div className='BG'>
    <h1 className="h2 back" Style = {Style}>{ Titre }</h1>
    </div>
  );
};

export default TitreBG;

