import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import './form.css';
import Ajout from '../Ajout';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Compteur from '../../Compteur/Compteur';
import CheckboxChoixUnique from '../checkBox/checkbox';

function Hebergement({}) {

  const [voyages, setVoyages] = useState([
    {
      NbNuit: '',
      CategorieHebergement: '',
      Labeliser: '',
    },
  ]);

  useEffect(() => {
    // Sauvegarde des voyages dans le localStorage lorsqu'ils sont modifiés
    localStorage.setItem('voyages', JSON.stringify(voyages));
  }, [voyages]);

  const addVoyage = useCallback(() => {
    // Ajout d'un nouveau voyage à la liste des voyages
    if (voyages.length < 10) {
      setVoyages((prevVoyages) => [
        ...prevVoyages,
        {
          NbNuit: '',
          CategorieHebergement: '',
          Labeliser: '',
        },
      ]);
    }
  }, [voyages]);

  const handleChangeVoyage = useCallback((index, field, value) => {
    // Mise à jour d'une valeur spécifique d'un voyage dans la liste des voyages
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        voyage[field] = value;
      }
      return updatedVoyages;
    });
  }, []);



  const handleDeleteVoyage = (index) => {
    // Suppression d'un voyage de la liste des voyages
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages.splice(index, 1);
      return updatedVoyages;
    });
  };

  return (
    <div>
      
      {voyages.map((voyage, index) => (
        <div className="duplique" key={index}>
          <div className="row col-12">
            <div className="SousTitre">
              <DeleteOutlinedIcon
                sx={{ color: "#024C4A" }}
                className="Icon"
                onClick={() => handleDeleteVoyage(index)}
              />
              <div className="h61">Hébergement {index + 1}</div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Compteur
                titre="Nombre de nuitées réservées"
                unit='nuits'
                onValeurChange={(value) => handleChangeVoyage(index, 'NbNuit', value)}
              />
            </div>

            <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <ListDeroule
                titre="Catégorie de l’hébergement"
                value="Hotel 1 étoile"
                onChange={(value) => handleChangeVoyage(index, 'CategorieHebergement', value)}
                mots = {["Hotel 2 étoile", "Hotel 3 étoile", "Hotel 4 étoile"]}
              />
            </div>
            </div>
          
          </div>

          <div className="row">
            <div className="col-12">
            <CheckboxChoixUnique
                onChange={(value) => handleChangeVoyage(index, 'Labeliser', value)}
                titre="L’hébergement est-il labellisé (Clef verte, Green globe, Earth Check, Ecolabel, autre...) ?"
                rep1="Oui"
                rep2="Non"
            />
            </div>
        </div>

          
        </div>
      ))}

      <div className="row">
        <div className="col-12">
          {voyages.length < 10 && (
            <button type="button" className="custom-button" onClick={addVoyage}>
              Ajouter un Hébergement
            </button>
          )}
        </div>
      </div>

     
    </div>
  );
}

export default Hebergement;