import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CarreLogo.css';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CarreLogo = ({ logo, titre, titregras }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={`CarreLogo ${isChecked ? 'isChecked' : ''}`} onClick={handleClick}>
      <div className='Check'>
        {isChecked ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
      </div>
      <div className='GrasCarre' style={{marginLeft : "18px"}}>{titregras}</div>
      <div className='LogoCarre'>
        <img src={logo} alt='Image' />
      </div>
      <div className='titreCarre'>{titre}</div>
    </div>
  );
};

export default CarreLogo;





