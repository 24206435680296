import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import CheckboxChoixUnique from '../checkBox/checkbox';
import Compteur from '../../Compteur/Compteur';
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import LogoPapier from "../../img/papier1.png";
import LogoBureautique from "../../img/Bureautique.png";
import LogoFourniture from "../../img/Fourniture.png";
import LogoCarton from "../../img/papier.png";
import LogoBois from "../../img/bois.png";



function FormAchat({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(formSubmitted);
  }

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Voiture-11'); // Ajout de la navigation après la soumission du formulaire
  };

  const handleConsommationConnueChange = (value) => {
    setConsommationConnue(value);
  };

  const [batiments, setBatiments] = useState([
    {
      Surface: '',
      Type: '',
      Structure: ''
    },
  ]);

  useEffect(() => {
    localStorage.setItem('batiments', JSON.stringify(batiments));
  }, [batiments]);

  const addBatiment = useCallback(() => {
    setBatiments((prevBatiments) => [
      ...prevBatiments,
      {
        Surface: '',
        Type: '',
        Structure: ''
      },
    ]);
  }, []);

  const handleChangeBatiment = useCallback((index, field, value) => {
    setBatiments((prevBatiments) => {
      const updatedBatiments = [...prevBatiments];
      const batiment = updatedBatiments[index];
      if (batiment) {
        batiment[field] = value;
      }
      return updatedBatiments;
    });
  }, []);

  const handleDeleteBatiment = (index) => {
    setBatiments((prevBatiments) => {
      const updatedBatiments = [...prevBatiments];
      updatedBatiments.splice(index, 1);
      return updatedBatiments;
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Titre Titre="Achats de biens et services " />
      <Compteur titre="Total des dépenses liées aux services (assurances, banques, téléphonie, ménage, honoraires, ...) :" unit="€" />
      <div className="row col-12">
        <div className="col"><CarreLogoInput2 logo={LogoPapier} titregras="Papiers" titre="Tous les formats de papiers" nombre={2} unit="€" unit1="tonnes" /></div>
        <div className="col"><CarreLogoInput2 logo={LogoCarton} titregras="Carton" titre="Post-its, scotchs, cartouches, stylos, surligneurs, ciseaux, etc ..." nombre={1} unit="€" /></div>
        <div className="col"><CarreLogoInput2 logo={LogoCarton} titregras="Plastique" titre="Cartouches d’encre, câbles, souris, claviers, casques, clés USB, etc..." nombre={1} unit="€" /></div>
        <div className="col"><CarreLogoInput2 logo={LogoBureautique} titregras="Métal" titre="Cartouches d’encre, câbles, souris, claviers, casques, clés USB, etc..." nombre={1} unit="€" /></div>
        <div className="col"><CarreLogoInput2 logo={LogoBois} titregras="Bois" titre="Cartouches d’encre, câbles, souris, claviers, casques, clés USB, etc..." nombre={1} unit="€" /></div>
      </div>
  
      <div className="row">
        <div className="col-12" style={{ marginTop: "3vh" }}>
          <Bouton titre="Suivant" onClick={handleButtonClick} />
        </div>
      </div>
  
      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInter voyages={[]} numeroEtape={2} />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
            </div>
          </div>
        </div>
      )}
    </form>
  );
  
}

export default FormAchat;

