import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import ImageVoiture from "../../../components/img/Al.png";
import StepperLine from '../../../components/StepperLine/index-test'
import LogoNon from "../../../components/img/imageLogoNon.png";
import LogoTravail from "../../../components/img/travail.png";
import Logowkend from "../../../components/img/wkend.png";
import Logottjour from "../../../components/img/ttjour.png";
import Compteur from '../../../components/Compteur/Compteur';
import LogoDep from "../../../components/img/LogoDep.png"
import ContenueLogoText from '../../../components/ContenuCard/ContenueLogoText';
import StepperLineEntreprise from '../../../components/StepperLine/index-entreprise';


const Deplacement11 = () => {
  const navigate = useNavigate();
  const [cardContenu, setCardContenu] = useState(<ContenueLogo Logo={LogoDep} tailleLogo={6} Titre="Déplacement terrestre (professionnel)" />);

  const handleCardClick = () => {
    navigate("/Entreprise/Deplacement-2")
  };


  return (
    <>   
     <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>
      <StepperLineEntreprise/>

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">
            <div className="col-10 offset-1">
                <TitreH2 Titre="Calculez vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10">
              <Card contenu={cardContenu} onClick={handleCardClick} color="#024C4A"/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-8">
              <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deplacement11;

