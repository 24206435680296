import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CarreLogo.css';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CarreLogoCompteur = ({ logo, titre, titregras, onChange }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [valeur, setValeur] = useState('0');

  const handleChangeValeur = (event) => {
    const newValue = event.target.value.replace(/\D/g, ''); // Supprimer les caractères non numériques
    setValeur(newValue);
    onChange(newValue); // Appel de la fonction de rappel pour renvoyer 
  };

  const handleClickMinus = (event) => {
    event.preventDefault();
    const newValue = parseInt(valeur) - 1;
    if (!isNaN(newValue)) {
      setValeur(String(newValue));
      onChange(String(newValue)); // Appel de la fonction de rappel pour renvoyer la valeur
    }
  };

  const handleClickPlus = (event) => {
    event.preventDefault();
    const newValue = parseInt(valeur) + 1;
    if (!isNaN(newValue)) {
      setValeur(String(newValue));
      onChange(String(newValue)); // Appel de la fonction de rappel pour renvoyer la valeur
    }
  };

  const handleClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="CarreLogo">

      <div className='GrasCarre'>{titregras}</div>
      <div className='LogoCarre'>
        <img src={logo} alt='Image' />
      </div>
      <div className='titreCarre'>{titre}</div>

      <div className="ajout-input-container">
        <button className="ajout-button" onClick={handleClickMinus}>-</button>
        <input
          type='text'
          className="ajout-input"
          value={valeur}
          onChange={handleChangeValeur}
          pattern="[0-9]*"
        />
        <button className="ajout-button" onClick={handleClickPlus}>+</button>
      </div>
    </div>
  );
};

export default CarreLogoCompteur;





