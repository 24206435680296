import React, { useEffect, useState } from 'react';
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/Index";
import Graphique from "../../components/Bilan/index"
import CardPetiteDroite from '../../components/CardPetiteDroite';
import SousTitre from '../../components/SousTitre';
import ChiffreSousChiffre from '../../components/CardPetiteDroite/Chiffre';
import ListDeroule from '../../components/Form/ListDeroule';
import TitreH2 from '../../components/TitreH2/index';
import TitreBG from '../../components/TitreBG/index';
import image1 from "../../components/img/planet.png";
import image2 from "../../components/img/Trainbilan.png"
import image3 from "../../components/img/Livre.png"
import Ligne1 from '../../components/Bilan/Ligne1';
import Ligne2 from '../../components/Bilan/Ligne2';
import Bouton from '../../components/Form/Bouton';
import CardBilan from '../../components/Bilan/CardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import Band1 from '../../components/Bilan/Band1';
import Band2 from '../../components/Bilan/Band2';
import { useNavigate } from 'react-router-dom';
import Popup from '../../components/Popup';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TrainIcon from '@mui/icons-material/Train';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HouseIcon from '@mui/icons-material/House';
import RecyclingIcon from '@mui/icons-material/Recycling';
import PedalBikeIcon from '@mui/icons-material/PedalBike';

const BilanPart = () => {
  const navigate = useNavigate();
  const handleListDerouleChange = (selectedValue) => {
    // Logique de gestion du changement de valeur du ListDeroule
    console.log("ListDeroule value:", selectedValue);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/bilan-2');
  };

  const handleButtonClickBilanFinal = (event) => {
    event.preventDefault();
    navigate('/bilan-4');
  };

  const [isPopupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    setPopupOpen(true);
  }, []);

  const handlePopupClose = () => {
    setPopupOpen(false);
  };


  return (
    <div className='page'>   
      <div style={{zIndex : "999"}}>
      <Popup isOpen={isPopupOpen} onClose={handlePopupClose}>
        <div className="col-10">
            <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
        </div>
        <div className="row col-6" style={{marginTop : "10vh"}}>
                <CardPetiteDroite
                  className="emission-card"
                  color={{ backgroundColor: "black" }}                
                  contenu={
                    <div >
                    <ChiffreSousChiffre
                      Titre={"Vos émissions"}
                      Chiffre={"6,59"}
                      SousChiffre={"Tonnes de C02"}
                      style={{
                        padding:"1px",
                        height: "65%",   
                        marginTop: "1px",
                      }}
                    />
                    </div>
                  }
                />
              </div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '100%',
        }}>
          <div className='row col-12'>
          <div className='col-lg-6 col-12'>
            <Bouton titre="Créer un compte et comparer les futurs calculs d'empreinte"/> 
          </div>
          <div className='col-lg-6 col-12'>
            <Bouton titre="Recevoir le détail en pdf par mail"/>
          </div>
          </div>
        </div>
      </Popup>
      </div>
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>
        
        <div className='col-10 offset-1'>
        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">

            <div className="col-10">
                <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            
        </div>

        <div className="row">
            
            <div className="col-9">
                <h1 className = "h31">Répartition de vos émissions</h1>

                <SousTitre SousTitre="Mes émissions" />

                <p className = "p1">A vos émissions annuelles, 1,1 tCO2 s’ajoutent liées au fonctionnement de nos services publics. </p>

              <Graphique />
            </div>



            <div className="col-3">
              <div className="row col-12" style={{marginTop : "3vh"}}>
                <CardPetiteDroite
                  color={{ backgroundColor: "black", height : "150px" }}
                  taille={{ height : "150px"}}                  
                  contenu={
                    <ChiffreSousChiffre
                      Titre={"Vos émissions"}
                      Chiffre={"6,57"}
                      SousChiffre={"Tonnes de C02"}
                      style={{
                        height: "80%",   
                        marginTop: "10px",
                        fontSize:"12px",
                      }}
                    />
                  }
                />
              </div>
              <div className="row offset-1" style={{marginTop : "5vh"}}>
                <ListDeroule
                  titre="Se comparer à"
                  value="National"
                  onChange={handleListDerouleChange}
                  text="France"
                  storageKey="listDerouleValue"
                  mots = {["Rapport 1", "Rapport2", "Rapport 3s"]}
                />
              </div>
              <div className="row col-10 offset-1"  style={{marginTop : "5vh"}}>
              <CardPetiteDroite
                  color={{ backgroundColor: "black", height : "100px" }}
                  taille={{ height : "100px"}}
                  contenu={
                    <ChiffreSousChiffre
                      Chiffre={"9,9"}
                      SousChiffre={"Tonnes de C02"}                  
                      style={{
                        height: "80%",   
                        marginTop: "10px"
                      }}
                    />
                  }
                />
              </div>
              <div 
              className="row offset-1"
              style={{
                marginTop : "3vh",
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "800",
                fontSize: "10px",
                lineHeight: "12px",
                fontVariant: "all-small-caps"
                }}  
              >

              <Bouton titre = "MON BILAN FINAL"  onClick={handleButtonClickBilanFinal}/>
                    Source: Carbone 4
              </div>
            </div>

          </div>

          <div className="row" style={{marginTop : "15vh", backgroundColor: "#F5F5F5"}}>
              <div className="col-4">
              <Ligne1 logo = {image1} titre = {"Tonnes de C02"} titregras={"2,5 planète(s)"}/>
              </div>
              <div className="col-4">
              <Ligne1 logo = {image2} titre = {"145 896 Km"} titregras={"1,8 tour(s) du monde en  TGV"}/>
              </div>
              <div className="col-4">
              <Ligne1 logo = {image3} titre = {"769 986 livres"} titregras={"3,1 bibliothèque(s) d’Alexandrie "}/>
              </div>
          </div>

          <div className="row" style={{marginTop : "10vh"}}>
              <div className="col-4">
              <Ligne2 text = {"L’empreinte de l’avion  est très importante. Cherchez des solutions alternatives."}/>
              </div>
              <div className="col-4">
              <Ligne2 text = {"Le tri des déchets influe également sur notre émission carbone. Quelles sont les bonnes pratiques ?"}/>
              </div>
              <div className="col-4">
              <Ligne2 text = {"La production d’1 kg de viande a une émission carbone 5 à 10 fois supérieure à celle d’1 kg de céréale."}/>
              </div>
          </div>

          <div className="row justify-content-center" style={{marginTop : "5vh"}}>
              <div className="col-4">
              <Bouton titre="Réduire mes émissions" onClick={handleButtonClick}/>
              </div>
              <div className="col-4">
              <Bouton titre = {"VOIR LE Bilan détaillé"}/>
              </div>
          </div>

          <div className="row justify-content-center" style={{marginTop : "5vh"}}>
              <div className="col-12">
              <h1 className = "h31">L’objectif fixé dans le cadre de l’accord de Paris est de limiter le réchauffement climatique en deçà de 2°C </h1>
              </div>
              <div className="col-12">
                <p className='p1'>
                L'Accord de Paris est un traité international juridiquement contraignant sur les changements climatiques. Il a été adopté par 196 parties lors de la COP 21 à Paris, le 12 décembre 2015 et est entré en vigueur le 4 novembre 2016.
                Son objectif est de limiter le réchauffement climatique à un niveau bien inférieur à 2 degrés Celsius, de préférence à 1,5 degré Celsius, par rapport au niveau préindustriel.
                Pour cela, l’Etat, les individus et les entreprises doivent travailler main dans la main pour réduire les émissions et compenser celles ne pouvant être évitées. On parle ainsi d’une empreinte carbone de 2 tonnes de CO2 par français et par an pour atteindre cet objectif. 
                </p>
              </div>
          </div>

          <div className='row col-12'>
              <CardBilan/>
          </div>

          <div className='row col-12' style={{marginTop : "5vh"}}>
              <Band1/>
          </div>

          <div className='row col-12' style={{marginTop : "5vh"}}>
              <Band2/>
          </div>

        </div>
      <Footer />
      </div>
    </div>
  );
};

export default BilanPart;

