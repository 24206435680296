// FormAvion.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios'; // Importez la bibliothèque axios
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import './form.css';
import Ajout from '../Ajout';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useFormProgress } from '../../FormProgressContext';
import Alert from '@mui/material/Alert';
import MyComponent from '../../Api/BoutonAppel';
import BoutonToken from '../../Api/BoutonToken';
import ApiHandler from '../../Api/ApiHandler';
import AppelAPI from '../../Api/AppelAPI';
import AppelAPIAvion from '../../Api/AppelAPIAvion';
import BilanInter from '../../BilanInter';

function FormAvion({ lien }) {
  // ---------- Gestion des états ----------
  const { formStatus, setFormStatus } = useFormProgress();
  const navigate = useNavigate();
  
  // Etat pour stocker les données de la requête
  const [requestData, setRequestData] = useState(null);

  // Etat pour suivre l'état de soumission du formulaire
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [displayBilan, setDisplayBilan] = useState(false);
  const [hasEnteredData, setHasEnteredData] = useState(false);

  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API
   

  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponse = (response) => {
    setApiResponse(response);
    
    
  };


  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);
    //console.log('lancer');
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
    // setResponseData(null); // Réinitialiser les données avant l'appel API
  };
  
  // Etat pour stocker les voyages
  const [voyages, setVoyages] = useState([
    {
      typeVoyage: '',
      depart: {
        name: '',
        latitude: null,
        longitude: null
      },
      arrive: {
        name: '',
        latitude: null,
        longitude: null
      },
      ajout: { frequence: '0', unit: 'an' },
      emptyFieldsMessage: null,
    },
  ]);

  // Vérifier si le formulaire est valide 
  const isFormValid = voyages.every((voyage) => {
    return voyage.typeVoyage && voyage.depart.name && voyage.arrive.name && voyage.ajout.frequence !== '' ? true : false;
    //return voyage.depart.name && voyage.arrive.name && voyage.ajout.frequence !== '' ? true : false;
  });

  // ---------- Effet au chargement ----------
  useEffect(() => {
    // Mettre à jour le statut du formulaire
    setFormStatus({
      ...formStatus,
      avion: 'en cours',
    });
  }, []);


  useEffect(() => {
    if (displayBilan) {
      setFormStatus({
        ...formStatus,
        avion: 'fini',
      });
    } else if (formSubmitted) {
      setFormStatus({
        ...formStatus,
        avion: 'en cours',
      });
    }
  }, [voyages, formSubmitted]);

  // ---------- Chargement des voyages depuis le localStorage ----------
  useEffect(() => {
    if (hasEnteredData) {
      const savedVoyages = localStorage.getItem('voyages');
      if (savedVoyages) {
        setVoyages(JSON.parse(savedVoyages));
      }
    }
  }, [hasEnteredData]);

  // ---------- Sauvegarde des voyages dans le localStorage ----------
  useEffect(() => {
    localStorage.setItem('voyages', JSON.stringify(voyages));
    setHasEnteredData(true);
  }, [voyages]);

  // ---------- Gestion du clic sur le bouton ----------
  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate(lien);
  };

  // ---------- Ajout d'un nouveau voyage ----------
  const addVoyage = useCallback(() => {
    if (voyages.length < 10) {
      setVoyages((prevVoyages) => [
        ...prevVoyages,
        {
          typeVoyage: '',
          depart: {
            name: '',
            latitude: null,
            longitude: null
          },
          arrive: {
            name: '',
            latitude: null,
            longitude: null
          },
          ajout: { frequence: '', unit: 'an' }, // Initialize 'ajout' property
          emptyFieldsMessage: null,
        },
      ]);
    }
  }, [voyages]);
  

  // ---------- Gestion du changement des informations d'un voyage ----------
  const handleChangeVoyage = useCallback((index, field, value) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      setHasEnteredData(true);
      const voyage = updatedVoyages[index];
      if (voyage) {
        if (field === 'depart' || field === 'arrive') {
          voyage[field] = { ...voyage[field], ...value };
        } else {
          voyage[field] = value;
        }
      }
      return updatedVoyages;
    });
  }, []);

  
  const handleChangeAjout = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        // Récupérer les valeurs de fréquence et unité
        const { frequence, unit } = values;
        
        // Transformer unit selon les conditions
        let unitValue;
        switch (unit) {
          case 'an':
            unitValue = 279640000;
            break;
          case 'mois':
            unitValue = 279640001;
            break;
          case 'semaine':
            unitValue = 279640002;
            break;
          default:
            unitValue = 279640000; // Défaut à an si unit n'est pas reconnu
        }
        
        // Mettre à jour les valeurs
        voyage.ajout = { frequence, unit: unitValue };
      }
      return updatedVoyages;
    });
  }, [setVoyages]);

  // ---------- Gestion de la suppression d'un voyage ----------
  const handleDeleteVoyage = (index) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages.splice(index, 1);
      return updatedVoyages;
    });
  };
  //Bug car envoyé dans l'élément parent (bilan inter)

// ---------- Soumission du formulaire ----------
const handleSubmit = async (event) => {
  event.preventDefault();
  setFormSubmitted(true);
  setDisplayBilan(true);

  const emptyFields = [];

  // const isValid = voyages.every((voyage) => {
  //   return voyage.typeVoyage && voyage.depart.name && voyage.arrive.name && voyage.ajout.frequence;
  // });
  // setFormSubmitted(isValid);


  // ---------- Vérification des champs vides ----------
  voyages.forEach((voyage, index) => {
    if (!voyage.typeVoyage || !voyage.depart.name || !voyage.arrive.name || !voyage.ajout.frequence) {
      const emptyFieldsForVoyage = [];
      if (!voyage.typeVoyage) {
        emptyFieldsForVoyage.push('Type de voyage');
      }
      if (!voyage.depart.name) {
        emptyFieldsForVoyage.push('Ville de départ');
        voyage.depart.name = "erreur"
      }
      if (!voyage.arrive.name) {
        emptyFieldsForVoyage.push('Ville d\'arrivée');
        voyage.arrive.name = "erreur"
      }
      if (!voyage.ajout.frequence) {
        emptyFieldsForVoyage.push('Fréquence du voyage');
      }

      emptyFields.push({
        voyageIndex: index,
        fields: emptyFieldsForVoyage,
      });

    }
  });

  // ---------- Gestion erreur : champs vides ----------
  if (emptyFields.length > 0) {
    const emptyFieldsMessage = emptyFields.map(
      entry => `Voyage ${entry.voyageIndex + 1}: ${entry.fields.join(', ')}`
    ).join('\n');

    // Mise à jour des messages d'erreurs dans les voyages
    setVoyages(prevVoyages => prevVoyages.map((prevVoyage, index) => {
      if (emptyFields.find(entry => entry.voyageIndex === index)) {
        return { ...prevVoyage, emptyFieldsMessage };
      }
      return prevVoyage;
    }));
    return; // Arrêter le traitement si des champs sont vides
  }



  // ---------- Requête API ----------
  // try {

  //   if (isFormValid) {
  //     const requestPromises = voyages.map((voyage, index) => {
  //       return AppelAPIAvion(voyage, lancer, updateApiResponse);
        
  //     });

  //     await Promise.all(requestPromises);

  //     setFormSubmitted(true);
  //     // Mise à jour du statut de la step line
      

    

  //     // Mettez à jour l'état requestData avec les données envoyées dans la requête
  //     setRequestData(requestPromises.map(promise => promise.config.data));
  //   }
  // } catch (error) {
  //   console.error('Erreur lors de l\'envoi des requêtes :', error);
  //   alert('Une erreur s\'est produite lors de l\'envoi des données.');
  // }
};

  // ---------- Rendu du composant ----------
  return (
    <form onSubmit={handleSubmit}>
      <div className="row col-12">
        <div className="SousTitre">
          Si votre voyage comporte une ou plusieurs escales, décomposez-le en plusieurs voyages. 
        </div>
      </div>


      {voyages.map((voyage, index) => (
        <div className="duplique" key={index}>
          <div className="row col-12">
            <div className="SousTitre">
              <DeleteOutlinedIcon
                sx={{ color: "#024C4A" }}
                className="Icon"
                onClick={() => handleDeleteVoyage(index)}
              />
              <div className="h61">Voyage {index + 1}</div>
            </div>
          </div>
          
          {/* Affiche non */}
          {voyage.arrive.name === '' ? '' : voyage.arrive.name === voyage.depart.name ? (<div className='row col-12' style={{marginTop : "2vh", marginBottom : "3vh"}}><Alert severity="error">Les noms de la ville de départ et d'arrivée ne peuvent pas être identiques.</Alert></div>) : ("")}
       

          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <ListDeroule
                titre="Type de voyage"
                value={voyage.typeVoyage === 279640001 ? 'Aller simple':'Aller - Retour'}
                onChange={(value) => handleChangeVoyage(index, 'typeVoyage', value==='Aller simple'? 279640001:279640000)}
                text="Aller - retour"
                mots={['Aller simple']}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <InputField
              titre="Départ"
              erreur = {voyage.depart.name === "erreur" ? true : ""}
              value={voyage.depart.name === "erreur" ? "" : voyage.depart.name} // Use the name from the depart object
              onChange={(value) => handleChangeVoyage(index, 'depart', value)}
            />
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <InputField
              titre="Arrivée"
              erreur = {voyage.arrive.name === "erreur" ? true : ""}
              value={voyage.arrive.name === "erreur" ? "" : voyage.arrive.name} // Use the name from the depart object
              onChange={(value) => handleChangeVoyage(index, 'arrive', value)}
            />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Ajout
                values={voyage.ajout}
                
                setValues={(values) => handleChangeAjout(index, values)}
              />
            </div>
          </div>
        </div>
      ))}

      <div className="row">
        <div className="col-12">
          {voyages.length < 10 && (
            <button type="button" className="custom-button" onClick={addVoyage}>
              Ajouter un voyage
            </button>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          
          <Bouton titre="CALCULER" onClick={handleButtonClickAPI}/>

        </div>
      </div>
      
      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
            {formSubmitted && isFormValid && voyages.map((voyage, index) => (
              <div className="duplique" key={index}>
              <AppelAPIAvion voyage={voyage} Lancer={lancer} setApiResponse={updateApiResponse}/>
               {console.log(apiResponse)}
            {apiResponse && (
            <div>
              <h2>Réponse de l'API :</h2>
              <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
            </div>
          )} 
              
              {/* Affichage des valeurs spécifiques pour ce voyage */}
                  {/* <div className="row">
                    <div className="col-12">
                      <div className='h61'>Valeurs spécifiques pour Voyage {index + 1} :</div>
                      <div>IdPage : PAvion</div>
                      <div>typeVoyage : {voyage.typeVoyage}</div>
                      <div>depart : {voyage.depart.name}</div>
                      <div>latitudeDepart : {voyage.depart.latitude}</div>
                      <div>longitudeDepart : {voyage.depart.longitude}</div>
                      <div>arrive : {voyage.arrive.name}</div>
                      <div>latitudeArrive : {voyage.arrive.latitude}</div>
                      <div>longitudeArrive : {voyage.arrive.longitude}</div>
                      <div>frequence : {voyage.ajout.frequence}</div>
                      <div>unit : {voyage.ajout.unit}</div>
                      <div>message : {voyage.emptyFieldsMessage}</div>
                    </div>
                  </div> */}
                </div>
              ))}
            </div>
          </div>



          {displayBilan && (
          <div>
            <div className="row">
              <div className="col-12">
                <BilanInter voyages={voyages} numeroEtape={1} voyage="oui" responseAPIAvion={apiResponse} /> 
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
              </div>
            </div>
          </div>
        )}


          
        </div>
      )}
    </form>
  );
}

export default FormAvion;
