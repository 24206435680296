import React from "react";
import "./TitreH2.css";

function TitreH2(props) {
  const { Titre, couleur } = props;

  const styleTitre = {
    color: couleur || "#024C4A", // Utilise la couleur spécifiée ou "#024C4A" par défaut
  };


  return <h1 className="h21" style={styleTitre}>{ Titre }</h1>;
}

export default TitreH2;

