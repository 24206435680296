import React, { useState, useEffect } from 'react';
import './Ajout.css';

const AjoutPerso = ({ values, setValues }) => {
  const [valeur, setValeur] = useState(values && values.valeur ? values.valeur : '2');

  const handleChangeValeur = (event) => {
    const newValue = event.target.value.replace(/\D/g, ''); // Supprimer les caractères non numériques
    setValeur(newValue);
    setValues({ ...values, valeur: newValue }); // Mettre à jour la valeur dans l'objet values
  };

  const handleClickMinus = (event) => {
    event.preventDefault();
    const newValue = parseInt(valeur) - 1;
    if (!isNaN(newValue)) {
      setValeur(String(newValue)); // Mettre à jour la valeur de l'état local
      setValues({ ...values, valeur: String(newValue) }); // Mettre à jour la valeur dans l'objet values
    }
  };

  const handleClickPlus = (event) => {
    event.preventDefault();
    const newValue = parseInt(valeur) + 1;
    if (!isNaN(newValue)) {
      setValeur(String(newValue)); // Mettre à jour la valeur de l'état local
      setValues({ ...values, valeur: String(newValue) }); // Mettre à jour la valeur dans l'objet values
    }
  };

  return (
    <div className="ajout-container">
      <span className="ajout-label">Nombre de personnes :</span>
      <div className="ajout-input-container">
        <button className="ajout-button" onClick={handleClickMinus}>-</button>
        <input
          type='text'
          className="ajout-input"
          value={valeur}
          onChange={handleChangeValeur}
          pattern="[0-9]*"
        />
        <button className="ajout-button" onClick={handleClickPlus}>+</button>
      </div>
      <span className="labelperso">personne(s)</span>
    </div>
  );
};

export default AjoutPerso;