import React from 'react';
import "./Input.css";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

function InputFieldNbUnique(props) {
    const { titre, value, onChange, unit } = props;

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      const numValue = parseFloat(inputValue);
  
      if (inputValue === '') {
        onChange(''); // Assurez-vous que la valeur est mise à jour avec une chaîne vide
      } else if (!isNaN(numValue)) {
        onChange(numValue);
        localStorage.setItem(titre, numValue);
      }
    };

    return (
      <div className="InputUnit">
        <div className="col-25 label-down">
          <label className="h61">{titre}</label>
        </div>
        <OutlinedInput
          sx={{
            m: 1,
            width: '90%',
            border: '0px solid black',
            borderRadius: '0px',
            marginTop: '2px',
          }}
          endAdornment={
            <InputAdornment position="end">{unit}</InputAdornment>
          }
          placeholder="0"
          value={value || ''}
          onChange={handleInputChange}
        />
      </div>
    );
}

export default InputFieldNbUnique;
