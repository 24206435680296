import React from 'react';
import './BilanInter.css';
import LogoAvion from "../img/LogoAvion.png";
import LogoVoiture from "../img/LogoVoiture.png";
import LogoTrain from "../img/train.png";
import LogoTC from "../img/TC.png";
import LogoAl from "../img/Al.png";
import LogoDom from "../img/dom.png";
import LogoDechet from "../img/dechet.png";
import LogoEnergie from "../img/Elect.png";
import LogoDepTerrestre from "../img/LogoDep.png";
import LogoFret from "../img/Fret.png";
import LogoImmo from "../img/Immo.png";
import LogoNumerique from "../img/numerique.png";





import Titre from '../Titre';
import SousTitre from '../SousTitre';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const BilanInterEnt = ({ voyages, numeroEtape, handleDeleteVoyage, voyage }) => {
  const collection = [
    {
      text: 'Bilan Energie : 3,4 tonnes de CO2 par an',
      image: LogoEnergie
    },
    {
      text: 'Bilan Deplacement terrestre : 3,4 tonnes de CO2 par an',
      image: LogoDepTerrestre
    },
    {
      text: 'Bilan Deplacement aérien : 3,4 tonnes de CO2 par an',
      image: LogoAvion
    },
    {
      text: 'Bilan Fret : 3,4 tonnes de CO2 par an',
      image: LogoFret
    },    
    {
      text: 'Bilan Immobilisations & Consommables : 3,4 tonnes de CO2 par an',
      image: LogoImmo
    },
    {
      text: 'Bilan Numérique : 3,4 tonnes de CO2 par an',
      image: LogoNumerique
    },    
    // Ajoutez les autres étapes de la collection ici
  ];


  const collectionAffichee = collection.slice(0, numeroEtape);

  return (
    <div className='BilanInt'>
      <Titre Titre="Bilan intermédiaire" place="start" />
      <div className="containerC">
        <div className="contentB">
          <SousTitre SousTitre="Bilan cumulé intermédiaire : 3,4 tonnes de CO2 par an" />
        </div>
      </div>

      {collectionAffichee.map((item, index) => (
        <div className="containerB" key={index}>
          <div className="contentB">
            <span className="textB" style={{fontWeight : "800"}}>{item.text}</span>
            <div className="imageCont">
            <img className="imageLogo" src={item.image} alt="Image centrée" />
            </div>
          </div>
        </div>
      ))}

    {voyage === "non" ? ("") : (
      voyages.map((voyage, index) => (
        <div className="containerB" key={index}>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Voyage {index + 1} : {voyage.prenom} - {voyage.nom},  {voyage.ajout.valeur} fois {voyage.ajout.unite}</span>
            <div className="textB col-4">0,7 tonne(s) de CO2</div>
            <div className="icon-container float-end">
              <DeleteOutlinedIcon className="Icon" onClick={() => handleDeleteVoyage(index)} />
            </div>
          </div>
        </div>
      ))
    )
  }

    </div>
  );
}

export default BilanInterEnt;

