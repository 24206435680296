import React, { createContext, useContext, useState } from 'react';

const FormProgressContext = createContext();

export const FormProgressProvider = ({ children }) => {
  const [formStatus, setFormStatus] = useState({
    avion: 'vide', // 'vide', 'en cours', 'fini'
    voiture: 'vide',
    train: 'vide',
    tc: 'vide',
    placement: 'vide',
    alimentaire: 'vide',
    domicile: 'vide',
    dechet: 'vide',
  });

  return (
    <FormProgressContext.Provider value={{ formStatus, setFormStatus }}>
      {children}
    </FormProgressContext.Provider>
  );
};

export const useFormProgress = () => {
  return useContext(FormProgressContext);
};
