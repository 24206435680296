import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import CheckboxChoixUnique from '../checkBox/checkbox';
import Compteur from '../../Compteur/Compteur';
import CheckboxChoixUniqueAdapt from '../checkBox/checkbox-adapt';
import imageEnergie from "../../img/Energie.png"
import ConsomationAnnel from './DomicileAnnuel';
import ConsomationAnnelIndividuel from './DomicileAnnuelIndividuel';
import BilanInter from '../../BilanInter';
import LogoElect from "../../img/Elect.png";
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import LogoChauf from "../../img/ChauffageUrbain.png";
import Logogpl from "../../img/GPL.png";
import Logogaz from "../../img/gaz.png";
import LogoBoi from "../../img/bois.png";
import LogoFioul from "../../img/fioule.png";
import LogoPanneauSol from "../../img/panneauSol.png";
import LogoPaneauVolt from "../../img/panneauPhoto.png";
import LogoEolienne from "../../img/eolienne.png";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BilanInterEnt from '../../BilanInter/indexEntreprise';


function FormEnergie({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState("");
  const [ChauffageColl, setChauffageColl] = useState(false);
  const [afficherCheckboxAdapt, setAfficherCheckboxAdapt] = useState(false);
  const [locaux, setLocaux] = useState([{ nom: '', modeChauffage: '', typeActivite: '', surface: '' }]);

  const reponses = ['≤5  A', '6 à 10  B', '11 à 20 C', '21 à 35 D', '36 à 55 E', '56 à 80 F', '> 80 G'];
  const reponseA = [ 'Bureau', 'Commerce', 'Enseignement', 'Batiment agricole', 'Santé', 'Autres'];
  const reponseChauffCol = ['Gaz', 'GPL', 'Chauffage urbain', 'Bois et plaquettes', 'Fioul'];

  const addLocal = () => {
    if (locaux.length < 10) {
      setLocaux((prevLocaux) => [
        ...prevLocaux,
        { nom: '', modeChauffage: '', typeActivite: '', surface: '' },
      ]);
    }
  };
  
  const handleChangeLocal = (index, field, value) => {
    setLocaux((prevLocaux) => {
      const updatedLocaux = [...prevLocaux];
      const local = updatedLocaux[index];
      if (local) {
        local[field] = value;
      }
      return updatedLocaux;
    });
  };
  
  const handleSurfaceChange = (index, value) => {
    setLocaux((prevLocaux) => {
      const updatedLocaux = [...prevLocaux];
      const local = updatedLocaux[index];
      if (local) {
        local.surface = value;
      }
      return updatedLocaux;
    });
  };
  
  const handleDeleteLocal = (index) => {
    setLocaux((prevLocaux) => {
      const updatedLocaux = [...prevLocaux];
      updatedLocaux.splice(index, 1);
      return updatedLocaux;
    });
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(formSubmitted);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Entreprise/Deplacement'); // Ajout de la navigation après la soumission du formulaire
  };

  const handleCheckboxChange = (value) => {
    setConsommationConnue(value);
    setAfficherCheckboxAdapt(value === "oui");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <CheckboxChoixUnique
            onChange={(value) => setConsommationConnue(value)}
            titre="Connaissez vous la consommation énergétique totale de vos locaux. Attention il faut prendre en compte les parties communes."
            rep1="oui"
            rep2="non"
          />
        </div>
      </div>
  
      {consommationConnue === "" ? ('') : consommationConnue ? (
        <div className="row col-12 Connais">
          <div className="h61">
            Indiquez votre consommation annuelle d’électricité en euro ou unité d’achat :
          </div>
          <div className="row col-12">
            <div className="col-lg col-md-6 col-12"><CarreLogoInput2 logo={LogoElect} titregras="Electricité" titre="Contrat électricité" /></div>
            <div className="col-lg col-md-6 col-12"></div>
            <div className="col-lg col-md-6 col-12"></div>
            <div className="col-lg col-md-6 col-12"></div>
            <div className="col-lg col-md-6 col-12"></div>
          </div>
          <div className="h61" style={{ marginTop: "5vh" }}>
            Indiquez vos consommation annuelle des modes de chauffage non électrique en euro ou unité d’achat pour :
          </div>
          <div className="row col-12" style={{ marginBottom: "10vh" }}>
            <div className="col-lg col-md-6 col-12"><CarreLogoInput2 logo={LogoChauf} titregras="Chauffage urbain" titre="Alimentation de plusieurs bâtiments par une chaleur provenant d’un seul site de production." /></div>
            <div className="col-lg col-md-6 col-12"><CarreLogoInput2 logo={Logogpl} titregras="GPL" titre="Chaudière GPL ou propane" unit1='€' unit='tonnes' /></div>
            <div className="col-lg col-md-6 col-12"><CarreLogoInput2 logo={Logogaz} titregras="Gaz" titre="Definition" /></div>
            <div className="col-lg col-md-6 col-12"><CarreLogoInput2 logo={LogoBoi} titregras="Bois Plaquettes forestières" titre="Chauffage au poêle ou avec une cheminée" unit1='€' unit='stères' /></div>
            <div className="col-lg col-md-6 col-12"><CarreLogoInput2 logo={LogoFioul} titregras="Fioul" titre="Definition" unit1='€' unit='L' /></div>
          </div>
        </div>
      ) : (
        <div>
          {locaux.map((local, index) => (
            <div key={index} className="LocauxCol-lg col-md-6 col-12">
              <div className="row col-12">
                <div className="Locaux">
                  <DeleteOutlinedIcon sx={{ color: "#024C4A" }} className="Icon" onClick={() => handleDeleteLocal(index)} />
                  <div className="h61">Local {index + 1}</div>
                </div>
              </div>
              <div className="row col-12">
                <div className="col-12 col-md-6" style={{ marginBottom: "3vh" }}>
                  <Compteur
                    titre="Surface des locaux chauffés"
                    unit="m²"
                    onValeurChange={(value) => handleSurfaceChange(index, value)}
                    val="100"
                  />
                </div>
              </div>
              <div className="row col-12">
                <div className="col-12 col-md-4">
                  <InputField
                    titre="Nom de local"
                    value={local.nom}
                    onChange={(value) => handleChangeLocal(index, 'nom', value)}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <ListDeroule
                    titre="Mode de chauffage"
                    text="Eléctrique"
                    storageKey={`uniqueKey-${index}`}
                    value={local.modeChauffage}
                    onChange={(value) => handleChangeLocal(index, 'modeChauffage', value)}
                    mots={reponseChauffCol}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <ListDeroule
                    titre="Type d'activité"
                    text="Bureau"
                    storageKey={`uniqueKey-${index}`}
                    value={local.typeActivite}
                    onChange={(value) => handleChangeLocal(index, 'typeActivite', value)}
                    mots={reponseA}
                  />
                </div>
              </div>
            </div>
          ))}
  
          <div className="row">
            <div className="col-12">
              <button type="button" className="custom-button" onClick={addLocal}>
                Ajouter un Local
              </button>
            </div>
          </div>
        </div>
      )}
  
      <div className="row">
        <div className="col-12">
          <Bouton titre="calculer" />
        </div>
      </div>
  
      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInterEnt voyage="non" numeroEtape={1} />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <h3>Valeurs des locaux :</h3>
              {locaux.map((local, index) => (
                <div key={index}>
                  <p>Local {index + 1}</p>
                  <p>Nom : {local.nom}</p>
                  <p>Mode de chauffage : {local.modeChauffage}</p>
                  <p>Type d'activité : {local.typeActivite}</p>
                  <p>Surface : {local.surface}</p>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
  
    </form>
  );
  
}

export default FormEnergie;
