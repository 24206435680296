import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from '../../Titre';
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import "./form.css";
import Compteur from '../../Compteur/Compteur';
import BilanInter from '../../BilanInter';
import Bouton from '../Bouton';
import CheckboxChoixUnique from '../checkBox/checkbox';
import BilanInterEnt from '../../BilanInter/indexEntreprise';

const FormDeplacement = ({}) => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [rep, setRep] = useState("");
  const [categories, setCategories] = useState([
    {
      titre: 'Voiture',
      rep : '',
      compteurs: [
        { titre: 'Kilométrage Essence', unite: 'km', valeur: '0' },
        { titre: 'Nombre de personnes', unite: '', valeur: '1' },
        { titre: 'Kilométrage Diesel', unite: 'km', valeur: '0' },
        { titre: 'Nombre de personnes', unite: '', valeur: '1' },
        { titre: 'Kilométrage Hybride', unite: 'km', valeur: '0' },
        { titre: 'Nombre de personnes', unite: '', valeur: '1' },
        { titre: 'Kilométrage Electrique', unite: 'km', valeur: '0' },
        { titre: 'Nombre de personnes', unite: '', valeur: '1' }
      ]
    },
    {
      titre: 'Moto',
      rep : '',
      compteurs: [
        { titre: 'Kilométrage Essence', unite: 'km', valeur: '0' },
        { titre: 'Nombre de personnes', unite: '', valeur: '1' },
        { titre: 'Kilométrage Diesel', unite: 'km', valeur: '0' },
        { titre: 'Nombre de personnes', unite: '', valeur: '1' },
        { titre: 'Kilométrage Hybride', unite: 'km', valeur: '0' },
        { titre: 'Nombre de personnes', unite: '', valeur: '1' },
        { titre: 'Kilométrage Electrique', unite: '€', valeur: '0' },
        { titre: 'Nombre de personnes', unite: '', valeur: '1' }
      ]
    },
    {
      titre: 'Transport en commun',
      rep : 'oui',
      compteurs: [
        { titre: 'Métro/Tram/RER en région parisienne', unite: 'km', valeur: '0' },
        { titre: 'Nombre de personnes', unite: '', valeur: '1' },
        { titre: 'Métro/Tram hors région parisienne', unite: 'km', valeur: '0' },
        { titre: 'Nombre de personnes', unite: '', valeur: '1' },
        { titre: 'Kilométrage Bus', unite: 'km', valeur: '0' },
      ]
    },
    {
      titre: 'Train',
      compteurs: [
        { titre: 'Kilométrage', unite: 'km', valeur: '0' },
      ]
    }
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(formSubmitted);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Entreprise/Deplacement-2'); // Ajout de la navigation après la soumission du formulaire
  };

  const handleChangeCompteur = (categoryIndex, compteurIndex, value) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const category = updatedCategories[categoryIndex];
      if (category) {
        const updatedCompteurs = [...category.compteurs];
        updatedCompteurs[compteurIndex].valeur = value;
        category.compteurs = updatedCompteurs;
      }
      return updatedCategories;
    });
  };

  const handleConsommationChange = (categoryIndex, value) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const category = updatedCategories[categoryIndex];
      if (category) {
        const updatedCompteurs = [...category.compteurs];
        if (value) {
          updatedCompteurs.forEach((compteur, compteurIndex) => {
            if (compteurIndex % 2 === 0) {
               { compteur.titre === 'Kilométrage Electrique' ? compteur.unite = '€' : compteur.unite = 'L' };
              category.rep = 'oui';
            }
          });
        } else {
          updatedCompteurs.forEach((compteur, compteurIndex) => {
            if (compteurIndex % 2 === 0) {
              compteur.unite = 'km';
              category.rep = 'non';
            }
          });
        }
        category.compteurs = updatedCompteurs;
      }
      return updatedCategories;
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="conso">
        <div className="h61 vert">
        Consommation annuelle
        </div>
        <div className="p1">
          Renseigner le kilométrage cumulé sur la durée du bilan par tous les employés :
        </div>
  
        {categories.map((category, categoryIndex) => (
          <div key={categoryIndex}>
            <div className="h61 vert" style={{ marginTop: "5vh" }}>
              {category.titre}
            </div>
  
            {category.titre !== "Train" && category.titre !== "Transport en commun" && (
              <CheckboxChoixUnique
                titre="Connaissez-vous votre consommation ?"
                rep1="Oui"
                rep2="Non"
                onChange={(value) => handleConsommationChange(categoryIndex, value)}
              />
            )}
  
            <div className="row">
              {category.rep === "" ? (
                ""
              ) : (
                category.compteurs.map((compteur, compteurIndex) => {
                  if (compteurIndex % 2 === 0) {
                    return (
                      <div key={compteurIndex} className="col-12 col-md-5">
                        <Compteur
                          titre={compteur.titre}
                          unit={compteur.unite}
                          valeur={compteur.valeur}
                          onValeurChange={(value) =>
                            handleChangeCompteur(categoryIndex, compteurIndex, value)
                          }
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={compteurIndex} className="col-12 col-md-5">
                        {category.rep === "oui" ? (
                          ""
                        ) : (
                          <div>
                            <Compteur
                              titre={compteur.titre}
                              unit={""}
                              italique={true}
                              valeur={compteur.valeur}
                              onValeurChange={(value) =>
                                handleChangeCompteur(categoryIndex, compteurIndex, value)
                              }
                            />
                          </div>
                        )}
                      </div>
                    );
                  }
                })
              )}
            </div>
          </div>
        ))}
  
        <div className="row">
          <div className="Bouton col-12">
            <Bouton titre="Soumettre" />
          </div>
        </div>
  
        {formSubmitted && (
          <div>
            <div className="row">
              <div className="col-12">
                <BilanInterEnt voyage="non" numeroEtape={2} />
              </div>
            </div>
  
            <div className="row">
              <div className="col-12">
                <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
              </div>
            </div>
  
            {/* Affichage des valeurs des compteurs */}
            <div className="row">
              <div className="col-12">
                <h3>Valeurs des compteurs :</h3>
                {categories.map((category, categoryIndex) => (
                  <div key={categoryIndex}>
                    <h4>{category.titre}</h4>
                    <div className="row">
                      {category.compteurs.map((compteur, compteurIndex) => {
                        if (compteurIndex % 2 === 0) {
                          return (
                            <div key={compteurIndex} className="col-12 col-md-5">
                              <p>
                                {compteur.titre}: {compteur.valeur} {compteur.unite}
                              </p>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );  
};

export default FormDeplacement;
