import React from 'react';
import "./Footer.css"

const Footer = (props) => {
    const { contenue } =  props;
  return (
    <footer>
       <p className='p1'>{ contenue }</p>
    </footer>
  );
};

export default Footer;
