import React from 'react';
import './StepperLine-test.css';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TrainIcon from '@mui/icons-material/Train';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HouseIcon from '@mui/icons-material/House';
import RecyclingIcon from '@mui/icons-material/Recycling';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useNavigate } from 'react-router-dom'; // import the useNavigate hook
import { useFormProgress } from '../FormProgressContext';

const StepperLineVoyage = () => {
  const navigate = useNavigate(); // instantiate the navigate function
  const { formStatus } = useFormProgress();

  const getColor = (status) => {
    switch (status) {
      case 'fini': return 'green';
      case 'en cours': return 'orange';
      default: return 'grey';
    }
  };

  return (
    <div className="containerT">

      <div className="circle" onClick={() => navigate('/Voyage/avion')}>
        <FlightIcon sx={{ color: getColor(formStatus.avion) }} />
        <div className="textT" style={{color: getColor(formStatus.avion)}}>Avion</div>
      </div>

      <div className="lineT line-between"></div>
      <div className="circle" onClick={() => navigate('/Voyage/Voiture-1')}>
        <DirectionsCarIcon sx={{ color: getColor(formStatus.voiture) }}/>
        <div className="textT"  style={{color: getColor(formStatus.voiture)}}>Voiture</div>
      </div>

      <div className="lineT line-between"></div>
      <div className="circle" onClick={() => navigate('/Voyage/Train')}>
        <TrainIcon sx={{ color: getColor(formStatus.train) }} />
        <div className="line-down"></div>
        <div className="textT" style={{color: getColor(formStatus.train)}}>Train</div>
      </div>

      <div className="lineT line-between"></div>
      <div className="circle" onClick={() => navigate('/Voyage/TC')}>
        <PedalBikeIcon sx={{ color: getColor(formStatus.tc) }} />
        <div className="line-down"></div>
        <div className="textT" style={{color: getColor(formStatus.tc)}}>Autres transports</div>
      </div>


    </div>
  );
}

export default StepperLineVoyage;
