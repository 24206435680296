import React, { useState, useEffect } from 'react';
import './Graphique.css';
import AppelAPIBilanAvion from '../Api/APIBilan/APIBilanAvion';
import AppelAPIBilanVoiture from '../Api/APIBilan/APIBilanVoiture';
import AppelAPIBilanMoto from '../Api/APIBilan/APIBilanMoto';
import AppelAPIBilanVLoc from '../Api/APIBilan/APIBilanVLoc';
import AppelAPIBilanTaxi from '../Api/APIBilan/APIBilanTaxi';
import AppelAPIBilanTrain from '../Api/APIBilan/APIBilanTrain';
import AppelAPIBilanTC from '../Api/APIBilan/APIBilanTC';
import AppelAPIBilanPlacement from '../Api/APIBilan/APIBilanPlacement';
import AppelAPIBilanAlimentaire from '../Api/APIBilan/APIBilanAlimentaire';
import AppelAPIBilanDomicile from '../Api/APIBilan/APIBilanDomicile';
import AppelAPIBilanDechet from '../Api/APIBilan/APIBilanDechet'; 

const Chart = () => {
  // Données pour les éléments du graphique
  const [numbers, setNumbers] = useState({});
  const [apiResponseAvion, setApiResponseAvion] = useState(null);
  const [apiResponseVoiture, setApiResponseVoiture] = useState(null);
  const [apiResponseMoto, setApiResponseMoto] = useState(null);
  const [apiResponseVLoc, setApiResponseVLoc] = useState(null);
  const [apiResponseTaxi, setApiResponseTaxi] = useState(null);
  const [apiResponseTrain, setApiResponseTrain] = useState(null);
  const [apiResponseTC, setApiResponseTC] = useState(null);
  const [apiResponsePlacement, setApiResponsePlacement] = useState(null);
  const [apiResponseAlimentaire, setApiResponseAlimentaire] = useState(null);
  const [apiResponseDomicile, setApiResponseDomicile] = useState(null);
  const [apiResponseDechet, setApiResponseDechet] = useState(null);
  

  // Données de la moyenne française
  const franceNumbers = {
    consoTotalAvion: 0.42,
    consoTotalTC: 0.18,
    consoTotalTrain: 0.05, //J'ai pas la valeur
    consoTotalVoiture: 2.1,
    consoTotalDomicile: 1.9,
    consoTotalDechet: 1.4,
    consoTotalPlacement: 0.09,
    consoTotalAlimentation: 2.45,
    consoTotalServiceP: 1.1,
  };

  //Test pour l'affichage dynamique grace aux appels API globaux. A tester lorsque ceux ci seront prêts
 useEffect(()=>{

  <AppelAPIBilanAvion Lancer= {true} setApiResponse={setApiResponseAvion}/>;
  <AppelAPIBilanVoiture Lancer= {true} setApiResponse={setApiResponseVoiture}/>;
  <AppelAPIBilanMoto Lancer= {true} setApiResponse={setApiResponseMoto}/>;
  <AppelAPIBilanVLoc Lancer= {true} setApiResponse={setApiResponseVLoc}/>;
  <AppelAPIBilanTaxi Lancer= {true} setApiResponse={setApiResponseTaxi}/>;
  <AppelAPIBilanTrain Lancer= {true} setApiResponse={setApiResponseTrain}/>;
  <AppelAPIBilanTC Lancer= {true} setApiResponse={setApiResponseTC}/>;
  <AppelAPIBilanPlacement Lancer= {true} setApiResponse={setApiResponsePlacement}/>;
  <AppelAPIBilanAlimentaire Lancer= {true} setApiResponse={setApiResponseAlimentaire}/>;
  <AppelAPIBilanDomicile Lancer= {true} setApiResponse={setApiResponseDomicile}/>;
  <AppelAPIBilanDechet Lancer= {true} setApiResponse={setApiResponseDechet}/>;


  const apiResponseAvionString=JSON.stringify({apiResponseAvion});
  const apiResponseAvionParsed=JSON.parse(apiResponseAvionString);
  const sumAvion = apiResponseAvion ? (apiResponseAvionParsed.responseAPI.ConsoTotalVoyage):0;

  let sumVoiture = 0;

  if (apiResponseVoiture || apiResponseMoto || apiResponseVLoc || apiResponseTaxi) {
    // Vérifier si l'objet responseAPIVoiture contient la clé "responseAPI" et la clé "ConsoTotalVoiture"
    if (apiResponseVoiture.responseAPI && apiResponseVoiture.responseAPI.ConsoTotalVoiture) {
      sumVoiture = apiResponseVoiture.responseAPI.ConsoTotalVoiture;
    }
    else if (apiResponseMoto.responseAPI && apiResponseVoiture.responseAPI.ConsoTotalMoto) {
      sumVoiture = apiResponseMoto.responseAPI.consoTotalMoto;
    }
    else if (apiResponseVLoc.responseAPI && apiResponseVLoc.responseAPI.ConsoVoitureLoc) {
      sumVoiture = apiResponseVLoc.responseAPI.ConsoVoitureLoc;
    }
    else if (apiResponseTaxi.responseAPI && apiResponseTaxi.responseAPI.ConsoTotalTaxi) {
      sumVoiture = apiResponseTaxi.responseAPI.ConsoTotalTaxi;
    }
  }


  const apiResponseTrainString=JSON.stringify({apiResponseTrain});
  const apiResponseTrainParsed=JSON.parse(apiResponseTrainString);
  const sumTrain = apiResponseTrain ? (apiResponseTrainParsed.responseAPI.ConsoTrain):0;

  const apiResponseTCString=JSON.stringify({apiResponseTC});
  const apiResponseTCParsed=JSON.parse(apiResponseTCString);
  const sumTC = apiResponseTC ? (apiResponseTCParsed.responseAPI.ConsoTC):0;


  const apiResponsePlacementString=JSON.stringify({apiResponsePlacement});
  const apiResponsePlacementParsed=JSON.parse(apiResponsePlacementString);
  const sumPlacement = apiResponsePlacement ? (apiResponsePlacementParsed.responseAPI.ConsoPlacements):0;


  const apiResponseAlimentaireString=JSON.stringify({apiResponseAlimentaire});
  const apiResponseAlimentaireParsed=JSON.parse(apiResponseAlimentaireString);
  const sumAlimentaire = apiResponseAlimentaire ? (apiResponseAlimentaireParsed.responseAPI.ConsoAlimentaire):0;


  const apiResponseDomicileString=JSON.stringify({apiResponseDomicile});
  const apiResponseDomicileParsed=JSON.parse(apiResponseDomicileString);
  const sumDomicile = apiResponseDomicile ? (apiResponseDomicileParsed.responseAPI.ConsoDomicile):0;

  const apiResponseDechetString=JSON.stringify({apiResponseDechet});
  const apiResponseDechetParsed=JSON.parse(apiResponseDechetString);
  const sumDechet = apiResponseDomicile ? (apiResponseDechetParsed.responseAPI.ConsoTotalDechetConsommation):0;
},[]);

  useEffect(() => {
    const APIResults = {
      consoTotalAvion: 1.8,
      consoTotalTC: 0.54,
      consoTotalTrain: 3,
      consoTotalVoiture: 1,
      consoTotalDomicile: 1.5,
      consoTotalDechet: 1.4,
      consoTotalPlacement: 1.4,
      consoTotalAlimentation: 5.1,
      consoTotalServiceP: 1.1,
    };

    setNumbers(APIResults);
  }, []);


 
 

  const calculateHeight = (value) => {
    const maxValue = Math.max(...Object.values(numbers));
    return (value / maxValue) * 370; // 370 étant la taille maximale sinon ça dépasse sur la légende
  };

  const data = [
    { label: 'Avion', color: '#EBE085', titre: numbers.consoTotalAvion?.toFixed(2) + "t", titre2: franceNumbers.consoTotalAvion?.toFixed(2) + "t", valueKey: 'consoTotalAvion' },
    { label: 'Transport en commun', color: '#68C4A5', titre: numbers.consoTotalTC?.toFixed(2) + "t", titre2: franceNumbers.consoTotalTC?.toFixed(2) + "t",valueKey: 'consoTotalTC' },
    { label: 'Train', color: '#FBAB3B', titre: numbers.consoTotalTrain?.toFixed(2) + "t", titre2: franceNumbers.consoTotalTrain?.toFixed(2) + "t", valueKey: 'consoTotalTrain' },
    { label: 'Voiture', color: '#024C4A', titre: numbers.consoTotalVoiture?.toFixed(2) + "t", titre2: franceNumbers.consoTotalVoiture?.toFixed(2) + "t", valueKey: 'consoTotalVoiture' },
    { label: 'Domicile', color: '#313179', titre: numbers.consoTotalDomicile?.toFixed(2) + "t", titre2: franceNumbers.consoTotalDomicile?.toFixed(2) + "t", valueKey: 'consoTotalDomicile' },
    { label: 'Placement', color: '#77B5FE', titre: numbers.consoTotalPlacement?.toFixed(2) + "t", titre2: franceNumbers.consoTotalPlacement?.toFixed(2) + "t", valueKey: 'consoTotalPlacement' },
    { label: 'Alimentation', color: '#C14118', titre: numbers.consoTotalAlimentation?.toFixed(2) + "t", titre2: franceNumbers.consoTotalAlimentation?.toFixed(2) + "t", valueKey: 'consoTotalAlimentation' },
    { label: 'Déchets', color: '#8C8C8C', titre: numbers.consoTotalDechet?.toFixed(2) + "t", titre2: franceNumbers.consoTotalDechet?.toFixed(2) + "t",valueKey: 'consoTotalDechet' },
    { label: 'Services publics', color: '#000000', titre: numbers.consoTotalServiceP?.toFixed(2) + "t", titre2: franceNumbers.consoTotalServiceP?.toFixed(2) + "t", valueKey: 'consoTotalServiceP' },
  ];

  return (
    <div className="chart">
      <div className="legend">
        <div className="row row1">
          <span className="legend-text col-10">Vous</span>
          <div className="legend-box col-1" style={{ backgroundColor: 'black' }}></div>
        </div>
        <div className="row">
          <span className="legend-text col-10">Moyenne francaise</span>
          <div className="legend-box col-1">
            {[...Array(8)].map((_, index) => (
              <div key={index} className="subdiv"></div>
            ))}
          </div>
        </div>
      </div>

      {data.map((item, index) => (
        <div className="Lab" key={index}>
          <div className="bar">
            <div className="Col" style={{ height: calculateHeight(numbers[item.valueKey] || 0), backgroundColor: item.color, borderColor: item.color }}>
              <span className="column-title">{(numbers[item.valueKey] !== undefined) ? item.titre : 'N/A'}</span>
            </div>
            <div className="ColAf">
              <span className="column-title">{(franceNumbers[item.valueKey] !== undefined) ? item.titre2 : 'N/A'}</span>
              <div className="Col-2" style={{ height: calculateHeight(franceNumbers[item.valueKey] || 0) }}>
                {[...Array(500)].map((_, subIndex) => (
                  <div key={subIndex} className="subdiv" style={{ backgroundColor: item.color }}></div>
                ))}
              </div>
            </div>
          </div>
          <label className="labelBar">{item.label}</label>
        </div>
      ))}
    </div>
  );
};

export default Chart;