import React from 'react';
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/Index";
import Graphique from "../../components/Bilan/index"
import CardPetiteDroite from '../../components/CardPetiteDroite';
import SousTitre from '../../components/SousTitre';
import ChiffreSousChiffre from '../../components/CardPetiteDroite/Chiffre';
import ListDeroule from '../../components/Form/ListDeroule';
import TitreH2 from '../../components/TitreH2/index';
import TitreBG from '../../components/TitreBG/index';
import image1 from "../../components/img/planet.png";
import image2 from "../../components/img/Trainbilan.png"
import image3 from "../../components/img/Livre.png"
import Ligne1 from '../../components/Bilan/Ligne1';
import Ligne2 from '../../components/Bilan/Ligne2';
import Bouton from '../../components/Form/Bouton';
import CardBilan from '../../components/Bilan/CardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import Band1 from '../../components/Bilan/Band1';
import Band2 from '../../components/Bilan/Band2';
import imagelabel from "../../components/img/label.png";
import { Label } from 'recharts';
import Ligne3 from '../../components/Bilan/Ligne3';
import Burger from '../../components/Bilan/burger';

const BilanPart3 = () => {
  const handleListDerouleChange = (selectedValue) => {
    // Logique de gestion du changement de valeur du ListDeroule
    console.log("ListDeroule value:", selectedValue);
  };

  return (
    <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">

            <div className="col-10">
                <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            
        </div>

        <div className="row">
            
            <div className="col-7">
                <h1 className = "h31">Répartition de vos émissions</h1>

                <SousTitre SousTitre="Mes émissions" />

                <p className = "p1">La contribution à la neutralité carbone mondiale (anciennement compensation carbone volontaire) est un mécanisme qui permet d’avoir un impact positif sur l’environnement, les communautés les plus vulnérables et la neutralité carbone en dehors de son périmètre d’activité, en participant au développement de projets favorisant la réduction ou la séquestration des émissions de gaz à effet de serre.
                <ul>
                  <li>Programme ACS</li>
                  <li>Mesurer &gt; Réduire &gt; contribuer</li>
                  <li>labellisé Gold Standard</li>
                </ul>
                </p>

            </div>



            <div className="col-5">
              <div className="row offset-1 align-items-center justify-content-center" style={{marginTop : "10vh"}}>
                <img src={imagelabel} alt="Image" style={{width : "30%"}}></img>
              </div>
              <div className="row offset-1" style={{marginTop : "3vh"}}>
                <p className = "p1">
                82% de ses ressources sont allouées à ses missions sociales (2021).
                <br/>La Fondation GoodPlanet est labellisée IDEAS pour sa gestion rigoureuse et transparente.

                </p>
              </div>
              <div className="row offset-1"  style={{marginTop : "3vh"}}>
                <button type="button" className="custom-button" style={{fontSize: "14px"}}>
                  Questions fréquentes CONTRIBUTION
                </button>
              </div>

            </div>

          </div>


          <div className="row" style={{marginTop : "5vh"}}>
              <SousTitre SousTitre="Votre contribution :" />
              <Ligne3 chiffre="6,57" unit="Tonnes de C02" chiffre1="134,57 €" unit1="pour financer des projets pour l’écologie"/>
          </div>

          <div className="row col-12" style={{marginTop : "5vh"}}>
            <div className='col-2'><Burger titre="Valorisation des ordures ménagères "/></div>
            <div className='col-2'><Burger titre="Valorisation des ordures ménagères "/></div>
            <div className='col-2'><Burger titre="Valorisation des ordures ménagères "/></div>

          </div>


        </div>
      <Footer />
    </div>
  );
};

export default BilanPart3;

