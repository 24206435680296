import React, { useState, useEffect, useCallback } from 'react';
import ListDeroule from '../ListDeroule';
import InputField from '../Input';
import Bouton from '../Bouton';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import Ajout from '../Ajout';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import LogoChauf from "../../img/ChauffageUrbain.png";
import Logogpl from "../../img/GPL.png";
import Logogaz from "../../img/gaz.png";
import LogoBoi from "../../img/bois.png";
import LogoFioul from "../../img/fioule.png";
import LogoPanneauSol from "../../img/panneauSol.png";
import LogoPaneauVolt from "../../img/panneauPhoto.png";
import LogoElect from "../../img/Elect.png";
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import CarreLogo from '../../CarreLogo/CarreLogo';
import CheckboxChoixUnique from '../checkBox/checkbox';
import Compteur from '../../Compteur/Compteur';
import "../Form Particulier/form.css"


function FormLieu({ onSubmit }) {
  const [locaux, setLocaux] = useState([
    {
      surface: '',
      climatiser: '',
      nbParticipants: '',
      duree: '',
      consommation: '',
    },
  ]);

  useEffect(() => {
    localStorage.setItem('locaux', JSON.stringify(locaux));
  }, [locaux]);

  const addLocal = useCallback(() => {
    setLocaux((prevLocaux) => [
      ...prevLocaux,
      {
        surface: '',
        climatiser: '',
        nbParticipants: '',
        duree: '',
        consommation: '',
      },
    ]);
  }, []);

  const handleChangeLocal = useCallback((index, field, value) => {
    setLocaux((prevLocaux) => {
      const updatedLocaux = [...prevLocaux];
      const local = updatedLocaux[index];
      if (local) {
        local[field] = value;
      }
      return updatedLocaux;
    });
  }, []);

  const handleDeleteLocal = (index) => {
    setLocaux((prevLocaux) => {
      const updatedLocaux = [...prevLocaux];
      updatedLocaux.splice(index, 1);
      return updatedLocaux;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(locaux);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row col-12 justify-content-center">
        <div className="SousTitre">
          <Titre
            Titre="Renseigner la surface du lieu occupé"
            place="start"
            color="#024C4A"
          />
        </div>
      </div>

      {locaux.map((local, index) => (
        <div className="duplique" key={index}>
          <div className="row col-12">
            <div className="SousTitre">
              <DeleteOutlinedIcon
                sx={{ color: '#024C4A' }}
                className="Icon"
                onClick={() => handleDeleteLocal(index)}
              />
              <div className="h61">Lieu {index + 1}</div>
            </div>
          </div>

          <div className="row col-12">
            <div className="col-12 col-lg-6">
              <Compteur
                titre="Surface du lieu"
                value={local.surface}
                onChange={(value) => handleChangeLocal(index, 'surface', value)}
                unit="m²"
              />
            </div>
          </div>

          <div className="row">
                <div className="col-12">
                <CheckboxChoixUnique
                    onChange={(value) => handleChangeLocal(index, 'climatiser', value)}
                    titre="L’événement est-il chauffé ou climatisé ?"
                    rep1="Oui"
                    rep2="Non"
                />
                </div>
            </div>


          {!local.climatiser ? ("") : (
            <div>
              <div className="row col-12 justify-content-center">
                <div className="col-12">
                  <CheckboxChoixUnique
                    titre="Connaissez-vous la consommation exacte pour la durée de l’évènement ?"
                    value={local.consommation}
                    onChange={(value) => handleChangeLocal(index, 'consommation', value)}
                    rep1="Oui"
                    rep2="Non"                  
                    />
                </div>
              </div>
          
              {local.consommation === "" ? ("") : local.consommation ? (
                <div className="row col-12 justify-content-center">
                <div className="row col-12">
                        <div className="col-lg col-md-6 col-12">
                        <CarreLogoInput2
                            logo={LogoChauf}
                            titregras="Chauffage urbain"
                            titre="Alimentation de plusieurs bâtiments par une chaleur provenant d’un seul site de production."
                        /> {/* Affiche un carré avec le logo du chauffage urbain, le titre "Chauffage urbain" et le sous-titre "Alimentation de plusieurs bâtiments par une chaleur provenant d’un seul site de production." */}
                        </div>
                        <div className="col-lg col-md-6 col-12">
                        <CarreLogoInput2
                            logo={Logogpl}
                            titregras="GPL"
                            titre="Chaudière GPL ou propane"
                            unit1='€'
                            unit='tonnes'
                        /> {/* Affiche un carré avec le logo du GPL, le titre "GPL", le sous-titre "Chaudière GPL ou propane" et les unités de mesure */}
                        </div>
                        <div className="col-lg col-md-6 col-12">
                        <CarreLogoInput2
                            logo={Logogaz}
                            titregras="Gaz"
                            titre="Definition"
                        /> {/* Affiche un carré avec le logo du gaz, le titre "Gaz" et le sous-titre "Definition" */}
                        </div>
                        <div className="col-lg col-md-6 col-12">
                        <CarreLogoInput2
                            logo={LogoBoi}
                            titregras="Bois Plaquettes forestières"
                            titre="Chauffage au poele ou avec une cheminée"
                            unit1='€'
                            unit='steres'
                        /> {/* Affiche un carré avec le logo du bois, le titre "Bois Plaquettes forestières", le sous-titre "Chauffage au poele ou avec une cheminée" et les unités de mesure */}
                        </div>
                        <div className="col-lg col-md-6 col-12">
                        <CarreLogoInput2
                            logo={LogoFioul}
                            titregras="Fioul"
                            titre="Definition"
                            unit1='€'
                            unit='L'
                        /> {/* Affiche un carré avec le logo du fioul, le titre "Fioul", le sous-titre "Definition" et les unités de mesure */}
                        </div>
                    </div>
                    <div className="row col-12">
                        <div className="col-lg col-md-6 col-12">
                        <CarreLogoInput2
                            logo={LogoElect}
                            titregras="Electricité"
                            titre="Contrat électricité"
                        /> {/* Affiche un carré avec le logo du chauffage urbain, le titre "Chauffage urbain" et le sous-titre "Alimentation de plusieurs bâtiments par une chaleur provenant d’un seul site de production." */}
                        </div>
                        <div className="col-lg col-md-6 col-12">
                        <CarreLogoInput2
                            logo={Logogpl}
                            titregras="Réseau froid"
                            titre="Clim"
                            unit1='€'
                            unit='kWh'
                        /> {/* Affiche un carré avec le logo du GPL, le titre "GPL", le sous-titre "Chaudière GPL ou propane" et les unités de mesure */}
                        </div>
                        <div className="col-lg col-md-6 col-12">
                
                        </div>
                        <div className="col-lg col-md-6 col-12">
                        
                        </div>
                        <div className="col-lg col-md-6 col-12">
                        
                        </div>
                    </div>
                </div>
              ) : (
                <div className="row col-12 justify-content-center">
                    <div>
                    <div className='row justify-content-center'>
                        <div className='col-lg col-md-6 col-12'>
                          <CarreLogo logo={LogoChauf}
                            titregras="Chauffage urbain"
                            titre="Alimentation de plusieurs bâtiments par une chaleur provenant d’un seul site de production."/></div>
                        <div className='col-lg col-md-6 col-12'><CarreLogo 
                          logo={Logogpl}
                        titregras="GPL"
                        titre="Chaudière GPL ou propane"
                        /></div>
                        <div className='col-lg col-md-6 col-12'><CarreLogo 
                          logo={Logogaz}
                          titregras="Gaz"
                          titre="Definition"
                        /></div>
                        <div className='col-lg col-md-6 col-12'><CarreLogo 
                          logo={LogoBoi}
                          titregras="Bois Plaquettes forestières"
                          titre="Chauffage au poele ou avec une cheminée"
                        /></div>
                        <div className='col-lg col-md-6 col-12'><CarreLogo 
                          logo={LogoFioul}
                          titregras="Fioul"
                          titre="Definition"
                        /></div>
                    </div>

                    <div className='row justify-content-center' style={{marginTop : "5vh"}}>
                        <div className='col-lg col-md-6 col-12'><CarreLogo 
                          logo={LogoElect}
                          titregras="Electricité"
                          titre="Contrat électricité"
                        /></div>
                        <div className='col-lg col-md-6 col-12'><CarreLogo 
                          logo={Logogpl}
                          titregras="Réseau froid"
                          titre="Clim"
                        /></div>
                        <div className='col-lg col-md-6 col-12'></div>
                        <div className='col-lg col-md-6 col-12'></div>
                        <div className='col-lg col-md-6 col-12'></div>
                    </div>
                    </div>
                </div>
              )}
            </div>
          )}
        
        <div>
              <div className="row col-12 justify-content-center">
                <div className="SousTitre">
                  <SousTitre
                    SousTitre="Renseigner le nombre de participants et le nombre de jour(s)"
                  />
                </div>
              </div>

              <div className="row col-12 justify-content-center">
                <div className="col-6">
                  <Compteur
                    titre="Nombre de participants"
                    value={local.nbParticipants}
                    onChange={(value) => handleChangeLocal(index, 'nbParticipants', value)}
                  />
                </div>
                <div className="col-6">
                  <Compteur
                    titre="Durée"
                    value={local.duree}
                    onChange={(value) => handleChangeLocal(index, 'duree', value)}
                    text="jour(s)"
                  />
                </div>
              </div>
            </div>
        </div>
      ))}

      <div className="row">
        <div className="col-12">
          <button type="button" className="custom-button" onClick={addLocal}>
            Ajouter un local
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Bouton titre="CALCULER" />
        </div>
      </div>
    </form>
  );
}

export default FormLieu;
