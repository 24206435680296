import React, { useState } from 'react';
import ListDeroule from '../ListDeroule';
import InputField from '../Input';
import Bouton from '../Bouton';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import '../Form Particulier/form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import "./Connection.css"
import InputMotPasse from '../Input/indexMP';
import CheckboxLabels from '../CheckBoxMulti';

function FormCreeCompte2() {
  const [civilite, setCivilite] = useState('');
  const [prenom, setPrenom] = useState('');
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [fonction, setFonction] = useState('');
  const [identifiant, setIdentifiant] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const [confirmerMotDePasse, setConfirmerMotDePasse] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [Alerte, setAlerte] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Vérification des champs remplis
    if (
      civilite &&
      prenom &&
      nom &&
      email &&
      fonction &&
      identifiant &&
      motDePasse &&
      confirmerMotDePasse
    ) {
      setAlerte(true);
      setFormSubmitted(true);
    } else {
      setAlerte(false);
      setFormSubmitted(false);
      console.log(Alerte);
    }
  };

  return (
    <form className='Connection'>
      <div className="row col-12 justify-content-center">
        <div className="SousTitre">
          <Titre
            Titre="Votre profil"
            place="center"
            color="#024C4A"
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <ListDeroule
            titre="Civilité"
            value={civilite}
            onChange={setCivilite}
            options={['Monsieur', 'Madame', 'Mademoiselle']} // Remplacez par les options réelles
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Prénom"
            value={prenom}
            onChange={setPrenom}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Nom"
            value={nom}
            onChange={setNom}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Email"
            value={email}
            onChange={setEmail}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Votre fonction"
            value={fonction}
            onChange={setFonction}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Identifiant"
            value={identifiant}
            onChange={setIdentifiant}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputMotPasse
            titre="Mot de passe"
            value={motDePasse}
            onChange={setMotDePasse}
            text="Mot de passe"
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputMotPasse
            titre="Confirmer le mot de passe"
            value={confirmerMotDePasse}
            onChange={setConfirmerMotDePasse}
            text="Confirmer le mot de passe"
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-6 col-md-6 col-12">
          <CheckboxLabels
            title="Gestion des données"
            labels={["J’accepte la politique de gestion des données de Good Planet ", "Je comprends que mes données anonymement seront utilisées à des fins statistiques"]}
          />
        </div>
      </div>

      {formSubmitted && (
        <div className="row">
          <div className="col-12">
            <p>Civilité: {civilite}</p>
            <p>Prénom: {prenom}</p>
            <p>Nom: {nom}</p>
            <p>Email: {email}</p>
            <p>Votre fonction: {fonction}</p>
            <p>Identifiant: {identifiant}</p>
            <p>Mot de passe: {motDePasse}</p>
            <p>Confirmer le mot de passe: {confirmerMotDePasse}</p>
          </div>
        </div>
      )}

      {!formSubmitted && !Alerte && (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                display:
                  civilite &&
                  prenom &&
                  nom &&
                  email &&
                  fonction &&
                  identifiant &&
                  motDePasse &&
                  confirmerMotDePasse
                    ? 'none'
                    : 'block'
              }}
            >
              Veuillez remplir tous les champs !
            </div>
          </div>
        </div>
      )}

      {!formSubmitted && (
        <div className="row">
          <div className="col-12">
            <Bouton
              type="submit"
              titre="Suivant"
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </form>
  );
}

export default FormCreeCompte2;
