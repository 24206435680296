import React from 'react';
import Titre from "../Titre";
import "./ContenueLogo.css"

const ContenueLogoText = ({Logo, Titre, text}) => {
  return (
    <div className="containerLogo">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-10">            
         <h2 className='h21 Avion'>{Titre}</h2>
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-3 justify-content-center align-items-center"><img src={Logo} className="ImageAvion" alt="Image"/></div>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-8 ">            
         <p3 className="p3">{text}</p3>
        </div>
      </div>
    </div>
    </div>
  );
}

export default ContenueLogoText;
