import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import FormTrain from '../../../components/Form/Form Particulier/FormTrain';
import FormPlacement from '../../../components/Form/Form Particulier/FormPlacement';

const PagePlacement1 = () => {
  const navigate = useNavigate();


  return (
    <>
    <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>
      <Stepperline/>

        <div className="container">
          <div className="row">
          <div className="row col-10 offset-1">
                <Titre Titre="Placements financiers"  place="start"/>
            </div>
          </div>
          <div className="row col-10 offset-1">
            <FormPlacement/>
          </div>

                
          <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Estimez l'empreinte carbone de votre épargne bancaire</div>
        <p>Votre épargne est utilisée par votre banque pour des prêts à des industries ou des projets par le biais de portefeuilles ou de fonds d’investissement. En décidant de placer votre épargne dans tel ou tel établissement bancaire, vous contribuez ainsi indirectement au financement d’industries responsables du dérèglement climatique.</p>
        <p>La transparence de l’impact climatique de ces prêts/investissements n’est pas totale mais est en progression. Pour l’heure, nous avons fait le choix de nous appuyer sur un rapport réalisé par Oxfam en octobre 2020 afin de vous fournir une première estimation de l’impact de votre épargne en fonction de votre banque. <a href="https://www.oxfamfrance.org/rapportbanque-oxfam-v5.pdf" target="_blank" rel="noopener noreferrer">Consulter le rapport OXFAM</a>.</p>
        
        <div className='h42'>Mode de calcul :</div>
        <p>Argent détenu dans la banque (compte courant + compte épargne) * intensité carbone de la banque</p>
    </div>
} />

            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-8">
              <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
          </div>

        </div>
      </div>
    </div>
    </>
  );
};

export default PagePlacement1;

