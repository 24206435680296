import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoPart from "../components/img/particulier.png";
import LogoEntreprise from "../components/img/entreprise.png";
import LogoVoyage from "../components/img/voyage.png";
import LogoEvent from "../components/img/event.png";
import LogoContribu from "../components/img/LogoContribue.png";
import Image15 from "../components/img/15deg.png";
import ImageMath from "../components/img/Math.png";
import Header from '../components/Header';
import TitreBG from '../components/TitreBG';
import Footer from '../components/Footer/Index';
import "./Particulier/Avion.css"
import Band3 from '../components/Bilan/Band3';
import Ligne2 from '../components/Bilan/Ligne2';
import TitreH2 from '../components/TitreH2';
import { useUUID } from '../components/UUIDContext';
import { v4 as uuidv4 } from 'uuid';
import MyComponent from '../components/Api/BoutonAppel';
// Import fetchToken from the correct file path
import { useAuth } from '../components/Authentification/Auth';

const Home = () => {
  const navigate = useNavigate();
  const { setUUID } = useUUID();


  const handleButton = (type) => {
    if (type === 'particulier') {
      const newUUID = uuidv4();
      console.log(newUUID);
      setUUID(newUUID);
    }
    navigate(`/${type}`);
  };


  return (
    <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>

        <div className='container row'>

        <div className='row col-lg-6 col-12'>
          <div className='Carre col-12 row'>
              <div className='h61 textTitre col-12'>Bilan annuel</div>
              <div className='trai-noir col-3'></div>
          </div>

              <div className='row col-lg-6 col-12 justify-content-center'>             
              <div className='carrelogo col-10' style={{marginTop : "2vh", marginBottom : "2vh", backgroundColor : "rgba(235, 224, 133, 0.5)"}} onClick={() => handleButton('particulier')}>
                  <div className='GrasCarre'>PARTICULIER</div>
                  <div className='LogoCarre'>
                    <img src={LogoPart} alt='Image' />
                  </div>
              </div>
              </div>

              <div className='row col-lg-6 col-12 justify-content-center'>             
              <div className='carrelogo col-10' style={{marginTop : "2vh" , marginBottom : "2vh", backgroundColor : "rgba(104, 196, 165, 0.5)"}} onClick={() => handleButton('entreprise')}>
                  <div className='GrasCarre'>ENTREPRISE OU ASSOCIATION</div>
                  <div className='LogoCarre'>
                    <img src={LogoEntreprise} alt='Image' />
                  </div>
              </div>
              </div>
        </div>



        <div className='row col-lg-6 col-12'>
          <div className='Carre col-12 row'>
              <div className='h61 textTitre col-12'>Bilan ponctuel</div>
              <div className='trai-noir col-3'></div>
          </div>

              <div className='row col-lg-6 col-12 justify-content-center'>             
              <div className='carrelogo col-10' style={{marginTop : "2vh" , marginBottom : "2vh", backgroundColor : "rgba(251, 171, 59, 0.75)"}} onClick={() => handleButton('Voyage/Avion')}>
                  <div className='GrasCarre'>VOYAGE</div>
                  <div className='LogoCarre'>
                    <img src={LogoVoyage} alt='Image' />
                  </div>
              </div>
              </div>

              <div className='row col-lg-6 col-12 justify-content-center'>             
              <div className='carrelogo col-10' style={{marginTop : "2vh" , marginBottom : "2vh", backgroundColor : "rgba(112, 198, 236, 0.5)"}} onClick={() => handleButton('Evenement/General')}>
                  <div className='GrasCarre'>événements</div>
                  <div className='LogoCarre'>
                    <img src={LogoEvent} alt='Image' />
                  </div>
              </div>
              </div>
        </div>

        <div>
          <div className='row col-12 align-items-center justify-content-center'>
              {/* <MyComponent/> */}
              <Band3 text = {"Je connais déjà le montant correspondant à mes émissions de gaz à effet de serre. "} />
          
          <div className='row col-12'>
            <p className='p1'>Différents gaz, dont le dioxyde de carbone (CO2), sont responsables de ce que l’on appelle l’effet de serre. Il s’agit d’un phénomène naturel et nécessaire à notre existence. Sans cela, il ferait -18°C à la surface du globe et toute vie humaine serait impossible ! Néanmoins, les activités humaines participent à l’augmentation rapide de ces émissions de gaz à effet de serre qui provoque les changements climatiques actuels. Aussi il est primordial de tous prendre en compte l’impact de nos activités sur le climat, et d’agir en conséquence en essayant de réduire notre empreinte carbone.</p>
            <p className='p1'>Les calculateurs proposés vous permettent, en peu de temps, d’estimer l’impact sur le climat de votre activité. Ces calculateurs s’appuient sur la méthodologie Bilan Carbone® développée par l’ADEME et aujourd’hui diffusée par l’Association Bilan Carbone (A.B.C.). Ils se veulent avant tout des outils de prise de conscience. Il ne s’agit pas d’un bilan exhaustif mais d’un diagnostic sommaire ciblé sur les principaux postes d’émissions des activités du secteur du spectacle et de l’événement.</p>
          </div>

         <div className='row col-lg-10 col-12'>
            <div className='col-lg-6 col-12'><Ligne2 image={Image15} text="Tout pour comprendre la neutralité carbone" /></div>
            <div className='col-lg-6 col-12'><Ligne2 image={ImageMath} text="Découvrir la méthodologie du Calculateur carbone " /></div>
          </div>

          <div className='row col-12'>
            <div className='p1'>Les illustrations du calculateurs proviennent de Iconscout. </div>
          </div>
          </div>

          <div className='TitreBG'>
            <TitreBG Titre="Ils contribuent avec nous" className="titre"/>
            </div>
          <div className="row">
                <div className="col-lg-10 col-12 offset-lg-1" style={{marginTop : '10vh', marginBottom: '5vh'}}>
                <TitreH2 Titre="Ils contribuent avec nous" className="titre" couleur="#000000"/>
            </div>
          </div>

          <div className='row col-12'>
            <div className='p1'>Ils contribuent à la neutralité carbone avec la Fondation GoodPlanet :</div>
          </div>

          <div className='row col-12' style={{marginTop : '2vh' , marginBottom : "2vh"}}>
            <img src={LogoContribu} alt="Logo Contribue" />
          </div>

        </div>

        </div>



          <div className="row justify-content-center">
            <div className="col-8">
              <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
          </div>
      
      </div>
    </div>
    
    // <div>
    //   <div className='contenaire row'>
    //       <div className='col-lg-6 col-12'>
    //         <div className='row col-12'>
    //           <div className='col-5' style={{marginTop : "2vh" , marginBottom : "2vh", backgroundColor : "#EBE085B2"}}>
    //             <div className='row justify-content-center align-items-center'>
    //               <div className='row text-center'><h6 className='h61'>PARTICULIER</h6></div>
    //               <div className='row col-lg-6 col-12 justify-content-center'><img src={LogoPart}/></div>    
    //             </div>
    //           </div>
    //           <div className='col-5'></div>
    //         </div>
    //       </div>
    //       <div className='col-lg-6 col-12'>
    //         <div className='row col-12'>
    //           <div className='col-5'></div>
    //           <div className='col-5'></div>
    //         </div>
    //       </div>
    //   </div>
    //   <h1>Page d'accueil</h1>
    //   <button onClick={() => handleButton('particulier')}>Particulier</button>
    //   <button onClick={() => handleButton('evenement')}>Événement</button>
    //   <button onClick={() => handleButton('entreprise')}>Entreprise</button>
    // </div>
  );
};

export default Home;

