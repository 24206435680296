import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoTelephonePortable from '../../img/tel.png';
import LogoEcranTV from '../../img/ecran.png';
import LogoSystemeAudio from '../../img/audio.png';
import LogoPostsRS from '../../img/reseau.png';
import LogoEmail from '../../img/mail.png';
import LogoTempsVisio from '../../img/visioconf.png';
import LogoDureeStockage from '../../img/Stockage.png';
import Titre from '../../Titre';
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import Bouton from '../Bouton';
import BilanInter from '../../BilanInter';
import Compteur from '../../Compteur/Compteur';
import CheckboxChoixUnique from '../checkBox/checkbox';


function FormNumerique() {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false); // nouvel état pour suivre la soumission du formulaire
  const [numeriqueValues, setNumeriqueValues] = useState({
    telephonePortable: 0,
    ecranTV: 0,
    systemeAudio: 0,
    postsRS: 0,
    emails: 0,
    tempsVisio: 0,
    dureeStockage: 0,
  });
  const [NbOrdi, setNbOrdi] = useState(false); // nouvel état pour suivre la soumission du formulaire
  const [Duree, setDuree] = useState(false); // nouvel état pour suivre la soumission du formulaire
  const [Inscription, setInscription] = useState(false); // nouvel état pour suivre la soumission du formulaire

  
  const handleValeurChange = (nom, valeur) => {
    setNumeriqueValues({ ...numeriqueValues, [nom]: valeur });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true); // indique que le formulaire a été soumis
    console.log(formSubmitted);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate("/Voiture-11");
  };

  return (
    <form onSubmit={handleSubmit}>
          <div className='h61 vert'>Matériel</div>

            <div className="row col-12 justify-content-center">
              <div className="col-12 col-lg-6">
                <Compteur
                  titre="Nombre d’ordinateurs utilisés : "
                  unit="ordinateurs"
                  value={NbOrdi}
                  onChange={(value) => setNbOrdi(value)}
                />
              </div>
              <div className="col-12 col-lg-6">
                <Compteur
                  titre="pendant une durée totale de"
                  unit="Heure(s)"
                  value={Duree}
                  onChange={(value) => setDuree(value)}
                  text="jour(s)"
                  italique={true}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg col-md-6 col-12">
                <CarreLogoInput2
                  logo={LogoTelephonePortable}
                  titre="Nombre de barres de son, enceinte"
                  titregras={'Téléphone portable'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('telephonePortable', valeur)
                  }
                  unit=""
                  nombre={1}
                />
              </div>
              <div className="col-lg col-md-6 col-12">
                <CarreLogoInput2
                  logo={LogoEcranTV}
                  titre="Nombre d'écrans de télévision"
                  titregras={'Ecran TV'}
                  onValeurChange={(valeur) => handleValeurChange('ecranTV', valeur)}
                  unit=""
                  nombre={1}       />
              </div>
              <div className="col-lg col-md-6 col-12">
                <CarreLogoInput2
                  logo={LogoSystemeAudio}
                  titre="Nombre de téléphone"
                  titregras={'Système audio'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('systemeAudio', valeur)
                  }
                  unit=""
                  nombre={1}            />
              </div>
            </div>

            <div className='h61 vert' style={{marginTop : "10vh"}}>Communication</div>

            <div className="row">
              <div className="col-lg col-md-6 col-12">
                <CarreLogoInput2
                  logo={LogoPostsRS}
                  titre="Nombre de posts sur les réseaux sociaux"
                  titregras={'Posts sur les RS'}
                  onValeurChange={(valeur) => handleValeurChange('postsRS', valeur)}
                  unit="Post(s)"
                  nombre={1}       />
              </div>
              <div className="col-lg col-md-6 col-12">
                <CarreLogoInput2
                  logo={LogoEmail}
                  titre="Nombre d'email envoyés"
                  titregras={'Email'}
                  onValeurChange={(valeur) => handleValeurChange('emails', valeur)}
                  unit="Email(s)"
                  nombre={1}      />
              </div>
              <div className='col'></div>
            </div>

            <div className='h61 vert' style={{marginTop : "10vh"}}>Visioconférence ou retransmission de l’événement</div>

            <div className="row">
              <div className="col-lg col-md-6 col-12">
                <CarreLogoInput2
                  logo={LogoTempsVisio}
                  titre="Temps passé en vidéo"
                  titregras={'Temps passé en visio'}
                  onValeurChange={(valeur) => handleValeurChange('tempsVisio', valeur)}
                  unit="Heure(s)"
                  unit1='Personne(s)'
                  nombre={2}          />
              </div>
              <div className="col-lg col-md-6 col-12">
                <CarreLogoInput2
                  logo={LogoDureeStockage}
                  titre="Durée de stockage des enregistrements post-événement"
                  titregras={'Durée de stockage'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('dureeStockage', valeur)
                  }
                  unit="Jours"
                  nombre={1}             />
              </div>
              <div className='col'></div>
            </div>

            <div className='h61 vert' style={{marginTop : "10vh"}}>Autre</div>
            <div className="row">
                <div className="col-12">
                <CheckboxChoixUnique
                    onChange={(value) => setInscription(value)}
                    titre="Utilisez-vous une plateforme d’inscription ?"
                    rep1="Oui"
                    rep2="Non"
                />
                </div>
            </div>

      <div className="row">
        <div className="col-12">
          <Bouton titre="CALCULER" />
        </div>
      </div>
  
      {formSubmitted && ( 
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInter numeroEtape={2} voyage="non" />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick}/>
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default FormNumerique;
