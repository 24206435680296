import React from 'react';
import './Graphique.css';

const Ligne1 = ({logo, titre, titregras}) => {
  // Données pour les éléments du graphique
  
  return (
      <div className='Ligne1'>
          <div className='titre'>{titre}</div>
          <div className='Gras'>{titregras}</div>
      </div>
  );
};

export default Ligne1;
