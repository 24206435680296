import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import Compteur from '../../../components/Compteur/Compteur';
import CheckboxChoixUnique from '../../../components/Form/checkBox/checkbox';
import CheckboxChoixUniqueAdapt from '../../../components/Form/checkBox/checkbox-adapt';
import imageEnergie from "../../../components/img/Energie.png"
import { Padding } from '@mui/icons-material';
import Bouton from '../../../components/Form/Bouton';
import FormDomicile from '../../../components/Form/Form Particulier/FormDomicile';
import FormLieu from '../../../components/Form/Form Event/FormLocal';
import FormRepas from '../../../components/Form/Form Event/FormRepas';
import FormLogistique from '../../../components/Form/Form Event/FormLogistique';
import StepperLineEvent from '../../../components/StepperLine/index-event';

const Logistique = () => {
  const navigate = useNavigate();
  const [consommationConnue, setConsommationConnue] = useState(false);
  const reponses = ['≤5  A', '6 à 10  B', '11 à 20 C', '21 à 35 D', '36 à 55 E', '56 à 80 F', '> 80 G'];



  return (
    <>
    <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>
      <StepperLineEvent/>

        <div className="container">
          <div className="row">
          <div className="row col-10 offset-1">
                <Titre Titre="Logistique matérielle"  place="start"/>
            </div>
          </div>

          <div className="row col-10 offset-1">
          <FormLogistique/>
          </div>
          
                
          <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Matériel, Fret et Déchets</div>
        <p>De nombreuses ressources sont nécessaires pour un événement. Elles se traduisent par du matériel, des matières premières, des produits ou même des services. Bien souvent, le matériel est amené en camion puis récupéré ou jeté à la fin de l’événement. Dans cette catégorie, les émissions liées au matériel et aux services, le fret avant et après l’événement, ainsi que la fin de vie du matériel sont mesurées.</p>
        <p><strong>Biens et services :</strong> Dans le cas où les besoins sont matériels, nous utilisons des facteurs d’émission en fonction du poids ou du volume de matière. Dans le cas où il s’agit de services, nous évaluons l’empreinte carbone grâce à des ratios monétaires.</p>
        <p><strong>Fret :</strong> Les facteurs d’émissions dépendent du mode de transport utilisé. Pour les transports routiers, nous considérons que les véhicules circulent majoritairement au diesel. Sont pris en compte la combustion et la phase amont pour les carburants ainsi que la fabrication pour le transport routier.</p>
        <p>Ici, vous devez indiquer le nombre de tonnes x kilomètres de votre marchandise. A savoir, le nombre de tonnes ayant parcouru un certain nombre de kilomètres. Vous devez additionner ce nombre de tonnes x kilomètres associées. Par exemple, si vous exportez 3 tonnes de marchandises chaque mois sur 200 km et importez 2 tonnes de matières premières par an sur 500 km en camionnette, le calcul est le suivant : (3 x 200 x 12) + (2 x 500) t.km.</p>
        <p><strong>Déchets :</strong> Les facteurs d’émissions proposés sont des facteurs d’émission « fin de vie moyenne filière » à l’échelle de chaque catégorie de déchets ménagers.</p>
        <p>Dans cette catégorie, nous vous demandons d’évaluer la quantité de déchets pour chaque matériau et d’indiquer la proportion mise au recyclage. Pour information, le recyclage ne permet pas de directement réduire son empreinte carbone mais de transformer et de réutiliser des matières premières, comme le plastique, et d’éviter d’en extraire de nouvelles pour la production. Nous parlerons ainsi d’émissions évitées par le recyclage.</p>
    </div>
} />

            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-8">
              <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
          </div>

        </div>
      </div>
    </div>
    </>
  );
};

export default Logistique;

