import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';

const AppelAPITC2 = ({compteur,setLancer, Lancer, setApiResponse}) => {
  const { token, fetchToken } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    console.log(token)
    if (token && Lancer) { 
       fetchData();
     }


  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

   const fetchData = async () => {
     setLoading(true);
    const apiUrl = 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_PautreTransport2_Global';
    
    const data = {
      IdBC: "b2c25702-f76c-ee11-8178-000d3a951a7d",
      IdPage: "PAutreTransport2",
      veloElectrique: compteur.veloElectrique,
      trotinetteElectrique: compteur.trotinetteElectique
    };

    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const result = await axios.post(apiUrl, data, options);
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(result.data);
       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default AppelAPITC2;
