import React from 'react';
import './Graphique.css';
import Bouton from '../Form/Bouton';
import CarreBilan from './CarreBilan';

const ContenueCardBilan = ({logo, titre, titregras}) => {
  // Données pour les éléments du graphique
  
  return (
      <div className='ContenueCardBilan row'>
          <div className='col-8'>
              <p className='p1'  style={{marginBottom : "3vh", marginTop : "3vh"}}>
              La compensation carbone consiste à absorber ses propres émissions de CO₂ par le biais de la participations à des projets de réduction ou de capture et de séquestration de carbone afin de rendre son bilan neutre. 
                <br/>
              <br/>
                Les fonds de la compensation carbone permettent le financement des 3 projets suivants :
                <ul>
                <li>Valorisation d’ordures ménagères au Cameroun, au Togo et à Madagascar</li>
                <li>Diffusion du biogaz en Inde, dans l’État du Karnataka</li>
                <li>Diffusion de cuiseurs solaires au Pérou et en Bolivie</li>
                </ul>
                </p>
              <button type="button" className="custom-button">
                  EN savoir plus sur les projets et la contribution
              </button>
          </div>
          <div className='col-4'>
              <CarreBilan/>
              <Bouton titre = "JE CONTRIBUE pour 48€"/>
          </div>
          
      </div>
  );
};

export default ContenueCardBilan;
