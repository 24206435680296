import React, { useState } from 'react';
import "./Input.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
  
  function InputMotPasse(props) {
    const { titre, value, onChange, text } = props;
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
  
    const handleInputChange = (e) => {
      onChange(e.target.value);
      localStorage.setItem(titre, e.target.value);
    };
  
    const handleInputFocus = () => {
      setIsFocused(true);
    };
  
    const handleInputBlur = () => {
      setIsFocused(false);
    };
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  
    return (
      <div className="row">
        <div className={`col-25 ${isFocused || value ? 'label-up' : ''}`}>
          <label className='h61'>{titre}</label>
        </div>
        <div className="col-75">
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder={text}
              value={value || ''}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
            <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
              <span className="password-toggle-icon">{showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  export default InputMotPasse;
