import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import ImageVoiture from "../../../components/img/voiture.png";
import StepperLine from '../../../components/StepperLine/index-test'
import CardInput from '../../../components/Form/Form Particulier/FormTC2';
import Ensavoir from '../../../components/Ensavoir';
import StepperLineVoyage from '../../../components/StepperLine/index-voyage';

const FormTC3Voyage = () => {
  const navigate = useNavigate();

  return (
    <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>
      <StepperLineVoyage/>

        <div className="container">
        <div className="row">
            <div className="col-10 offset-1">
                <TitreH2 Titre="Calculez vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
          </div>


          <div className="row justify-content-center">
          <div className="row col-10 offset-1">
              <CardInput/>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Émissions liées aux transports en commun</div>
        <p>Les émissions prises en compte pour les transports en commun routiers (bus) prennent en compte le carburant (amont et combustion) ainsi que la fabrication du véhicule.</p>
        
        <p>Pour les bus urbains et interurbains, on distingue trois classes d’agglomérations :</p>
        <ul>
            <li>Classe 1 : agglomérations de plus de 250 000 habitants</li>
            <li>Classe 2 : agglomérations de 150 000 à 250 000 habitants</li>
            <li>Classe 3 : agglomérations de moins de 150 000 habitants</li>
        </ul>
        
        <p>Pour les modes ferrés (Métro, RER, Tram), ce sont les émissions liées au mode d'alimentation énergétique du réseau électrique (les émissions amont), ainsi que la fabrication qui sont prises en compte. La quasi-totalité des métros circulants dans les grandes agglomérations, c’est le facteur d’émission correspondant aux agglomérations de classe 1 qui a été choisi.</p>
        <p>Quant au tram, il y a presque autant de réseaux de tramway dans les agglomérations de classe 1 que dans les agglomérations de classe 2. C’est pourquoi, nous avons choisi de prendre une moyenne des facteurs d’émissions de ces deux types d’agglomérations. Le RER et Transiliens, ne circulant qu’en Ile-de-France, disposent de leur propre facteur d’émission.</p>
        
        <p>Pour le train, le facteur d’émission choisi est une moyenne entre celui du TGV, du TER et des Trains grandes lignes.</p>
    </div>
} />

            </div>
          </div>


          <div className="row justify-content-center">
            <div className="col-8">
              <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormTC3Voyage;

