import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import ImageVoiture from "../../../components/img/LogoVoiture.png";
import StepperLine from '../../../components/StepperLine/index-test';
import voiture from "../../../components/img/voiture.png";
import moto from "../../../components/img/moto.png";
import It from "../../../components/img/It.png";
import non from "../../../components/img/imageLogoNon.png";
import StepperLineVoyage from '../../../components/StepperLine/index-voyage';


const FormVoiture11Voyage = () => {
  const navigate = useNavigate();
  const [petiteCards, setPetiteCards] = useState([
    { label: "J’AI UNE VOITURE", imageName: voiture, lien:'/Voyage/Voiture-2' },
    { label: "J’AI UNE MOTO", imageName: moto, lien:'/Voyage/Moto'  },
    { label: "Ponctuellement Taxi, VTC, location", imageName: It, lien:'/Voyage/VTC' },
    { label: "JAMAIS", imageName: non, lien:'/Voyage/Train' }
  ]);
  const [cardContenu, setCardContenu] = useState(<ContenueBouton petiteCards={petiteCards} setPetiteCards={setPetiteCards} TitreP="Voyage en voiture/2 roues" SousTitreP="Utilisez-vous un véhicule motorisé ?" lien="/Voyage/Train"/>);


  const ajouterPetiteCard = (label, imageName) => {
    const nouvellePetiteCard = { label, imageName };
    setPetiteCards([...petiteCards, nouvellePetiteCard]);
  }



  return (
    <>   
    <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>
      <StepperLineVoyage/>

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">
            <div className="col-10 offset-1">
                <TitreH2 Titre="Calculez vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10">
              
              <Card contenu={cardContenu} color="#024C4A"/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-8">
              <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormVoiture11Voyage;

