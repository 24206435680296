import React from 'react';
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/Index";
import Graphique from "../../components/Bilan/index"
import CardPetiteDroite from '../../components/CardPetiteDroite';
import SousTitre from '../../components/SousTitre';
import ChiffreSousChiffre from '../../components/CardPetiteDroite/Chiffre';
import ListDeroule from '../../components/Form/ListDeroule';
import TitreH2 from '../../components/TitreH2/index';
import TitreBG from '../../components/TitreBG/index';
import image1 from "../../components/img/planet.png";
import image2 from "../../components/img/Trainbilan.png"
import logolegume from "../../components/img/Logolegume.png"
import logoAvion from "../../components/img/LogoAvion.png"
import Ligne1 from '../../components/Bilan/Ligne1';
import Ligne2 from '../../components/Bilan/Ligne2';
import Bouton from '../../components/Form/Bouton';
import CardBilan from '../../components/Bilan/CardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import Band1 from '../../components/Bilan/Band1';
import Band2 from '../../components/Bilan/Band2';
import CarreLogo from '../../components/CarreLogo/CarreLogo';

import train from "../../components/img/train.png";
import voiture from "../../components/img/voiture.png";
import vetement from "../../components/img/vetement.png";
import vege from  "../../components/img/vege.png";
import mail from "../../components/img/mail.png";
import tel from "../../components/img/tel.png";
import heberge from "../../components/img/heberge.png";
import ecran from "../../components/img/ecran.png";
import thermometre from "../../components/img/thermometre.png";
import economie from "../../components/img/economie-denergie.png";
import gaz from "../../components/img/ChauffageUrbain.png";

const BilanPart2 = () => {
  const handleListDerouleChange = (selectedValue) => {
    // Logique de gestion du changement de valeur du ListDeroule
    console.log("ListDeroule value:", selectedValue);
  };

  return (
    <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>

      <div className='col-10 offset-1'>
        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">

            <div className="col-10">
                <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            
        </div>

        <div className="row">
            
            <div className="col-9">
                <h1 className = "h31">Répartition de vos émissions</h1>

                <SousTitre SousTitre="Mes émissions" />

                <p className = "p1">A vos émissions annuelles, 1,1 tCO2 s’ajoute liées au fonctionnement de nos services publics. </p>

            </div>
          
          </div>


          <div className='row justify-content-center' style={{marginTop : "5vh"}}>
              <div className='col' ><CarreLogo titre="Refuser un aller-retour intercontinental en avion" titregras="-3,8 tCO2 par an" logo={logoAvion}/></div>
              <div className='col'><CarreLogo titre="Prendre le train au lieu de l'avion vers un pays limitrophe" titregras="-0,37 tCO2 par an" logo={train}/></div>
              <div className='col'><CarreLogo titre="Se passer de voiture pour les trajets < 5 km " titregras="-0,5 tCO2 par an" logo={voiture}/></div>
              <div className='col'><CarreLogo titre="Appliquer l'écoconduite" titregras="-0,04 tCO2 par an	" logo={voiture}/></div>
              <div className='col'><CarreLogo titre="Passer d'un régime 'classique' à flexitarien" titregras="-0,53 tCO2 par an	" logo={logolegume}/></div>
          </div>

          <div className='row justify-content-center' style={{marginTop : "5vh"}}>
              <div className='col'><CarreLogo titre="Passer d'un régime 'classique' à végétarien" titregras="-0,8 tCO2 par an" logo={vege}/></div>
              <div className='col'><CarreLogo titre="Passer d'un régime flexitarien à végétalien	" titregras="-0,48 tCO2 par an" logo={logolegume}/></div>
              <div className='col'><CarreLogo titre="Rénover son logement chauffé au gaz (classe D à B)" titregras="-1,8 tCO2 par an " logo={gaz}/></div>
              <div className='col'><CarreLogo titre="Baisser de 1°C la température des pièces" titregras="-0,3 tCO2 par an" logo={thermometre}/></div>
              <div className='col'><CarreLogo titre="Débrancher les appareils en veille" titregras="-0,05 tCO2 par an	" logo={economie}/></div>
          </div>

          <div className='row justify-content-center' style={{marginTop : "5vh"}}>
              <div className='col'><CarreLogo titre="Acheter des vêtements d'occasion à la place de neufs" titregras="-0,05 tCO2 par an " logo={vetement}/></div>
              <div className='col'><CarreLogo titre="Acheter un ordinateur portable reconditionné à la place d’un neuf" titregras="-0,027 tCO2 par an" logo={ecran}/></div>
              <div className='col'><CarreLogo titre="Allonger de 2 ans la durée de vie d’un Smartphone" titregras="-0,016 tCO2 par an " logo={tel}/></div>
              <div className='col'><CarreLogo titre="Diviser par deux le visionnage de vidéos en streaming" titregras="-0,082 tCO2 par an 	" logo={heberge}/></div>
              <div className='col'><CarreLogo titre="Supprimer 10 000 mails" titregras="-0,002 tCO2 par an" logo={mail}/></div>
          </div>


          <div className='' style={{marginTop : "5vh"}}>
              <Band1/>
          </div>

          <div className='' style={{marginTop : "5vh"}}>
              <Band2/>
          </div>

          
          <div className='row col-12' style={{marginTop : "5vh"}}>
              <CardBilan/>
          </div>

        </div>
      <Footer />
      </div>
      </div>
  );
};

export default BilanPart2;

