import React, { useState, useEffect, useCallback } from 'react';
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/Index";
import Graphique from "../../components/Bilan/index"
import CardPetiteDroite from '../../components/CardPetiteDroite';
import SousTitre from '../../components/SousTitre';
import ChiffreSousChiffre from '../../components/CardPetiteDroite/Chiffre';
import ListDeroule from '../../components/Form/ListDeroule';
import TitreH2 from '../../components/TitreH2/index';
import TitreBG from '../../components/TitreBG/index';
import image1 from "../../components/img/planet.png";
import image2 from "../../components/img/Trainbilan.png"
import logolegume from "../../components/img/Logolegume.png"
import logoAvion from "../../components/img/LogoAvion.png"
import Ligne1 from '../../components/Bilan/Ligne1';
import Ligne2 from '../../components/Bilan/Ligne2';
import Bouton from '../../components/Form/Bouton';
import CardBilan from '../../components/Bilan/CardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import Band1 from '../../components/Bilan/Band1';
import Band2 from '../../components/Bilan/Band2';
import CarreLogo from '../../components/CarreLogo/CarreLogo';
import Ligne3 from '../../components/Bilan/Ligne3';
import BilanFinal from '../../components/BilanInter/final';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const BilanPart5 = () => {
  const handleListDerouleChange = (selectedValue) => {
    // Logique de gestion du changement de valeur du ListDeroule
    console.log("ListDeroule value:", selectedValue);
  };

  const [isOpen, setIsOpen] = useState(true);
  const [isOpenGeste, setIsOpenGeste] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdownGeste = () => {
    setIsOpenGeste(!isOpenGeste);
  };

  return (
    <div className='page'>   
      <div className="Header">
        <Header />
      </div>
      
      <div className='container-bas container'>

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>

        <div className="row">

            <div className="col-10">
                <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            
        </div>

          <div className="row" style={{marginTop : "5vh"}}>
              <SousTitre SousTitre="Votre contribution :" />
              <Ligne3 chiffre="6,57" unit="Tonnes de C02" chiffre1="134,57 €" unit1="pour financer des projets pour l’écologie"/>
          </div>



          <div className="col-12 row" style={{marginTop : "4vh"}}>
                <h1 className = "h31">Contribuer pour le climat </h1>
                <p className = "p1">La contribution à la neutralité carbone mondiale (anciennement compensation carbone volontaire) est un mécanisme qui permet d’avoir un impact positif sur l’environnement, les communautés les plus vulnérables et la neutralité carbone en dehors de son périmètre d’activité, en participant au développement de projets favorisant la réduction ou la séquestration des émissions de gaz à effet de serre.
                <ul>
                  <li>Programme ACS</li>
                  <li>Mesurer &gt; Réduire &gt; contribuer</li>
                  <li>labellisé Gold Standard</li>
                </ul>
                </p>
            </div>

          <div className="row">
            <div className="col-7" style={{marginBottom : "3vh", cursor : "pointer"}} onClick={() => toggleDropdown()}>
                <h1 className = "h31">Le bilan détaillé de mes émissions </h1>
            </div>
            <div className='col-5' style={{marginTop : "5px", cursor : "pointer"}} onClick={() => toggleDropdown()}>
            {isOpen ? 
              <ExpandLessIcon
                sx={{ color: '#000000' }}
                className="Icon"
                onClick={() => toggleDropdown()}
                fontSize="large"
              /> :
              <ExpandMoreIcon
                sx={{ color: '#000000' }}
                className="Icon"
                onClick={() => toggleDropdown()}
                fontSize="large"
              />}
              </div>
          </div>

          {isOpen && (
          <div className="row">
              <BilanFinal numeroEtape={6} voyage="non" />
          </div>)}

          <div className="row">
            <div className="col-8" style={{marginBottom : "3vh", cursor : "pointer"}} onClick={() => toggleDropdownGeste()}>
                <h1 className = "h31">Choisir ses éco gestes pour l’environnement : </h1>
            </div>
            <div className='col-4' style={{marginTop : "5px", cursor : "pointer"}} onClick={() => toggleDropdownGeste()}>
            {isOpenGeste ? 
              <ExpandLessIcon
                sx={{ color: '#000000' }}
                className="Icon"
                onClick={() => toggleDropdownGeste()}
                fontSize="large"
              /> :
              <ExpandMoreIcon
                sx={{ color: '#000000' }}
                className="Icon"
                onClick={() => toggleDropdownGeste()}
                fontSize="large"
              />}
              </div>
          </div>

          {isOpenGeste && (
          <div>
          <div className='row justify-content-center'>
              <div className='col'><CarreLogo titre="-32% d’émmission annuelle" titregras="Arrêter l’avion" logo={logoAvion}/></div>
              <div className='col'><CarreLogo titre="-32% d’émmission annuelle" titregras="Devenir végétarien" logo={logolegume}/></div>
              <div className='col'><CarreLogo titre="-32% d’émmission annuelle" titregras="Devenir végétarien" logo={logolegume}/></div>
              <div className='col'><CarreLogo titre="-32% d’émmission annuelle" titregras="Devenir végétarien" logo={logolegume}/></div>
              <div className='col'><CarreLogo titre="-32% d’émmission annuelle" titregras="Devenir végétarien" logo={logolegume}/></div>
          </div>

          <div className='row justify-content-center' style={{marginTop : "5vh"}}>
              <div className='col'><CarreLogo titre="-32% d’émmission annuelle" titregras="Arrêter l’avion" logo={logoAvion}/></div>
              <div className='col'><CarreLogo titre="-32% d’émmission annuelle" titregras="Devenir végétarien" logo={logolegume}/></div>
              <div className='col'><CarreLogo titre="-32% d’émmission annuelle" titregras="Devenir végétarien" logo={logolegume}/></div>
              <div className='col'><CarreLogo titre="-32% d’émmission annuelle" titregras="Devenir végétarien" logo={logolegume}/></div>
              <div className='col'><CarreLogo titre="-32% d’émmission annuelle" titregras="Devenir végétarien" logo={logolegume}/></div>
          </div>
          </div>
          )}



          <div className='' style={{marginTop : "5vh"}}>
              <Band1/>
          </div>

          <div className='' style={{marginTop : "5vh"}}>
              <Band2/>
          </div>

          
          <div className='row col-12' style={{marginTop : "5vh"}}>
              <CardBilan/>
          </div>

        </div>
      <Footer />
    </div>
  );
};

export default BilanPart5;

