import React from 'react';
import './Band.css';
import Card from '../Card';
import ContenueCardBilan from './ContenuCardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import SousTitre from '../SousTitre';
import InputField from '../Form/Input';
import Bouton from '../Form/Bouton';

const Band3 = ({text}) => {
  // Données pour les éléments du graphique
  
  return (
<div className='col-12 d-flex align-items-center justify-content-center' style={{marginTop : "5vh"}}>
  
  <div className='row col-12 col-lg-8 Band3 align-items-center justify-content-center'>
    <div className='col-lg-8 col-12 text-center'>
      <p className='p3'>{text}</p>
    </div>
    <div className='col-lg-4 col-12 d-flex align-items-center justify-content-center'>
        <Bouton titre="Je contribue" />
    </div>
  </div>
  
</div>

  );
  
};

export default Band3;
