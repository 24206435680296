import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import CheckboxChoixUnique from '../checkBox/checkbox';
import Compteur from '../../Compteur/Compteur';
import CheckboxChoixUniqueAdapt from '../checkBox/checkbox-adapt';
import imageEnergie from "../../img/Energie.png"
import ConsomationAnnel from './DomicileAnnuel';
import ConsomationAnnelIndividuel from './DomicileAnnuelIndividuel';
import BilanInter from '../../BilanInter';
import { useFormProgress } from '../../FormProgressContext';

function FormDomicileMaison({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { formStatus, setFormStatus } = useFormProgress();
  const [displayBilan, setDisplayBilan] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState("");
  const [ChauffageColl, setChauffageColl] = useState("");
  const [Renove, setRenove] = useState("");
  const [Mode, setMode] = useState("");
  const [Date, setDate] = useState("");
  const [afficherCheckboxAdapt, setAfficherCheckboxAdapt] = useState(false);
  const reponses = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
  const reponseA = ['Avant 1975', 'Après 1975'];
  const reponseChauffCol = ['Gaz', 'GPL', 'Chauffage urbain', 'Bois et plaquettes', 'Fioul'];


  useEffect(() => {
    setFormStatus({
        ...formStatus,
        domicile: 'en cours',
    });
   }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setDisplayBilan(true);
    console.log(formSubmitted);
  }

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Dechet'); // Ajout de la navigation après la soumission du formulaire
  };

  const handleCheckboxChange = (value) => {
    setConsommationConnue(value);
    setAfficherCheckboxAdapt(value === "oui");
  };

  const handleCheckboxChangeRenove = (value) => {
    setRenove(value);
    setAfficherCheckboxAdapt(value === "oui");
  };

  const handleListDerouleChange = (value) => {
    console.log('ListDeroule value:', value);
    // Faites ce que vous souhaitez avec la valeur sélectionnée
  };

  const handleCheckboxAdaptChange = (value) => {
    console.log("Choix sélectionné :", value);
    // Mettez à jour l'état ou effectuez d'autres actions en fonction de la valeur sélectionnée
  };
  
  useEffect(() => {
    if (displayBilan) {
      setFormStatus({
        ...formStatus,
        domicile: 'fini',
      });
    } else if (formSubmitted) {
      setFormStatus({
        ...formStatus,
        domicile: 'en cours',
      });
    }
  }, [formSubmitted]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-lg-7">
          <Compteur titre="Nombre total de personnes vivant dans le logement" />
        </div>
        <div className="col-12 col-lg-5">
          <Compteur titre="Surface du logement" unit="m²"/>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CheckboxChoixUnique
            onChange={(value) => setConsommationConnue(value)}
            titre="Connaissez-vous la catégorie de votre logement dans l’étiquette climat (kgeqCO2/m².an) ?"
            rep1="Oui"
            rep2="Non, mais aidez-moi à la calculer"
          />
        </div>
      </div>

      {consommationConnue === "" ? null : consommationConnue ? (
        <div className="row Connais">
          <div className="col-12 col-lg-4">
            <CheckboxChoixUniqueAdapt
              titre="Quelle est votre étiquette climat ?"
              reponses={reponses}
              onChange={handleCheckboxAdaptChange}
            />
          </div>
          <div className="col-12 col-lg-6">
            <img src={imageEnergie} />
          </div>
          <div className="col-12 col-lg-2" style={{ height: "150px", paddingLeft: "50px" }}>
            <div className='p3' style={{ backgroundColor: "#EBE085", padding: "10%" }}>
              L'étiquette climat pour connaître la quantité de gaz à effet de serre émise (à droite sur l'image) n'est pas à confondre avec l'étiquette énergie à gauche.
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <CheckboxChoixUniqueAdapt
                titre="Date de construction"
                reponses={reponseA}
                onChange={(value) => setDate(value)}
              />
            </div> 
          </div>
          <div className="row">
            <div className="col-12">
              <CheckboxChoixUnique
                onChange={(value) => setRenove(value)}
                titre="Avez-vous réalisé une rénovation énergétique (isolation, changement de mode de chauffage,…) dans les 10 dernières années ?"
                rep1="Oui"
                rep2="Non"
              />
            </div>
          </div>

          {Renove === "" ? null : (
            <div>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <ListDeroule
                    titre="Pays"
                    value="France"
                    text="France"
                    mots={["Espagne", "Italie", "Portugal"]}
                    onChange={handleListDerouleChange}
                    storageKey="selectedCountry"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <CheckboxChoixUnique
                    onChange={(value) => setChauffageColl(value)}
                    titre="Réseau de chauffage"
                    rep1="Individuel"
                    rep2="Collectif"
                  />
                </div>
              </div>
            </div>
          )}

          {ChauffageColl === "" ? null : ChauffageColl ? (
            <ConsomationAnnel />
          ) : (
            <div className="row">
              <div className="col-12 col-lg-4">
                <CheckboxChoixUniqueAdapt
                  titre="Mode de chauffage collectif"
                  reponses={reponseChauffCol}
                  onChange={(value) => setMode(value)}
                />
              </div>
              <div className="col-12 col-lg-8">
                {Mode === "" ? null : <ConsomationAnnelIndividuel />}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <Bouton titre="CALCULER" />
        </div>
      </div>

      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInter voyage="non" numeroEtape={6} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default FormDomicileMaison;

