import React from 'react';
import './Graphique.css';

const CarreBilan = ({logo, titre, titregras}) => {
  // Données pour les éléments du graphique
  
  return (
      <div className='CarreBilan'>

          <div className='textCarreBilan'>2 Tonnes = 2x24€ </div>
          <div className='textCarreBilan'>24€ la tonne de CO2 à la fondation goodplanet</div>
      </div>
  );
};

export default CarreBilan;