import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Titre from '../../Titre';
import SousTitre from '../../SousTitre';
import LogoElect from "../../img/Elect.png";
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import LogoChauf from "../../img/ChauffageUrbain.png";
import Logogpl from "../../img/GPL.png";
import Logogaz from "../../img/gaz.png";
import LogoBoi from "../../img/bois.png";
import LogoFioul from "../../img/fioule.png";
import LogoPanneauSol from "../../img/panneauSol.png";
import LogoPaneauVolt from "../../img/panneauPhoto.png";
import LogoEolienne from "../../img/eolienne.png";
import "./form.css";


const ConsomationAnnel = ({}) => {

  return (
    <div className="conso">
      <Titre Titre="Consommation annuelle" /> {/* Affiche le titre "Consommation annuelle" */}
      <div className="h61">
        Indiquez votre consommation annuelle d’électricité en euro ou unité d’achat pour votre logement.
      </div> {/* Affiche un texte d'instructions */}
      <div className="row col-12">
        <div className="col-md-6 col-lg-3 col-12">
          <CarreLogoInput2
            logo={LogoElect}
            titregras="Electricité"
            titre="Contrat électricité"
          /> {/* Affiche un carré avec le logo de l'électricité, le titre "Electricité" et le sous-titre "Contrat électricité" */}
        </div>
        <div className="col-md"></div> {/* Colonne vide */}
        <div className="col-md"></div> {/* Colonne vide */}
        <div className="col-md"></div> {/* Colonne vide */}
        <div className="col-md"></div> {/* Colonne vide */}
      </div>
      <div className="h61">
        Indiquez votre consommation annuelle d’électricité en euro ou unité d’achat pour votre logement.
      </div> {/* Affiche un autre texte d'instructions */}
      <div className="row col-12">
        <div className="col">
          <CarreLogoInput2
            logo={LogoChauf}
            titregras="Chauffage urbain"
            titre="Alimentation de plusieurs bâtiments par une chaleur provenant d’un seul site de production."
          /> {/* Affiche un carré avec le logo du chauffage urbain, le titre "Chauffage urbain" et le sous-titre "Alimentation de plusieurs bâtiments par une chaleur provenant d’un seul site de production." */}
        </div>
        <div className="col-md-6 col-lg col-12">
          <CarreLogoInput2
            logo={Logogpl}
            titregras="GPL"
            titre="Chaudière GPL ou propane"
            unit1='€'
            unit='tonnes'
          /> {/* Affiche un carré avec le logo du GPL, le titre "GPL", le sous-titre "Chaudière GPL ou propane" et les unités de mesure */}
        </div>
        <div className="col-md-6 col-lg col-12">
          <CarreLogoInput2
            logo={Logogaz}
            titregras="Gaz"
            titre="Definition"
          /> {/* Affiche un carré avec le logo du gaz, le titre "Gaz" et le sous-titre "Definition" */}
        </div>
        <div className="col-md-6 col-lg col-12">
          <CarreLogoInput2
            logo={LogoBoi}
            titregras="Bois Plaquettes forestières"
            titre="Chauffage au poele ou avec une cheminée"
            unit1='€'
            unit='steres'
          /> {/* Affiche un carré avec le logo du bois, le titre "Bois Plaquettes forestières", le sous-titre "Chauffage au poele ou avec une cheminée" et les unités de mesure */}
        </div>
        <div className="col-md-6 col-lg col-12">
          <CarreLogoInput2
            logo={LogoFioul}
            titregras="Fioul"
            titre="Definition"
            unit1='€'
            unit='L'
          /> {/* Affiche un carré avec le logo du fioul, le titre "Fioul", le sous-titre "Definition" et les unités de mesure */}
        </div>
      </div>
      <div className="h61">
        Indiquez votre consommation annuelle d’électricité en euro ou unité d’achat pour votre logement.
      </div> {/* Affiche un autre texte d'instructions */}
      <div className="row col-12">
        <div className="col-md-6 col-lg col-12">
          <CarreLogoInput2
            logo={LogoPanneauSol}
            titregras="Panneau solaire"
            titre="Production de chaleur grâce au Soleil"
            nombre={1}
            unit1='kWh'
          /> {/* Affiche un carré avec le logo du panneau solaire, le titre "Panneau solaire", le sous-titre "Production de chaleur grâce au Soleil", le nombre et l'unité de mesure */}
        </div>
        <div className="col-md-6 col-lg col-12">
          <CarreLogoInput2
            logo={LogoPaneauVolt}
            titregras="Panneau photovoitaïque"
            titre="Production d’énergie grâce au Soleil"
            nombre={1}
            unit1='kWh'
          /> {/* Affiche un carré avec le logo du panneau photovoltaïque, le titre "Panneau photovoitaïque", le sous-titre "Production d’énergie grâce au Soleil", le nombre et l'unité de mesure */}
        </div>
        <div className="col-md-6 col-lg col-12">
          <CarreLogoInput2
            logo={LogoEolienne}
            titregras="Eolienne"
            titre="Production d’électricité grâce au vent"
            nombre={1}
            unit1='kWh'
          /> {/* Affiche un carré avec le logo de l'éolienne, le titre "Eolienne", le sous-titre "Production d’électricité grâce au vent", le nombre et l'unité de mesure */}
        </div>
        <div className="col-md"></div> {/* Colonne vide */}
        <div className="col-md"></div> {/* Colonne vide */}
      </div>
    </div>
  );
};

export default ConsomationAnnel;
