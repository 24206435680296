import React from 'react';
import ImageAvion from "../img/LogoAvion.png";
import './Card.css';
import { colors } from '@mui/material';

const Card = ({ contenu, onClick, color }) => {
  return (
    <div className="default-card align-items-center justify-content-center" onClick={onClick} style={{ boxShadow: `-6px 6px 0px ${color}` }}>
        {contenu}
      </div>
  );
}

export default Card;

