import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PetiteCard.css';
import Compteur from '../Compteur/Compteur';

const PetiteCard = ({ label, imageName, lien, onClick }) => {
  const navigate = useNavigate();

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate(lien); // Ajout de la navigation après la soumission du formulaire
    onClick(); // Appeler la fonction de rappel
  };

  return (
    <button className="small-card" onClick={handleButtonClick}>
      <div className="rectangle">
        <div className="label">{label}</div>
        <div className="imageDiv">
          <div className="image">
            {imageName === 'non' ? <Compteur /> : <img src={imageName} alt="Image" />}
          </div>
        </div>
      </div>
    </button>
  );
};

export default PetiteCard;




