import React, { useState } from "react";
import "./Ensavoir.css";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'; 

const Ensavoir = ({contenu}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const dropdownStyle = {
    display: isOpen ? "block" : "none",
    padding: "10px",
    marginTop: "0px",
    background: "#F5F5F5",
    border: "1px solid #E4E4E4",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",   
    flex: "none",
    order: "1",
    flexGrow: "0",
  };

  return (
    <div>
      <div className="boutonEnsavoir col-12" onClick={toggleOpen}>
        <div className="row justify-content-left">
          <div className="textEnsavoir col-10 col-lg-4 col-md-6" style={{marginLeft : "12px"}}>En savoir plus sur ce calcul</div>
          {/* Bas ou Haut en fonction de l'état isOpen */}
          <div className="iconDescente col-1">
            {isOpen ? <ExpandLessOutlinedIcon/> : <ExpandMoreOutlinedIcon/>}
          </div>
        </div>
      </div>

      <div style={dropdownStyle}>
        {contenu}
      </div>
    </div>
  );
};

export default Ensavoir;