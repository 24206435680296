import React from 'react';
import { useState } from 'react';

import './BilanInter.css';
import LogoAvion from "../img/LogoAvion.png";
import LogoVoiture from "../img/LogoVoiture.png";
import LogoTrain from "../img/train.png";
import LogoTC from "../img/TC.png";
import LogoAl from "../img/Al.png";
import LogoDom from "../img/dom.png";
import LogoDechet from "../img/dechet.png";
import LogoPlacement from "../img/Visite.png";

import Titre from '../Titre';
import SousTitre from '../SousTitre';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FormAlimentaire2 from '../Form/Form Particulier/FormAlimentaire';


const BilanInter = ({ voyages, numeroEtape, handleDeleteVoyage, voyage, alimentaire,responseAPI,responseAPIPlacement,responseAPITC, responseAPIDechet,responseAPIDomicile,responseAPIAvion,responseAPIVoiture, responseAPITrain}) => {
  

  const responseAPITCString=JSON.stringify({responseAPITC});
  const responseAPITCParsed=JSON.parse(responseAPITCString);
  const sumTC = responseAPITC ? (responseAPITCParsed.responseAPI.ConsoTrotinetteVelo).toFixed(3):0;

  
 

  const sumVoiture = responseAPIVoiture ? (responseAPIVoiture.ConsoVoiture|| responseAPIVoiture.ConsoMoto || responseAPIVoiture.ConsoVTC || responseAPIVoiture.ConsoVoyageTaxi).toFixed(3) : 2;

 const sumPlacement = responseAPIPlacement ? (responseAPIPlacement.ConsoPlacementBanque).toFixed(3):0;

 const sumAvion = responseAPIAvion ? (responseAPIAvion.consoVoyage).toFixed(3):0;



  const responseAPIAlim = JSON.stringify({responseAPI});
  const responseAPIAlimparsed = JSON.parse(responseAPIAlim);
  console.log(responseAPIAlimparsed);

  const sumAlim = responseAPI ? (
    responseAPIAlimparsed.responseAPI.ConsoPetitDej +
    responseAPIAlimparsed.responseAPI.ConsoRepasVegetarien +
    responseAPIAlimparsed.responseAPI.ConsoRepasVegetalien +
    responseAPIAlimparsed.responseAPI.ConsoRepasMarin +
    responseAPIAlimparsed.responseAPI.ConsoRepasViandeRouge +
    responseAPIAlimparsed.responseAPI.ConsoRepasViandeBlanche +
    responseAPIAlimparsed.responseAPI.ConsoBoisson
  ).toFixed(3) : 0;


const sumDomicile =0;

  const sumDechet = responseAPIDechet ? (responseAPIDechet.ConsoTotalDechetConsommation).toFixed(3) : 0;

  const sumTrain = responseAPITrain ? (responseAPITrain.ConsoTrain || responseAPITrain.ConsoVoyageTrain).toFixed(3) : 0;

  const collection = [
    {
      text: `Bilan avion : ${sumAvion} tonnes de CO2 par an`,
      image: LogoAvion
    },
    {
      text: `Bilan voiture : ${sumVoiture} tonnes de CO2 par an`,
      image: LogoVoiture
    },
    {
      text: `Bilan train : ${sumTrain} tonnes de CO2 par an`,
      image: LogoTrain
    },
    {
      text: `Bilan transport en commun : ${sumTC} tonnes de CO2 par an`,
      image: LogoTC
    },
    
    {
      text: `Bilan alimentation : ${sumAlim} tonnes de CO2 par an`,
      image: LogoAl
    },
    {
      text: 'Bilan domicile : 3,4 tonnes de CO2 par an',
      image: LogoDom
    },    
    {
      text: `Bilan consommation & déchets : ${sumDechet} tonnes de CO2 par an`,
      image: LogoDechet
    },
    {
      text: `Bilan placements financiers : ${sumPlacement} tonnes de CO2 par an`,
      image: LogoPlacement 
    },
    // Ajoutez les autres étapes de la collection ici
  ];

  const collectionAffichee = collection.slice(0, numeroEtape);
  const sumInter = parseFloat(sumVoiture) + parseFloat(sumTC) + parseFloat(sumPlacement) + parseFloat(sumAlim) + parseFloat(sumDechet) + parseFloat(sumTrain) + parseFloat(sumAvion) + parseFloat(sumDomicile)  ;


  return (
    <div className='BilanInt'>
      <Titre Titre="Bilan intermédiaire" place="start" />
      <div className="containerC">
        <div className="contentB">
        <div className='h61'>Bilan cumulé intermédiaire : {sumInter} tonnes de CO2 par an</div>
        </div>
      </div>
      

      {collectionAffichee.map((item, index) => (
        <div className="containerB" key={index}>
          <div className="contentB">
            <span className="textB" style={{fontWeight : "800"}}>{item.text}</span>
            <div className="imageCont">
            <img className="imageLogo" src={item.image} alt="Image centrée" />
            </div>
          </div>
        </div>
      ))}

    {voyage === "non" ? ("") : (
      voyages.map((voyage, index) => (
        <div className="containerB" key={index}>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Voyage {index + 1} : {voyage.depart.name} - {voyage.arrive.name},  {voyage.ajout.frequence} fois  {voyage.ajout.unite}</span>
            <div className="textB col-4">0,6 tonne(s) de CO2</div>
            <div className="icon-container float-end">
              <DeleteOutlinedIcon className="Icon" onClick={() => handleDeleteVoyage(index)} />
            </div>
          </div>
        </div>
      ))
    )
  }



{alimentaire === "oui" && (
  <div>
    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation petit déjeuner :</span>
            <div className="textB col-4">{responseAPI.ConsoPetitDej} tonne(s) de CO2</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation petit déjeuner :</span>
            <div className="textB col-4">0 tonne(s) de CO2</div>
          </div>
        </>
      ) }
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas végétarien :</span>
            <div className="textB col-4">{responseAPI.ConsoRepasVegetarien} tonne(s) de CO2</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas végétarien :</span>
            <div className="textB col-4">0 tonne(s) de CO2</div>
          </div>
        </>
      )}
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas végétalien :</span>
            <div className="textB col-4">{responseAPI.ConsoRepasVegetalien} tonne(s) de CO2</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas végétalien :</span>
            <div className="textB col-4">0 tonne(s) de CO2</div>
          </div>
        </>
      )}
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas marins :</span>
            <div className="textB col-4">{responseAPI.ConsoRepasMarin} tonne(s) de CO2</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas marins :</span>
            <div className="textB col-4">0 tonne(s) de CO2</div>
          </div>
        </>
      )}
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation viande rouge :</span>
            <div className="textB col-4">{responseAPI.ConsoRepasViandeRouge} tonne(s) de CO2</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation viande rouge :</span>
            <div className="textB col-4">0 tonne(s) de CO2</div>
          </div>
        </>
      )}
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation viande blanche :</span>
            <div className="textB col-4">{responseAPI.ConsoRepasViandeBlanche} tonne(s) de CO2</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation viande blanche :</span>
            <div className="textB col-4">0 tonne(s) de CO2</div>
          </div>
        </>
      )}
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation boissons :</span>
            <div className="textB col-4">{responseAPI.ConsoBoisson} tonne(s) de CO2</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation boissons :</span>
            <div className="textB col-4">0 tonne(s) de CO2</div>
          </div>
        </>
      )}
    </div>
  </div>
)}
    </div>
  );
}

export default BilanInter;

