import React, { useState } from 'react';
import ListDeroule from '../ListDeroule';
import InputField from '../Input';
import Bouton from '../Bouton';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import '../Form Particulier/form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import "./Connection.css"
import InputMotPasse from '../Input/indexMP';
import CheckboxLabels from '../CheckBoxMulti';

function FormConnection() {
  const [identifiant, setIdentifiant] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const [confirmerMotDePasse, setConfirmerMotDePasse] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [Alerte, setAlerte] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Vérification des champs remplis
    if (
      identifiant &&
      motDePasse 
    ) {
      setAlerte(true);
      setFormSubmitted(true);
    } else {
      setAlerte(false);
      setFormSubmitted(false);
      console.log(Alerte);
    }
  };

  return (
    <form className='Connection'>
      <div className="row col-12 justify-content-center">
        <div className="SousTitre">
          <Titre
            Titre="Votre profil"
            place="center"
            color="#024C4A"
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Identifiant"
            value={identifiant}
            onChange={setIdentifiant}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputMotPasse
            titre="Mot de passe"
            value={motDePasse}
            onChange={setMotDePasse}
            text="Mot de passe"
          />
        </div>
      </div>


      {formSubmitted && (
        <div className="row">
          <div className="col-12">
            <p>Identifiant: {identifiant}</p>
            <p>Mot de passe: {motDePasse}</p>
          </div>
        </div>
      )}

      {!formSubmitted && !Alerte && (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                display:
                  identifiant &&
                  motDePasse 
                    ? 'none'
                    : 'block'
              }}
            >
              Veuillez remplir tous les champs !
            </div>
          </div>
        </div>
      )}

      {!formSubmitted && (
      <div className="row col-12 justify-content-center">
      <div className="col-lg-2 col-md-2 col-12">
            <Bouton
              type="submit"
              titre="Connection"
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </form>
  );
}

export default FormConnection;
