import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Titre from '../../Titre';
import SousTitre from '../../SousTitre';
import LogoElect from "../../img/Elect.png";
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import LogoChauf from "../../img/ChauffageUrbain.png";
import Logogpl from "../../img/GPL.png";
import Logogaz from "../../img/gaz.png";
import LogoBoi from "../../img/bois.png";
import LogoFioul from "../../img/fioule.png";
import LogoPanneauSol from "../../img/panneauSol.png";
import LogoPaneauVolt from "../../img/panneauPhoto.png";
import LogoEolienne from "../../img/eolienne.png";
import "./form.css";

const ConsomationAnnelIndividuel = ({}) => {

  return (
    <div className="conso">
      <Titre Titre="Consommation annuelle individuelle supplémentaire" />
      <div className="h61">
        Indiquez votre consommation annuelle individuelle en euro ou unité d’achat pour votre logement.
      </div>
      <div className="row col-12">
        <div className="col-md-6 col-lg-3 col-12">
          <CarreLogoInput2
            logo={LogoElect}
            titregras="Electricité"
            titre="Contrat électricité"
          />
        </div>
        <div className="col-md-6 col-lg-3 col-12">
          <CarreLogoInput2
            logo={LogoBoi}
            titregras="Bois Plaquettes forestières"
            titre="Chauffage au poele ou avec une cheminée"
            unit='steres'
          />
        </div>
      </div>
      <div className="h61">
        Je produis et consomme une partie de mon énergie (l’énergie produite pour la revente n’est pas prise en compte):
      </div>
      <div className="row col-12">
        <div className="col-md-6 col-lg-3 col-12">
          <CarreLogoInput2
            logo={LogoPanneauSol}
            titregras="Panneau solaire"
            titre="Production de chaleur grâce au Soleil"
            nombre={1}
            unit1='kWh'
          />
        </div>
        <div className="col-md-6 col-lg-3 col-12">
          <CarreLogoInput2
            logo={LogoPaneauVolt}
            titregras="Panneau photovoitaïque"
            titre="Production d’énergie grâce au Soleil"
            nombre={1}
            unit1='kWh'
          />
        </div>
        <div className="col-md-6 col-lg-3 col-12">
          <CarreLogoInput2
            logo={LogoEolienne}
            titregras="Eolienne"
            titre="Production d’électricité grâce au vent"
            nombre={1}
            unit1='kWh'
          />
        </div>
      </div>
    </div>
  );
};

export default ConsomationAnnelIndividuel;
