import React, { useState, useEffect } from 'react';
import "./checkboxAjout.css";
import SousTitre from '../../SousTitre';
import InputElement from './InputElement';
import SelectElement from './SelectElement';
import "../Ajout/Ajout.css";

const CheckboxAjout = ({ titre, values, setValues, valeurInput, setValeurInput, transport,setTransport, unit, setHandleUnite}) => {
  const handleChangeValeurInput = (event) => {
    const newValue = event.target.value.replace(/\D/g, '');
    setValeurInput(newValue);
  };

  const handleCheckboxChange = (event) => {
    const newValue = event.target.value;
    setValues(newValue);
  };
  
  const handleChangeTransport = (event) => {
    // const newTransport = event.target.value;
    // let transportValue;
    // if (newTransport === 'non') {
    //   transportValue = null; // Définir une valeur null ou une autre valeur appropriée lorsque "non" est sélectionné
    // } else {
    //   transportValue = mapTransportValue(newTransport);
    // }
    // setTransport(transportValue);
    setTransport(event.target.value);
  };


  const [unite, setUnite] = useState('an');

  const handleChangeUnite = (value) => {
    //console.log('je suis dans handleChangeUnite CheckboxAjout');
    const newUnite = value;
    setUnite(newUnite);
    setHandleUnite(newUnite); // Appeler la fonction de mise à jour de l'unité
    //console.log(unite); // Ceci affichera l'ancienne valeur de l'unité, car setState est asynchrone
  };

   // Fonction de correspondance pour le transport
   const mapTransportValue = (transport) => {
    switch (transport) {
      case 'Tram':
        return 279640000;
      case 'Métro/RER':
        return 279640001;
      case 'Bus':
        return 279640002;
      case 'Train':
        return 279640003;
      case 'Autocar':
        return 279640004;
      default:
        return 279640002; // Valeur par défaut est la valeur du premier composant qui s'affiche donc Bus
    }
  };

    // Fonction de correspondance pour l'unité
    const mapUnitValue = (unit) => {
      switch (unit) {
        case 'an':
          return 279640000;
        case 'mois':
          return 279640001;
        case 'semaine':
          return 279640002;
        default:
          return 279640000; // Valeur par défaut est la valeur du premier composant qui s'affiche donc An
      }
    };


  const transportValue = mapTransportValue(transport);
  const unitValue = mapUnitValue(unit);

  // const handleChangeUnite = (event) => {
  //   const newValue = event.target.value;
  //   setUnit(newValue);
  // };

  // Calculate the dynamic width for the input element based on the input value length
  let inputWidth = `${valeurInput.length * 15}px`; // Adjust the factor as needed
  if (inputWidth < '50px') {
    inputWidth = '50px'; // Set a minimum width of 30px
  }


  return (
    <div className="checkboxAjout">
      <div className='h61'>{titre}</div>

      <div className='row'>
        <div className='col-md-3 col-12'>
          <label>
            <input
              type="checkbox"
              name="choix"
              value="oui"
              checked={values === "oui"}
              onChange={handleCheckboxChange}
            />
            <span className="checkmark"></span>
            Oui, j’effectue
          </label>
        </div>

        {(
          <>
          
             
            <div className='col-md-1 col-3'>
              <input
                type='text'
                className="ajout-input"
                value={valeurInput}
                onChange={handleChangeValeurInput}
                style={{ width: inputWidth }} // Set the dynamic width here
                pattern="[0-9]*"
              />
            </div>
            <div className='col-md-1 col-3 unitCompteur'>{unit}</div>
            <div className='col-md-2 col-4'>
              <SelectElement values={unite} setValues={setUnite} onChange={handleChangeUnite}/> 
  
            </div>
            {transport !== 'non' && (
            <div className='col-md-1 col-2'>
              <select className="ajout-select" value={transport} onChange={handleChangeTransport}>
                <option value="Bus">Bus</option>
                <option value="Autocar">Autocar</option>
                <option value="Tram">Tram</option>
                <option value="Métro/RER">Métro/RER</option>
                <option value="Train">Train</option>
              </select>
            </div>)}
          </>
        )}
      </div>

      <label>
        <input
          type="checkbox"
          name="choix"
          value="non"
          checked={values === "non"}
          onChange={(event) => setValues(event.target.value)}
        />
        <span className="checkmark"></span>
        Non, mais aidez-moi à le calculer
      </label>
    </div>
  );
}

export default CheckboxAjout;
