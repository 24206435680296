import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageAvion from "../img/LogoAvion.png";
import Titre from "../Titre";
import PetiteCard from "../PetiteCard/PetiteCard";
import PetiteCard2 from "../PetiteCard/PetiteCard2"
import SousTitre from "../SousTitre";
import "./ContenueLogo.css"
import Bouton from '../Form/Bouton';
import PetiteCardAl from '../PetiteCard/PetiteCardAl';

const ContenueBoutonAl = ({ petiteCards, setPetiteCards, TitreP, SousTitreP, lien }) => {
  const navigate = useNavigate();

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate(lien); // Ajout de la navigation après la soumission du formulaire
  };

  return (
    <div>
      <div className="row">
        <div className="col-6 offset-3">            
          <Titre Titre={TitreP} className={TitreP} couleur="#000000" place="center"/>
        </div>
      </div>

      <div className="row">
        <div className="col-12">            
          <SousTitre SousTitre={SousTitreP} className="SousTitre" place="center"/>
        </div>
      </div>

      <div className="row col-12 petite-card-container justify-content-center">
        {
          petiteCards.map((petiteCard, index) => (  
            <div key={index} className='cardPett col' >            
              <PetiteCardAl label={petiteCard.label} imageName={petiteCard.imageName} lien={petiteCard.lien} nombre={petiteCard.nombre} />
            </div>
          ))}
      </div>
      <div className="btnCat row justify-content-end" style={{marginTop : "5vh"}}>
        <div className="col-5 ">
          <Bouton titre="Catégorie suivante" onClick={handleButtonClick}/>
        </div>
      </div>
        
    </div>
  );
}

export default ContenueBoutonAl;


