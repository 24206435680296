import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import SousTitre from '../../SousTitre';
import { grey, pink } from '@mui/material/colors';
import { Label } from 'recharts';

export default function CheckboxLabels({ labels, title }) {
  return (
    <div style={{marginTop : "5vh"}}>
      <div className='p4'>{title}</div>
      <FormGroup>
        {labels.map((label, index) => (
        <div key={index}>
          <FormControlLabel key={index} control={<Checkbox sx={{
                color: grey[900],
                '&.Mui-checked': {
                color: grey[900],
                },
            }}/>} label={label} 
          />
        </div>
        ))}
      </FormGroup>
    </div>
  );
}
